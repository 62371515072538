@import url(https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,600,600i,800,800i);
.checkmark {
  position: relative; }
  .checkmark:before {
    content: ' ';
    display: block;
    border: solid 0.8em #ffb40a;
    border-radius: 0.8em;
    height: 0;
    width: 0;
    position: absolute;
    left: 0.5em;
    top: 40%;
    margin-top: -0.5em; }
  .checkmark:after {
    content: ' ';
    display: block;
    width: 0.3em;
    height: 0.6em;
    border: solid #ffffff;
    border-width: 0 0.2em 0.2em 0;
    position: absolute;
    left: 1em;
    top: 40%;
    margin-top: -0.2em;
    transform: rotate(45deg); }

body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif; }

p {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: 300;
  color: #222325; }

h2 {
  font-size: 15px; }

button {
  font-size: 11px;
  font-weight: bold;
  border: none; }
  button:focus {
    outline: none; }

textarea {
  padding: 0; }

input {
  width: 100%;
  /* Safari/Chrome, other WebKit */
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */ }

.main-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start; }
  .main-page__content {
    width: 100%;
    display: flex;
    flex-direction: row; }
    .main-page__content .side-bar {
      flex: 1 1; }
    .main-page__content .content {
      flex: 1 1;
      padding: 40px;
      display: flex;
      flex-direction: column; }

.border-none {
  border: none !important; }

.loader-page {
  flex: 4 1;
  display: flex;
  flex-direction: column; }
  .loader-page > .loader-modal .custom-modal__content {
    height: 180px;
    background-color: transparent; }

.banner-container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .banner-container__title {
    font-size: 25px; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */ }

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */ }

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #b1b1b1; }

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b1b1b1; }

::placeholder {
  /* Most modern browsers support this now. */
  color: #b1b1b1; }

input:focus::-webkit-input-placeholder {
  color: transparent; }

input:focus:-moz-placeholder {
  color: transparent; }

/* FF 4-18 */
input:focus::-moz-placeholder {
  color: transparent; }

/* FF 19+ */
input:focus:-ms-input-placeholder {
  color: transparent; }

/* IE 10+ */
.disabled-section {
  opacity: 0.5;
  cursor: no-drop !important; }
  .disabled-section > * {
    cursor: no-drop !important; }
  .disabled-section .package-element {
    cursor: no-drop !important; }

.custom-table__table {
  border-radius: 20px;
  z-index: 1; }
  .custom-table__table .custom-loading {
    background: rgba(255, 255, 255, 0.9) !important;
    border-radius: 20px !important; }
    .custom-table__table .custom-loading .custom-loading-inner {
      color: #000000;
      font-size: 16px; }
  .custom-table__table .rt-table {
    border-radius: 20px;
    border: solid 1px #000000; }
    .custom-table__table .rt-table .rt-thead {
      box-shadow: none !important;
      background-color: #efefef;
      height: 60px; }
      .custom-table__table .rt-table .rt-thead .rt-tr {
        align-items: center; }
        .custom-table__table .rt-table .rt-thead .rt-tr .rt-resizable-header {
          border: none; }
          .custom-table__table .rt-table .rt-thead .rt-tr .rt-resizable-header .rt-resizable-header-content {
            font-size: 14px;
            font-weight: 800;
            color: #4a4a4a;
            text-align: left;
            padding-left: 1.25rem; }
      .custom-table__table .rt-table .rt-thead .rt-tr[data-qnt='0'] > .rt-expandable {
        visibility: hidden; }
    .custom-table__table .rt-table .rt-tbody .rt-tr-group {
      min-height: 45px;
      margin-left: 1.25rem; }
      .custom-table__table .rt-table .rt-tbody .rt-tr-group .rt-expandable {
        margin-right: 1.25rem; }
    .custom-table__table .rt-table .sub-component {
      padding: 1.25rem; }
      .custom-table__table .rt-table .sub-component .line {
        display: flex;
        margin-top: 0.625rem;
        align-self: center;
        background-color: #979797;
        width: 100%;
        height: 1px;
        cursor: none; }
  .custom-table__table .rt-noData {
    margin: 1.875rem 0;
    background-color: transparent !important; }

.custom-table .custom-button {
  width: 160px;
  margin-top: 20px; }

.custom-table__downloadLink {
  text-decoration: none;
  background-color: #ffb40a;
  color: #000000;
  font-size: 11px;
  font-weight: bold;
  padding: 15px 35px;
  border-radius: 20px; }

.custom-table__error {
  font-size: 15px;
  color: #d0021b;
  font-weight: bold; }

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: none !important; }

.custom-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative; }
  .custom-input > label {
    font-size: 15px;
    color: #000000;
    font-weight: bold;
    display: inline-block;
    text-align: left;
    margin: 0;
    margin-right: 30px; }
  .custom-input__input {
    height: 30px;
    width: 60%;
    display: block;
    border: 1px solid #131212;
    border-radius: 14px;
    background-color: #ffffff;
    padding: 0 10px;
    font-size: 15px; }
    .custom-input__input--locked {
      border: none;
      pointer-events: none;
      background-color: #ececec !important;
      border: 1px solid #131212; }
    .custom-input__input:focus {
      outline: none; }
    .custom-input__input--delete input {
      padding-right: 25px; }
  .custom-input__layer {
    margin-left: 10px;
    display: inline-block;
    height: 16px;
    width: 16px;
    line-height: 16px;
    border-radius: 8px;
    background-color: #e4e0e0;
    color: black;
    text-align: center;
    font-size: 13px;
    cursor: pointer; }
    .custom-input__layer:focus {
      outline: none; }
  .custom-input__error {
    margin-top: 1%;
    width: 70%;
    color: #d0021b; }

.custom-input-new-gen {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative; }
  .custom-input-new-gen > label {
    font-size: 15px;
    color: #000000;
    font-weight: bold;
    display: inline-block;
    text-align: left;
    margin: 0;
    margin-right: 30px; }
  .custom-input-new-gen__input {
    height: 40px;
    width: 100%;
    display: block;
    border: none;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 0 10px;
    font-size: 15px; }
    .custom-input-new-gen__input--locked {
      border: none;
      pointer-events: none;
      background-color: #ececec !important;
      border: 1px solid #131212; }
    .custom-input-new-gen__input:focus {
      outline: none; }
    .custom-input-new-gen__input::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-weight: 600; }
    .custom-input-new-gen__input::-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-weight: 600; }
    .custom-input-new-gen__input::placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-weight: 600; }
    .custom-input-new-gen__input--delete input {
      padding-right: 25px; }
  .custom-input-new-gen__layer {
    margin-left: 10px;
    display: inline-block;
    height: 16px;
    width: 16px;
    line-height: 16px;
    border-radius: 8px;
    background-color: #e4e0e0;
    color: black;
    text-align: center;
    font-size: 13px;
    cursor: pointer; }
    .custom-input-new-gen__layer:focus {
      outline: none; }
  .custom-input-new-gen__input--error {
    border: 1px solid #d0021b;
    background-color: #FD7B7B; }
  .custom-input-new-gen__error {
    margin-top: 1%;
    width: 70%;
    color: #d0021b; }

.custom-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px; }
  .custom-select__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .custom-select__content > label {
      font-size: 15px;
      font-weight: bold;
      text-align: left;
      white-space: nowrap;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
    .custom-select__content__select {
      width: 100%; }
      .custom-select__content__select .react-select__control {
        width: 100%;
        height: 30px;
        min-height: 30px !important;
        border-radius: 15px;
        border-color: #000000;
        flex-wrap: nowrap; }
        .custom-select__content__select .react-select__control .react-select__placeholder {
          font-size: 15px;
          color: #000000; }
        .custom-select__content__select .react-select__control--is-focused {
          border-color: currentColor;
          border-color: initial;
          box-shadow: none; }
        .custom-select__content__select .react-select__control:hover {
          border-color: currentColor;
          border-color: initial; }
      .custom-select__content__select .react-select__value-container,
      .custom-select__content__select .react-select__value-container--is-multi {
        flex-wrap: nowrap; }
      .custom-select__content__select .react-select__menu {
        width: 100%;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 0;
        margin: 0;
        box-shadow: none;
        border: 1px solid #000000;
        border-top: 0 none; }
        .custom-select__content__select .react-select__menu .react-select__menu-list {
          padding-top: 0; }
          .custom-select__content__select .react-select__menu .react-select__menu-list .react-select__option {
            font-size: 15px;
            height: 30px;
            min-height: 30px !important; }
            .custom-select__content__select .react-select__menu .react-select__menu-list .react-select__option--is-focused {
              background-color: #d8d8d8;
              border-radius: 20px; }
      .custom-select__content__select--menuOpen .react-select__control--is-focused {
        border-color: currentColor;
        border-color: initial;
        box-shadow: none;
        border-bottom: 0 none;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important; }
  .custom-select__error {
    color: #d0021b;
    margin-top: 20px; }

.custom-textarea {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .custom-textarea > label {
    font-size: 15px;
    font-weight: 800; }
  .custom-textarea__textarea {
    height: 220px;
    width: 100%;
    border: 1px solid #131212;
    border-radius: 14px;
    background-color: #ffffff;
    font-size: 15px;
    resize: none;
    box-sizing: border-box;
    margin: 10px 0;
    padding: 10px; }
    .custom-textarea__textarea--locked {
      border: none;
      pointer-events: none;
      background-color: rgba(155, 155, 155, 0.24); }
    .custom-textarea__textarea::-webkit-input-placeholder {
      font-size: 15px;
      font-weight: 400;
      color: #c8c7bd; }
    .custom-textarea__textarea::-ms-input-placeholder {
      font-size: 15px;
      font-weight: 400;
      color: #c8c7bd; }
    .custom-textarea__textarea::placeholder {
      font-size: 15px;
      font-weight: 400;
      color: #c8c7bd; }
    .custom-textarea__textarea:focus {
      outline: none; }
  .custom-textarea__layer {
    margin-left: 10px;
    display: inline-block;
    height: 16px;
    width: 16px;
    line-height: 16px;
    border-radius: 8px;
    background-color: #e4e0e0;
    color: black;
    text-align: center;
    font-size: 13px;
    cursor: pointer; }
    .custom-textarea__layer:focus {
      outline: none; }
  .custom-textarea__error {
    color: #d0021b; }

.custom-button {
  min-height: 40px;
  border-radius: 20px;
  position: relative; }
  .custom-button:hover {
    cursor: pointer; }
  .custom-button--gray {
    background-color: #d8d8d8; }
    .custom-button--gray:hover {
      background-color: #ffb40a; }
  .custom-button--orange {
    background-color: #ffb40a; }
    .custom-button--orange:hover {
      border: 1px solid #000000; }
  .custom-button--yellow {
    background-color: #FFF523; }
    .custom-button--yellow:hover {
      border: 1px solid #000000; }
  .custom-button--purple {
    background-color: #6F49CE; }
    .custom-button--purple:hover {
      border: 1px solid #000000; }
  .custom-button--white {
    background-color: #ffffff;
    border: 1px solid #000000; }
    .custom-button--white:hover {
      border: 0 none;
      background-color: #ffb40a; }
  .custom-button--black {
    background-color: #ffffff;
    color: #000000;
    border: 2px solid #000000;
    min-height: 30px;
    font-weight: 800; }
  .custom-button__disabled {
    background-color: #d8d8d8 !important;
    border: none !important;
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important; }
  .custom-button__loading {
    background-color: #d8d8d8;
    background-image: url("http://loadinggif.com/images/image-selection/3.gif");
    background-size: 24px 24px;
    background-position: right 8% center;
    background-repeat: no-repeat; }
  .custom-button__heavy {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 900;
    line-height: 1.2; }
  .custom-button > i {
    border: solid #000000;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 5px; }
  .custom-button__arrowLeft {
    position: absolute;
    top: 12px;
    left: 8px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }
  .custom-button__arrowRight {
    position: absolute;
    top: 12px;
    right: 8px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }

.custom-button-new-gen {
  min-height: 40px;
  border-radius: 12px;
  position: relative;
  color: white; }
  .custom-button-new-gen:hover {
    cursor: pointer; }
  .custom-button-new-gen--inherit {
    background-color: inherit;
    border: 1px solid #000000;
    color: #000000; }
    .custom-button-new-gen--inherit:hover {
      background-color: #000000;
      color: #ffffff; }
  .custom-button-new-gen--gray {
    background-color: #d8d8d8; }
  .custom-button-new-gen--orange {
    background-color: #ffb40a; }
    .custom-button-new-gen--orange:hover {
      border: 1px solid #000000; }
  .custom-button-new-gen--yellow {
    background-color: #FFF523; }
    .custom-button-new-gen--yellow:hover {
      border: 1px solid #000000; }
  .custom-button-new-gen--purple {
    background-color: #6F49CE; }
    .custom-button-new-gen--purple:hover {
      border: 1px solid #000000; }
  .custom-button-new-gen--white {
    background-color: #ffffff;
    border: 1px solid #000000; }
    .custom-button-new-gen--white:hover {
      border: 0 none;
      background-color: #ffb40a; }
  .custom-button-new-gen--black {
    background-color: #ffffff;
    color: #000000;
    border: 2px solid #000000;
    min-height: 30px;
    font-weight: 800; }
  .custom-button-new-gen__disabled {
    background-color: #d8d8d8 !important;
    border: none !important;
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important; }
  .custom-button-new-gen__loading {
    background-color: #d8d8d8;
    background-image: url("http://loadinggif.com/images/image-selection/3.gif");
    background-size: 24px 24px;
    background-position: right 8% center;
    background-repeat: no-repeat; }
  .custom-button-new-gen__heavy {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 900;
    line-height: 1.2; }
  .custom-button-new-gen > i {
    border: solid #ffffff;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 5px; }
  .custom-button-new-gen__arrowLeft {
    position: absolute;
    top: 12px;
    left: 8px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    color: white; }
  .custom-button-new-gen__arrowRight {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%) rotate(-45deg);
    -webkit-transform: translateY(-50%) rotate(-45deg);
    color: white; }

.custom-upload__input {
  display: none;
  width: 20px;
  height: 20px; }

.custom-upload__button {
  min-height: 40px;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #ffb40a;
  border: 1px solid #ffb40a;
  font-size: 11px;
  font-weight: bold; }
  .custom-upload__button:hover {
    border: 1px solid #000000; }
  .custom-upload__button:focus {
    outline: none; }
  .custom-upload__button > span {
    margin: 0 20px; }

.custom-daypicker .custom-input {
  margin: 0;
  width: 290px; }
  .custom-daypicker .custom-input__input {
    width: 290px;
    border-bottom: solid 0.9px;
    border-bottom-color: #d8d8d8;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 25px; }
    .custom-daypicker .custom-input__input:focus {
      outline: none; }

.custom-daypicker .DayPicker {
  width: 290px;
  outline: none;
  margin: 0; }
  .custom-daypicker .DayPicker-wrapper {
    border: solid 1px #000000;
    background-color: #ffffff;
    border-radius: 14px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0; }

.custom-toggle {
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer; }
  .custom-toggle .isLocked {
    color: rgba(0, 0, 0, 0.5); }
  .custom-toggle:focus {
    outline: none; }
  .custom-toggle p {
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.1px;
    color: #000000; }
  .custom-toggle input[type='checkbox'] {
    display: none; }
  .custom-toggle__toggle {
    position: relative;
    width: 51px;
    height: 31px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .custom-toggle__toggle label,
    .custom-toggle__toggle i {
      box-sizing: border-box;
      display: block;
      background: #ffffff;
      cursor: pointer; }
    .custom-toggle__toggle label {
      width: 31.9px;
      height: 17.6px;
      border-radius: 32px;
      border: 1px solid #000000;
      transition: all 0.3s ease; }
    .custom-toggle__toggle i {
      position: absolute;
      top: 9.5px;
      left: 4px;
      width: 11.5px;
      height: 11.5px;
      border-radius: 28px;
      background: #c8c7bd;
      transition: all 0.3s cubic-bezier(0.275, -0.45, 0.725, 1); }
  .custom-toggle input[type='checkbox']:active + .custom-toggle__toggle i {
    width: 15px; }
  .custom-toggle input[type='checkbox']:active + .custom-toggle__toggle label,
  .custom-toggle input[type='checkbox']:checked + .custom-toggle__toggle label {
    width: 31.9px;
    height: 17.6px;
    border-radius: 32px;
    border: 1px solid #000000;
    transition: all 0.3s ease;
    background: #ffb40a; }
  .custom-toggle input[type='checkbox']:checked + .custom-toggle__toggle i {
    left: 17px;
    background: #ffffff; }
  .custom-toggle input[type='checkbox']:checked:active + .custom-toggle__toggle label {
    border: 9px solid #ffffff; }
  .custom-toggle input[type='checkbox']:checked:active + .custom-toggle__toggle i {
    left: 14px; }

.custom-modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1002;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s; }
  .custom-modal--show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s; }
  .custom-modal__content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    transform: translate(-50%, -50%);
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    background: #ffffff; }
    .custom-modal__content:focus {
      outline: none; }
    .custom-modal__content__close-button {
      font-size: 24px;
      color: #222325;
      text-align: right;
      cursor: pointer;
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 16777275; }
      .custom-modal__content__close-button:focus {
        outline: none; }
      .custom-modal__content__close-button:hover {
        background-color: darkgray; }

.DayPickerInput input {
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
  display: block;
  border: 1px solid #131212;
  border-radius: 14px;
  padding: 0 0.625rem;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 15px; }

.DayPickerInput .DayPickerInput-Overlay {
  border: 1px solid #000000;
  transition: width 2s, height 4s;
  width: 278px;
  height: 290px;
  border-radius: 14px;
  z-index: 2; }

.DayPickerInput .DayPicker-Caption select {
  border: 1px solid #131212;
  border-radius: 14px; }

.react-picker-error {
  color: red;
  margin-top: -20px !important; }

.plan-element {
  width: 220px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #efefef;
  border-radius: 15px; }
  .plan-element--cyclicBill {
    min-height: auto; }
  .plan-element__noOffer {
    margin-top: 50%;
    flex: 6 1; }
  .plan-element__prices {
    width: 90%;
    flex: 2 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px #979797; }
    .plan-element__prices--larger {
      flex: 3 1;
      padding: 10px 0; }
    .plan-element__prices--noOffer {
      flex: 2 1; }
    .plan-element__prices__label {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .plan-element__prices__label--border {
        border-right: 2px solid #000000; }
      .plan-element__prices__label__priceDescription {
        font-size: 38px;
        font-weight: bold;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #000000; }
        .plan-element__prices__label__priceDescription > p {
          font-size: 28px;
          font-weight: bold;
          margin: 0;
          color: #000000; }
      .plan-element__prices__label__month {
        font-size: 11px;
        text-align: center; }
      .plan-element__prices__label__timeframe {
        font-size: 11px;
        font-weight: bold;
        text-align: center; }
  .plan-element__description {
    width: 90%;
    flex: 6 1;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    .plan-element__description--cyclicBill {
      flex: none; }
      .plan-element__description--cyclicBill:nth-last-child() p {
        font-weight: bold; }
    .plan-element__description__separator {
      border-bottom: solid 1px #979797;
      margin: 10px 0; }
    .plan-element__description__title {
      font-weight: bold;
      margin: 0; }
    .plan-element__description__subtitle {
      text-decoration: underline; }
    .plan-element__description__margin {
      margin: 10px 0; }
    .plan-element__description__subscription {
      margin: 10px 0; }
      .plan-element__description__subscription > p {
        font-size: 11px;
        text-align: left;
        margin: 0;
        letter-spacing: 0.8px; }
        .plan-element__description__subscription > p span {
          margin: 0;
          font-weight: bold; }
        .plan-element__description__subscription > p:last-child {
          font-style: italic;
          font-weight: 300; }
    .plan-element__description > p {
      font-size: 11px;
      text-align: left;
      margin: 0;
      letter-spacing: 0.8px; }
      .plan-element__description > p span {
        margin: 0;
        font-weight: bold; }
      .plan-element__description > p:last-child {
        font-style: italic;
        font-weight: 300; }
  .plan-element__toggle {
    width: 90%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    .plan-element__toggle--cyclicBill {
      flex: none; }
    .plan-element__toggle__productOptions {
      flex: 2 1;
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-top: solid 1px #979797; }
      .plan-element__toggle__productOptions--borderBottom {
        border-bottom: solid 1px #979797; }
      .plan-element__toggle__productOptions__title {
        font-size: 11px;
        text-align: left;
        margin: 0;
        letter-spacing: 0.8px;
        align-self: flex-start;
        font-weight: bold;
        margin: 0; }
        .plan-element__toggle__productOptions__title span {
          margin: 0;
          font-weight: bold; }
        .plan-element__toggle__productOptions__title:last-child {
          font-style: italic;
          font-weight: 300; }
    .plan-element__toggle .custom-toggle {
      background-color: #ececec;
      width: 100%;
      justify-content: flex-start;
      margin: 5px 0; }
      .plan-element__toggle .custom-toggle p {
        font-size: 11px;
        margin-left: 20px; }
      .plan-element__toggle .custom-toggle__toggle {
        margin-right: 0; }

.energy-detail {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  background-color: #efefef;
  border-radius: 15px; }
  .energy-detail > span {
    display: flex;
    padding-left: 20px;
    margin: 10px 0; }
    .energy-detail > span > p {
      margin: 0;
      font-size: 15px;
      line-height: 1.2;
      color: #222325; }
    .energy-detail > span > p:nth-child(2) {
      margin-left: 10px; }

.energy-gauge {
  width: 120px;
  height: 120px; }
  .energy-gauge .input-range {
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    touch-action: pan-x; }
  .energy-gauge .input-range__track {
    background: #d8d8d8; }
  .energy-gauge .input-range__track--active {
    background: #ffb40a; }
  .energy-gauge .input-range__slider {
    background: #ffb40a;
    border-color: #000000; }
  .energy-gauge__progress {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .energy-gauge__progress .progress,
    .energy-gauge__progress .progress__bar {
      height: 100px;
      width: 65px; }
    .energy-gauge__progress .progress {
      background-color: #ffb40a;
      border-radius: 5px;
      position: relative; }
      .energy-gauge__progress .progress__label {
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }
        .energy-gauge__progress .progress__label__value:nth-child(1) {
          font-size: 11px;
          font-weight: bold;
          color: #000000; }
        .energy-gauge__progress .progress__label__value:nth-child(2) {
          font-size: 18px;
          font-weight: bold;
          color: #ffffff; }
    .energy-gauge__progress .progress__bar {
      background-color: #d8d8d8;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px; }

.package-element {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  background-color: #d8d8d8;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 13px; }
  .package-element__title {
    font-size: 24px;
    text-align: center; }
  .package-element__iconizing {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    line-height: 1.38;
    letter-spacing: 0.9px;
    margin: 5px 0;
    transform: scaleY(0.76); }
  .package-element__price {
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    color: #000000; }
    .package-element__price > p {
      font-size: 25px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 0.8px;
      margin: 0;
      color: #000000; }
  .package-element > span {
    font-size: 11px; }
  .package-element__description {
    flex-grow: 8;
    font-size: 12px;
    text-align: left;
    padding: 10%;
    font-stretch: normal;
    line-height: normal;
    color: #000000; }
  .package-element:focus {
    outline: none; }
  .package-element--recommended {
    border: 4px solid #000000;
    transform: scale(1.2); }
    .package-element--recommended > span {
      margin-bottom: 10px;
      width: 50px;
      height: 13px; }
  .package-element--chosen {
    border: 4px solid #ffb40a;
    background-color: #ffffff;
    position: relative; }
  .package-element .checkmark {
    position: absolute;
    bottom: 0;
    left: 40%;
    transform: scaleY(0.76); }

.custom-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px; }

.custom-checkbox .image-holder {
  width: 60px;
  height: 60px;
  margin-right: 20px; }

.custom-checkbox input {
  padding: 0;
  height: auto;
  height: initial;
  width: auto;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer; }

.custom-checkbox label {
  position: relative;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  align-items: center; }

.custom-checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: #ffffff;
  border: 5px solid #dddddb;
  padding: 8px;
  display: inline-block;
  cursor: pointer;
  margin-right: 20px; }

.custom-check--withDescription label:before {
  margin-top: -20px; }

.custom-checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 10px;
  width: 5px;
  height: 16px;
  border: solid #ffb40a;
  border-width: 0 2px 2px 0;
  transform: rotate(42deg); }

.custom-checkbox input:checked + .custom-checkbox__label:after {
  top: 20px; }

.custom-check--withDescription input:checked + .custom-checkbox__label:after {
  top: 0; }

.custom-checkbox__label__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.custom-checkbox__label__content__text {
  font-size: 24px;
  color: black;
  margin: 0; }

.custom-checkbox__label__content__description {
  font-size: 15px;
  color: #9b9b9b;
  margin: 0; }

.custom-transfer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start; }
  .custom-transfer > h3 {
    font-size: 20px;
    font-weight: bold; }
  .custom-transfer .undefined {
    display: none; }
  .custom-transfer > p {
    font-size: 15px;
    font-weight: bold; }
  .custom-transfer__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start; }
    .custom-transfer__inputs__content {
      width: 100%;
      flex: 1 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
      .custom-transfer__inputs__content > p {
        font-size: 15px;
        font-weight: bold;
        width: 130px; }
      .custom-transfer__inputs__content > input {
        height: 30px;
        width: 20%;
        display: block;
        border: 1px solid #131212;
        border-radius: 14px;
        background-color: #ffffff;
        padding: 0 10px;
        margin: 10px 0;
        font-size: 15px; }
        .custom-transfer__inputs__content > input--locked {
          border: none;
          pointer-events: none;
          background-color: rgba(155, 155, 155, 0.24); }
        .custom-transfer__inputs__content > input:focus {
          outline: none; }
        .custom-transfer__inputs__content > input::-webkit-input-placeholder {
          color: #b1b1b1; }
        .custom-transfer__inputs__content > input::-ms-input-placeholder {
          color: #b1b1b1; }
        .custom-transfer__inputs__content > input::placeholder {
          color: #b1b1b1; }
    .custom-transfer__inputs__error {
      font-size: 15px;
      color: #d0021b; }
    .custom-transfer__inputs .modal-title {
      width: 20%;
      font-weight: 800;
      font-size: 16px;
      text-decoration: underline;
      color: #747474;
      cursor: pointer; }
  .custom-transfer__error {
    font-size: 15px;
    color: #d0021b;
    font-weight: bold; }
  .custom-transfer .custom-button {
    width: 160px;
    margin-top: 20px; }

.custom-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  /* On mouse-over, add a grey background color */ }
  .custom-radio .custom-radio__checkmark {
    border-color: #b1b1b1; }
    .custom-radio .custom-radio__checkmark::after {
      left: 0.5px;
      top: 0.5px; }
  .custom-radio__label {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .custom-radio__label .custom-radio__checkmark {
      position: inherit; }
    .custom-radio__label .image-holder {
      margin: 0 20px; }
  .custom-radio__description {
    font-size: 11px;
    color: #9b9b9b;
    margin: 0; }
  .custom-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    /* When the radio button is checked, add a blue background */
    /* Show the indicator (dot/circle) when checked */ }
    .custom-radio input:checked ~ .custom-radio__checkmark {
      background-color: #ffffff; }
    .custom-radio input:checked ~ .custom-radio__checkmark:after {
      display: block; }
  .custom-radio__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 3px solid #ecece8;
    border-radius: 50%;
    /* Create the indicator (the dot/circle - hidden when not checked) */ }
    .custom-radio__checkmark:after {
      content: '';
      position: absolute;
      display: none;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background-color: #ffb40a; }
  .custom-radio:hover input ~ .custom-radio__checkmark {
    background-color: #ccc; }

.side-bar {
  background-color: #000000;
  width: 30%;
  min-width: 275px;
  min-height: 890px;
  padding-bottom: 40px;
  z-index: 1001;
  z-index: 1001; }
  .side-bar > img {
    display: block;
    margin: 15px auto;
    height: 64px;
    width: 184px; }
  .side-bar__logout {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    text-decoration: none;
    cursor: pointer; }
    .side-bar__logout > p {
      font-size: 11px;
      font-weight: 600; }
      .side-bar__logout > p:hover {
        color: #ffb40a; }
    .side-bar__logout > img {
      height: 14px;
      width: 16px; }
  .side-bar__data-private {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background: rgba(216, 216, 216, 0.35);
    padding-top: 0.9375rem;
    width: 100%;
    height: 85px; }
    .side-bar__data-private > img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: #ffffff;
      margin-left: 1.2vw;
      margin-right: 6px; }
    .side-bar__data-private__name-number {
      display: flex;
      flex-direction: column; }
      .side-bar__data-private__name-number :nth-child(1) {
        font-size: 20px;
        font-weight: 800;
        color: #ffffff; }
      .side-bar__data-private__name-number :nth-child(2) {
        font-size: 12px;
        color: #ffffff;
        font-weight: 400; }
      .side-bar__data-private__name-number .title-adm {
        font-size: 13px;
        color: #ffffff;
        font-weight: 400; }
        .side-bar__data-private__name-number .title-adm > span {
          font-weight: 500;
          font-size: 15px; }
  .side-bar__navigation__list {
    margin: 0;
    padding: 0 1.2vw; }
    .side-bar__navigation__list :nth-child(1) {
      margin: 0; }
    .side-bar__navigation__list__title {
      font-size: 15px;
      font-weight: 800;
      color: #ffffff;
      margin-bottom: 5px;
      margin-top: 40px; }
      .side-bar__navigation__list__title--active {
        font-size: 15px;
        font-weight: 800;
        margin-bottom: 5px;
        margin-top: 40px;
        color: #ffb40a; }
    .side-bar__navigation__list ul {
      padding: 0; }
    .side-bar__navigation__list li {
      text-decoration: none; }
    .side-bar__navigation__list a {
      text-decoration: none;
      font-size: 13px;
      font-weight: 400;
      color: #ffffff; }
      .side-bar__navigation__list a:hover {
        color: #ffb40a; }

.banner {
  height: 50px;
  width: 100%;
  margin-bottom: 40px;
  z-index: 10;
  position: relative;
  cursor: pointer;
  outline: none; }
  .banner--open .banner__item {
    border-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px; }
  .banner__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 50px;
    padding: 5px 0;
    width: 100%;
    outline: none;
    border: solid 1px #000000;
    border-radius: 25px; }
    .banner__item__name {
      flex: 3 1;
      font-size: 15px;
      font-weight: 600;
      color: #000000; }
    .banner__item__address {
      flex: 4 1;
      font-size: 15px;
      font-weight: 600;
      color: #000000; }
    .banner__item__client-number {
      flex: 4 1;
      font-size: 15px;
      font-weight: 600;
      color: #000000;
      text-align: center; }
    .banner__item img {
      height: 40px;
      width: 20px;
      margin-bottom: 5px;
      flex-basis: 8%; }
    .banner__item span {
      display: flex;
      position: relative;
      top: -2px;
      width: 13px;
      height: 13px;
      margin-right: 2rem;
      border: solid 2px #000000;
      border-left: none;
      border-top: none;
      transform: rotate(45deg); }
  .banner__items {
    background-color: #ffffff;
    border: solid 1px #000000;
    border-top: 0;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    width: 100%; }
    .banner__items__line {
      display: flex;
      margin: auto;
      justify-content: center;
      background-color: #d8d8d8;
      width: 80%;
      height: 1px;
      cursor: none; }
    .banner__items__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      height: 50px;
      padding: 5px 0;
      width: 100%;
      outline: none; }
      .banner__items__item__name {
        flex: 3 1;
        font-size: 15px;
        font-weight: 600;
        color: #000000; }
      .banner__items__item__address {
        flex: 4 1;
        font-size: 15px;
        font-weight: 600;
        color: #000000; }
      .banner__items__item__client-number {
        flex: 4 1;
        font-size: 15px;
        font-weight: 600;
        color: #000000;
        text-align: center; }
      .banner__items__item img {
        height: 40px;
        width: 20px;
        margin-bottom: 5px;
        flex-basis: 8%; }
      .banner__items__item span {
        display: flex;
        position: relative;
        top: -2px;
        width: 13px;
        height: 13px;
        margin-right: 2rem;
        border: solid 2px #000000;
        border-left: none;
        border-top: none;
        transform: rotate(45deg); }
      .banner__items__item > span {
        border: solid 2px transparent; }
      .banner__items__item:hover {
        background-color: #ffb40a; }
      .banner__items__item:nth-last-child(1):hover {
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px; }

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s; }
  .modal--show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s; }
  .modal__content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    height: 45%;
    transform: translate(-50%, -50%);
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .modal__content--error {
      background-color: #d8d8d8; }
    .modal__content__title {
      flex: 1 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
      .modal__content__title > h3 {
        margin-left: 20px;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.2; }
    .modal__content__description {
      flex: 1.7 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .modal__content__description .desc-text {
        margin-top: 10px; }
      .modal__content__description > p {
        width: 90%;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        text-align: center; }
        .modal__content__description > p > a {
          color: #000000; }
        .modal__content__description > p > span {
          display: inline-block;
          margin-bottom: 15px;
          text-align: center; }
    .modal__content__image {
      display: flex;
      flex-direction: row;
      justify-content: center; }
      .modal__content__image > img {
        width: 80px;
        height: 80px; }
    .modal__content__buttons {
      flex: 2 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      .modal__content__buttons .custom-button {
        width: 30%;
        font-size: 11px;
        letter-spacing: 0.7px;
        color: #222325; }
        .modal__content__buttons .custom-button:last-child {
          margin-left: 30px; }
        .modal__content__buttons .custom-button__arrowLeft {
          top: 15px;
          left: 12px; }
    .modal__content:focus {
      outline: none; }
    .modal__content__close-button {
      font-size: 24px;
      color: #222325;
      text-align: right;
      cursor: pointer;
      position: absolute;
      top: 25px;
      right: 25px; }
      .modal__content__close-button:focus {
        outline: none; }
      .modal__content__close-button:hover {
        background-color: darkgray; }
  .modal .layer {
    margin-left: 10px;
    display: inline-block;
    height: 16px;
    width: 16px;
    line-height: 16px;
    border-radius: 8px;
    background-color: #e4e0e0;
    color: #000000;
    text-align: center;
    font-size: 13px;
    cursor: pointer; }
    .modal .layer:focus {
      outline: none; }

.footer {
  width: 100%;
  height: 100px;
  display: flex;
  background-color: #ffffff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative; }
  .footer__certification {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-width: 150px;
    margin-left: 10px;
    flex: 1 1; }
    .footer__certification > a {
      color: #222325; }
    .footer__certification__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 0; }
      .footer__certification__text > p {
        font-size: 10px;
        margin: 5px;
        margin-right: 0; }
  .footer__articles {
    flex: 5 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .footer__articles > a {
      font-size: 11px;
      color: #222325;
      text-decoration: none; }
    .footer__articles > p {
      margin: 0 10px; }
      @media (max-width: 1300px) {
        .footer__articles > p {
          margin: 0 5px; } }
  .footer__social {
    flex: 1 1;
    max-width: 240px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; }
  .footer__bottom_text {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    font-style: italic;
    font-size: 12px; }

.footerAuth {
  width: 90%;
  height: 100px;
  display: flex;
  background-color: #ffffff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 5%;
  border-radius: 25px;
  box-shadow: 0 5.42px 32.5px 0 rgba(200, 200, 200, 0.25);
  left: 50%;
  transform: translateX(-50%); }
  @media screen and (max-height: 635px) {
    .footerAuth {
      height: 75px; } }
  .footerAuth__certification {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-width: 150px;
    margin-left: 10px;
    flex: 1 1; }
    .footerAuth__certification > a {
      color: #222325; }
    .footerAuth__certification__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 0; }
      .footerAuth__certification__text > p {
        font-size: 10px;
        margin: 5px;
        margin-right: 0; }
  .footerAuth__articles {
    flex: 5 1;
    grid-gap: 35px;
    gap: 35px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5%; }
    .footerAuth__articles > a {
      font-size: 16px;
      color: #222325;
      text-decoration: none;
      font-weight: 300; }
    .footerAuth__articles > p {
      margin: 0 10px; }
      @media (max-width: 1300px) {
        .footerAuth__articles > p {
          margin: 0 5px; } }
  .footerAuth__social {
    flex: 1 1;
    max-width: 240px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; }
  .footerAuth__bottom_text {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    font-style: italic;
    font-size: 12px; }

.sliding-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 40vw;
  max-width: 300px;
  height: 100%;
  transition: transform 1s ease-in-out;
  background-color: #F0F0F0;
  /* ou tout autre couleur de fond */
  z-index: 1000;
  transform: translateX(-90%);
  padding: 40px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .sliding-menu__button {
    position: absolute;
    left: 95%;
    top: 5%;
    display: flex;
    border-radius: 100%;
    background-color: #FFF523;
    width: 35px;
    height: 35px;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
  .sliding-menu__img {
    position: absolute;
    left: 95%;
    top: 5%;
    width: 8%;
    cursor: pointer;
    transition: transform 0.9s ease-in-out; }
  .sliding-menu__logo {
    width: 40%;
    margin-bottom: 25px;
    cursor: pointer; }
  .sliding-menu__individual {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    font-size: 20px;
    margin-bottom: 10px;
    font-family: 'Lexend Medium', sans-serif;
    cursor: pointer; }
    .sliding-menu__individual > img {
      transition: transform 0.5s ease-in-out;
      width: 6%;
      height: 6%; }
  .sliding-menu__submenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    grid-gap: 5px;
    gap: 5px;
    font-size: 12px;
    font-family: 'Lexend Medium', sans-serif;
    cursor: pointer;
    max-height: 0;
    visibility: hidden;
    opacity: 0;
    transition: max-height 0.45s ease-in-out, visibility 0s 0.55s linear; }
    .sliding-menu__submenu > a {
      color: #000000;
      text-decoration: none;
      font-weight: 400; }
  .sliding-menu .full {
    max-height: 100%;
    visibility: visible;
    margin-bottom: 12px;
    opacity: 1;
    transition: max-height 0.9s ease-in-out, opacity 0.2s 0.15s ease-in-out, visibility 0s 0s linear; }
  .sliding-menu__content {
    margin-bottom: 10px;
    font-family: 'Lexend Medium', sans-serif; }
    .sliding-menu__content > a {
      color: #000000;
      text-decoration: none;
      font-size: 20px;
      font-weight: 400; }
  .sliding-menu .custom-button-new-gen {
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
    align-self: center;
    margin: 25px auto 10px;
    position: relative;
    bottom: 0;
    font-size: 14px;
    border-radius: 15px; }
    @media screen and (max-height: 622px) {
      .sliding-menu .custom-button-new-gen {
        position: relative;
        margin-top: 20px; } }
  .sliding-menu__footer {
    position: absolute;
    top: 80%; }
    .sliding-menu__footer__title {
      font-size: 12px;
      font-weight: 500;
      color: #000000;
      margin: 0 0 2px;
      font-family: 'Lexend Medium', sans-serif; }
    .sliding-menu__footer__subtitle {
      font-size: 11px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 10px; }
      .sliding-menu__footer__subtitle > a {
        color: #000000;
        text-decoration: none; }

.visible {
  transform: translateX(0) !important; }

.hidden {
  transform: translateX(-90%) !important; }

.open-img {
  transform: rotate(180deg); }

.individualOpen {
  margin-bottom: 4px; }

.open {
  transform: rotate(180deg);
  transition: transform 0.5s ease-in-out;
  background-color: #F0F0F0;
  /* ou tout autre couleur de fond */ }

.balance-container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .balance-container__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #efefef;
    width: 42%;
    border-radius: 20px;
    padding: 20px 26px; }
    .balance-container__block__error {
      font-size: 15px;
      align-self: center;
      margin: 10px 0; }
    .balance-container__block__head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.625rem; }
      .balance-container__block__head > h3 {
        margin: 0;
        font-size: 23px; }
      .balance-container__block__head > p {
        font-size: 16px; }
    .balance-container__block__message > p {
      font-size: 13px; }

.custom-progressbar {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .custom-progressbar > h3 {
    font-size: 20px;
    font-weight: bold; }
  .custom-progressbar__section {
    width: 100%;
    display: flex;
    flex-direction: row; }
  .custom-progressbar__bar-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    font-size: 2.2em;
    font-weight: 100; }
    .custom-progressbar__bar-content > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 102px;
      height: 102px;
      background-color: #efefef;
      border-radius: 50%; }
      .custom-progressbar__bar-content > div > img {
        width: 52px;
        height: 73.5px; }
      .custom-progressbar__bar-content > div > strong {
        font-size: 20px; }
        .custom-progressbar__bar-content > div > strong > span {
          font-size: 10px; }
  .custom-progressbar__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 20%;
    margin-left: 3.125rem; }
    .custom-progressbar__content .rect {
      width: 15px;
      height: 9px;
      border-radius: 4.5px;
      margin: 0 0.375rem; }
    .custom-progressbar__content__rect1 {
      background-color: #dddddb; }
    .custom-progressbar__content__rect2 {
      margin-left: 1.875rem !important;
      background-color: #ffb40a; }

.option-element {
  width: 100%;
  border-radius: 13px;
  border: solid 2px #008000; }
  .option-element > h1 {
    font-size: 16px;
    margin: 20px 20px 0; }
  .option-element__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    padding: 20px;
    /* Safari/Chrome, other WebKit */
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */ }
    .option-element__content__item {
      width: 45%; }
      .option-element__content__item__toggle {
        width: 100%;
        display: flex;
        flex-direction: row; }
        .option-element__content__item__toggle .custom-toggle {
          width: auto;
          margin-right: 5px;
          justify-content: flex-start; }
          .option-element__content__item__toggle .custom-toggle__toggle {
            margin-right: 0; }
          .option-element__content__item__toggle .custom-toggle > p {
            color: #008000; }
          .option-element__content__item__toggle .custom-toggle input[type='checkbox']:active
+ .custom-toggle__toggle
label,
          .option-element__content__item__toggle .custom-toggle input[type='checkbox']:checked
+ .custom-toggle__toggle
label {
            background: #008000; }
      .option-element__content__item__description {
        margin: 0;
        font-size: 11px;
        font-weight: 500; }

.stacked-bar {
  margin-bottom: 20px; }
  .stacked-bar canvas {
    width: 70% !important;
    height: 400px !important; }
  .stacked-bar__legend {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .stacked-bar__legend .custom-toggle {
      width: 200px; }
  .stacked-bar .chartjsLegend ul {
    display: flex;
    flex-direction: row; }
    .stacked-bar .chartjsLegend ul li {
      list-style: none;
      margin-right: 20px; }
      .stacked-bar .chartjsLegend ul li span {
        display: inline-block;
        margin-right: 5px;
        width: 15px;
        height: 9px;
        border-radius: 4.5px; }
  .stacked-bar > h3 {
    font-size: 20px;
    font-weight: bold; }

.Table__itemCount {
  font-size: 14px; }

.Table__pagination {
  display: flex;
  justify-content: flex-end;
  padding: 20px 10px; }

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer; }

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: #b1b1b1; }

.Table__pageButton--active {
  color: #ffb40a;
  font-weight: bold; }

.date-container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .date-container > .date-disclaimer {
    color: #707070;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.25;
    text-align: justify; }
  .date-container__text {
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: 1px;
    text-align: right;
    color: #222325;
    width: 30%;
    margin-right: 30px; }
  .date-container__date {
    width: 100%;
    display: flex;
    flex-direction: row; }
    .date-container__date .selectable .DayPicker-Day--selected:not(.DayPicker-Day--weekend) {
      background-color: #d8d8d8;
      color: #000000;
      border-radius: 50% !important;
      outline: none; }
    .date-container__date .DayPickerInput-Overlay {
      border-top: none !important;
      border: 1px solid #000000;
      transition: width 2s, height 4s;
      width: 278px;
      height: 290px;
      border-radius: 14px;
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .date-container__date__calendars {
      width: 50%;
      display: flex;
      transition: width 2s, height 4s; }
      .date-container__date__calendars .cyclical-billing {
        padding: 5px 10px;
        border: solid 2px black;
        border-radius: 18px; }
        .date-container__date__calendars .cyclical-billing > p {
          height: auto !important;
          font-weight: 800; }
      .date-container__date__calendars__EL-calendar {
        margin-right: 0.5rem; }
        .date-container__date__calendars__EL-calendar > p {
          align-items: center;
          display: flex;
          font-size: 15px;
          font-style: normal;
          font-stretch: normal;
          justify-content: flex-start;
          line-height: 0.8;
          letter-spacing: 1.3px;
          margin-left: 0px;
          vertical-align: baseline; }
        .date-container__date__calendars__EL-calendar .DayPickerInput input {
          border-radius: 14px;
          width: 112px;
          font-size: 15px;
          border: 1px solid #000000;
          text-align: center;
          color: #c8c7bd;
          outline: none;
          margin-right: 50px;
          margin-left: 0px;
          transition: width 2s, height 4s;
          transition: padding-right 2s; }
        .date-container__date__calendars__EL-calendar .DayPickerInput input:focus {
          width: 278px;
          border-bottom-color: #d8d8d8;
          color: #000000;
          text-align: center;
          padding-right: 145px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; }
        .date-container__date__calendars__EL-calendar .DayPickerInput-OverlayWrapper:hover {
          height: 300px;
          margin-left: 0px; }
        .date-container__date__calendars__EL-calendar .DayPickerInput-OverlayWrapper:not(:focus) {
          height: 300px;
          margin-left: 0px; }
        .date-container__date__calendars__EL-calendar__paragraph {
          margin-top: 20px;
          font-size: 13px !important;
          color: #4a4a4a !important;
          font-weight: normal !important;
          line-height: 1.3 !important; }
        .date-container__date__calendars__EL-calendar__infos {
          align-items: flex-start;
          display: flex;
          flex-direction: row; }
          .date-container__date__calendars__EL-calendar__infos__options {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-left: 0px; }
            .date-container__date__calendars__EL-calendar__infos__options p {
              margin: 0;
              font-family: Montserrat;
              font-size: 11px;
              color: #000000; }
          .date-container__date__calendars__EL-calendar__infos__circle_1 {
            border-radius: 50%;
            width: 24px;
            height: 24px;
            margin-right: 10px;
            background-color: #ffb40a; }
          .date-container__date__calendars__EL-calendar__infos__circle_2 {
            border-radius: 50%;
            width: 24px;
            height: 24px;
            margin-right: 10px;
            background-color: #d8d8d8; }
      .date-container__date__calendars__NG-calendar {
        margin-left: 0.5rem; }
        .date-container__date__calendars__NG-calendar > p {
          align-items: center;
          display: flex;
          font-size: 15px;
          font-style: normal;
          font-stretch: normal;
          justify-content: flex-start;
          line-height: 0.8;
          letter-spacing: 1.3px;
          margin-left: 0px;
          vertical-align: baseline; }
        .date-container__date__calendars__NG-calendar .DayPickerInput input {
          border-radius: 14px;
          width: 112px;
          font-size: 15px;
          border: 1px solid #000000;
          text-align: center;
          color: #c8c7bd;
          outline: none;
          margin-right: 50px;
          margin-left: 0px;
          transition: width 2s, height 4s;
          transition: padding-right 2s; }
        .date-container__date__calendars__NG-calendar .DayPickerInput input:focus {
          width: 278px;
          border-bottom-color: #d8d8d8;
          color: #000000;
          text-align: center;
          padding-right: 145px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; }
        .date-container__date__calendars__NG-calendar .DayPickerInput-OverlayWrapper:hover {
          height: 300px;
          margin-left: 0px; }
        .date-container__date__calendars__NG-calendar .DayPickerInput-OverlayWrapper:not(:focus) {
          height: 300px;
          margin-left: 0px; }
        .date-container__date__calendars__NG-calendar__paragraph {
          margin-top: 20px;
          font-size: 13px !important;
          color: #4a4a4a !important;
          font-weight: normal !important;
          line-height: 1.3 !important; }
        .date-container__date__calendars__NG-calendar__infos {
          align-items: flex-start;
          display: flex;
          flex-direction: row; }
          .date-container__date__calendars__NG-calendar__infos__options {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-left: 0px; }
            .date-container__date__calendars__NG-calendar__infos__options p {
              margin: 0;
              font-family: Montserrat;
              font-size: 11px;
              color: #000000; }
          .date-container__date__calendars__NG-calendar__infos__circle_1 {
            border-radius: 50%;
            width: 24px;
            height: 24px;
            margin-right: 10px;
            background-color: #ffb40a; }
          .date-container__date__calendars__NG-calendar__infos__circle_2 {
            border-radius: 50%;
            width: 24px;
            height: 24px;
            margin-right: 10px;
            background-color: #d8d8d8; }
      .date-container__date__calendars .date_container_div {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%; }
        .date-container__date__calendars .date_container_div .right {
          align-self: flex-end;
          width: 215px;
          font-weight: 800;
          font-size: 13px;
          right: -180px;
          bottom: 50px;
          position: absolute; }
        .date-container__date__calendars .date_container_div .right-hidden {
          display: none; }
        .date-container__date__calendars .date_container_div .warnings {
          align-self: flex-start;
          width: 215px;
          font-weight: 600;
          font-size: 10px;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 1rem; }
          .date-container__date__calendars .date_container_div .warnings span {
            color: #707070;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: normal;
            line-height: 1.25;
            margin-bottom: 1rem;
            text-align: justify; }
          .date-container__date__calendars .date_container_div .warnings .attention {
            width: 100%;
            font-weight: 600;
            font-size: 10px;
            text-align: left; }
            .date-container__date__calendars .date_container_div .warnings .attention__title {
              color: red;
              margin-right: 1em; }
        .date-container__date__calendars .date_container_div .hidden {
          display: none; }

.tile {
  align-items: center;
  color: #222325;
  border-radius: 13px;
  border-style: solid;
  border-width: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 1em;
  min-width: 1em;
  overflow: hidden;
  padding: 1rem;
  background-color: #ecece8;
  border-color: transparent; }
  .tile > *:first-child {
    margin-bottom: 1rem;
    margin-top: 0 !important; }
  .tile > *:nth-child() {
    margin-bottom: 1rem !important; }
  .tile > *:last-child {
    margin-bottom: 0 !important; }
  .tile__title-row {
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .tile__title-row span:first-child {
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: .9px;
      line-height: 1.38;
      text-align: justify;
      flex-shrink: 0; }
    .tile__title-row span:nth-child(2) {
      flex-grow: 1;
      font-size: 11px;
      text-align: justify;
      width: 100%;
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .tile__body {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%; }
    .tile__body__text-wrapped {
      flex-grow: 1;
      font-size: 11px;
      text-align: justify;
      width: 100%;
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .tile__body__text-unwrapped {
      flex-grow: 1;
      font-size: 11px;
      text-align: justify;
      width: 100%; }
    .tile__body__toggle {
      font-size: 1.5rem;
      margin: 0 1rem;
      -webkit-user-select: unset;
         -moz-user-select: unset;
          -ms-user-select: unset;
              user-select: unset; }
      .tile__body__toggle:active {
        opacity: 0.8; }

.tile-active {
  align-items: center;
  color: #222325;
  border-radius: 13px;
  border-style: solid;
  border-width: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 1em;
  min-width: 1em;
  overflow: hidden;
  padding: 1rem;
  background-color: white;
  border-color: #ffb40a; }
  .tile-active > *:first-child {
    margin-bottom: 1rem;
    margin-top: 0 !important; }
  .tile-active > *:nth-child() {
    margin-bottom: 1rem !important; }
  .tile-active > *:last-child {
    margin-bottom: 0 !important; }
  .tile-active__title-row {
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .tile-active__title-row span:first-child {
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: .9px;
      line-height: 1.38;
      text-align: justify;
      flex-shrink: 0; }
    .tile-active__title-row span:nth-child(2) {
      flex-grow: 1;
      font-size: 11px;
      text-align: justify;
      width: 100%;
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .tile-active__body {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%; }
    .tile-active__body__text-wrapped {
      flex-grow: 1;
      font-size: 11px;
      text-align: justify;
      width: 100%;
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .tile-active__body__text-unwrapped {
      flex-grow: 1;
      font-size: 11px;
      text-align: justify;
      width: 100%; }
    .tile-active__body__toggle {
      font-size: 1.5rem;
      margin: 0 1rem;
      -webkit-user-select: unset;
         -moz-user-select: unset;
          -ms-user-select: unset;
              user-select: unset; }
      .tile-active__body__toggle:active {
        opacity: 0.8; }

.tile-highlighted {
  align-items: center;
  color: #222325;
  border-radius: 13px;
  border-style: solid;
  border-width: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 1em;
  min-width: 1em;
  overflow: hidden;
  padding: 1rem;
  background-color: #ecece8;
  border-color: #222325; }
  .tile-highlighted > *:first-child {
    margin-bottom: 1rem;
    margin-top: 0 !important; }
  .tile-highlighted > *:nth-child() {
    margin-bottom: 1rem !important; }
  .tile-highlighted > *:last-child {
    margin-bottom: 0 !important; }
  .tile-highlighted__title-row {
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .tile-highlighted__title-row span:first-child {
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: .9px;
      line-height: 1.38;
      text-align: justify;
      flex-shrink: 0; }
    .tile-highlighted__title-row span:nth-child(2) {
      flex-grow: 1;
      font-size: 11px;
      text-align: justify;
      width: 100%;
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .tile-highlighted__body {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%; }
    .tile-highlighted__body__text-wrapped {
      flex-grow: 1;
      font-size: 11px;
      text-align: justify;
      width: 100%;
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .tile-highlighted__body__text-unwrapped {
      flex-grow: 1;
      font-size: 11px;
      text-align: justify;
      width: 100%; }
    .tile-highlighted__body__toggle {
      font-size: 1.5rem;
      margin: 0 1rem;
      -webkit-user-select: unset;
         -moz-user-select: unset;
          -ms-user-select: unset;
              user-select: unset; }
      .tile-highlighted__body__toggle:active {
        opacity: 0.8; }

.checkbox-tile {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: stretch; }
  .checkbox-tile > .custom-checkbox {
    margin-top: 1rem; }
  .checkbox-tile > .tile, .checkbox-tile .tile-active {
    flex-grow: 1; }

.address-select {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .address-select > *:first-child {
    margin-left: 0 !important;
    margin-right: 1.2vw; }
  .address-select > * {
    margin-right: 1.2vw; }
  .address-select > *:last-child {
    margin-right: 0 !important; }
  .address-select > span {
    margin-bottom: 1rem; }
  .address-select__checkboxes {
    display: flex;
    flex-direction: row; }
    .address-select__checkboxes > *:first-child {
      margin-left: 0 !important;
      margin-right: 1.2vw; }
    .address-select__checkboxes > * {
      margin-right: 1.2vw; }
    .address-select__checkboxes > *:last-child {
      margin-right: 0 !important; }
  .address-select__inputs {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .address-select__inputs__address {
      align-items: flex-start;
      grid-column-gap: 1.2vw;
      -webkit-column-gap: 1.2vw;
              column-gap: 1.2vw;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%; }
      .address-select__inputs__address > div {
        flex-grow: 1; }
      .address-select__inputs__address > .custom-input:nth-of-type(3) input {
        background-color: #efefef; }
  .address-select .custom-select {
    flex-shrink: 0;
    width: 30%; }
  .address-select .custom-checkbox label:before {
    margin-top: 0; }
  .address-select .custom-select__content {
    display: flex;
    flex-direction: column; }
    .address-select .custom-select__content label {
      align-self: flex-start; }
  .address-select .custom-input input {
    width: 100%; }

.custom-error {
  border: solid 1px black;
  border-radius: 20px;
  font-family: Montserrat,Helvetica,Arial,sans-serif;
  margin: 1em;
  overflow: hidden;
  padding: 1em; }
  .custom-error__text {
    color: #222325;
    font-size: 0.8rem;
    font-weight: 300; }
  .custom-error__title {
    color: #d0021b;
    font-size: 0.8rem;
    font-weight: 700; }

.custom-overlay {
  display: flex;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 1004; }
  .custom-overlay__background {
    background-color: black;
    height: 100vh;
    left: 0;
    min-height: 3em;
    min-width: 3em;
    opacity: 0.5;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1001; }
  .custom-overlay__window {
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    z-index: 1005; }
    .custom-overlay__window__content {
      align-items: center;
      background-color: white;
      border-radius: 0.5em;
      display: flex;
      flex-direction: column;
      height: unset;
      overflow: visible;
      padding: 0;
      width: unset; }
      .custom-overlay__window__content__close-button {
        font-size: 24px;
        color: #222325;
        text-align: right;
        cursor: pointer;
        position: absolute;
        top: 30px;
        right: 30px; }
        .custom-overlay__window__content__close-button:focus {
          outline: none; }
        .custom-overlay__window__content__close-button:hover {
          background-color: darkgray; }

.custom-overlay--hidden {
  height: 0 !important;
  visibility: hidden;
  width: 0 !important;
  z-index: -1000;
  display: flex;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 1004; }
  .custom-overlay--hidden__background {
    background-color: black;
    height: 100vh;
    left: 0;
    min-height: 3em;
    min-width: 3em;
    opacity: 0.5;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1001; }
  .custom-overlay--hidden__window {
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    z-index: 1005; }
    .custom-overlay--hidden__window__content {
      align-items: center;
      background-color: white;
      border-radius: 0.5em;
      display: flex;
      flex-direction: column;
      height: unset;
      overflow: visible;
      padding: 0;
      width: unset; }
      .custom-overlay--hidden__window__content__close-button {
        font-size: 24px;
        color: #222325;
        text-align: right;
        cursor: pointer;
        position: absolute;
        top: 30px;
        right: 30px; }
        .custom-overlay--hidden__window__content__close-button:focus {
          outline: none; }
        .custom-overlay--hidden__window__content__close-button:hover {
          background-color: darkgray; }

.custom-overlay--centered {
  align-items: center;
  justify-content: center;
  position: fixed;
  display: flex;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 1004; }
  .custom-overlay--centered__background {
    background-color: black;
    height: 100vh;
    left: 0;
    min-height: 3em;
    min-width: 3em;
    opacity: 0.5;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1001; }
  .custom-overlay--centered__window {
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    z-index: 1005; }
    .custom-overlay--centered__window__content {
      align-items: center;
      background-color: white;
      border-radius: 0.5em;
      display: flex;
      flex-direction: column;
      height: unset;
      overflow: visible;
      padding: 0;
      width: unset; }
      .custom-overlay--centered__window__content__close-button {
        font-size: 24px;
        color: #222325;
        text-align: right;
        cursor: pointer;
        position: absolute;
        top: 30px;
        right: 30px; }
        .custom-overlay--centered__window__content__close-button:focus {
          outline: none; }
        .custom-overlay--centered__window__content__close-button:hover {
          background-color: darkgray; }

.error-element {
  display: flex;
  flex-direction: column;
  width: 98%;
  border-radius: 10px;
  border: solid 2px #ff455c;
  background-color: #ffffff;
  padding: 0.9375rem 2.5rem;
  margin: 1.25rem 0; }
  .error-element > p {
    color: #ff455c;
    font-size: 11px;
    font-weight: 500; }
    .error-element > p > span {
      font-weight: 600; }
  .error-element > ul {
    padding: 0;
    margin: 0;
    list-style: none; }
    .error-element > ul > li > p {
      color: #ff455c;
      font-size: 11px;
      font-weight: 500; }
      .error-element > ul > li > p > span {
        font-weight: 600; }

.section-separator {
  display: flex;
  justify-content: center;
  width: 100%; }
  .section-separator > div:first-child {
    background-color: #ffb40a;
    height: 2px;
    margin: 2em;
    max-width: 30%;
    min-width: 240px; }

.close-button {
  color: #222325;
  cursor: pointer;
  font-size: 24px;
  padding: 30px;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: right;
  z-index: 1004; }

.grey-area:active {
  opacity: 0.8; }

.grey-area:hover {
  background-color: #a9a9a9; }

.toggle-indicator {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .toggle-indicator--centered {
    align-items: center;
    justify-content: center; }
    .toggle-indicator--centered span {
      visibility: hidden; }
  .toggle-indicator--left {
    align-items: center;
    justify-content: flex-end; }
    .toggle-indicator--left span {
      padding-right: 0.5rem;
      text-align: right; }
  .toggle-indicator--right {
    align-items: center;
    justify-content: flex-start; }
    .toggle-indicator--right span {
      padding-left: 0.5rem;
      text-align: left; }
  .toggle-indicator div {
    border: 2px #ffb40a solid;
    border-radius: 50%;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    height: 1em;
    margin: 0;
    width: 1em; }
  .toggle-indicator .on {
    background-color: #ffb40a; }
  .toggle-indicator .off {
    background-color: white; }
  .toggle-indicator span {
    flex-grow: 1;
    flex-shrink: 0; }

.coords-inputs {
  align-items: flex-start;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .coords-inputs > *:first-child {
    margin-left: 0 !important;
    margin-right: 1rem; }
  .coords-inputs > * {
    margin-right: 1rem; }
  .coords-inputs > *:last-child {
    margin-right: 0 !important; }
  .coords-inputs .custom-input {
    width: 10rem; }
    .coords-inputs .custom-input input {
      width: 100%; }
  .coords-inputs .custom-select {
    height: 100% !important;
    width: 8rem; }
    .coords-inputs .custom-select__content {
      align-items: flex-start;
      display: flex;
      flex-direction: column; }

.timeline-status {
  margin: 20px 0;
  padding-top: 25px; }
  .timeline-status .timeline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    width: 90%; }
    .timeline-status .timeline-small {
      width: 60%; }
    .timeline-status .timeline .step {
      position: relative; }
      .timeline-status .timeline .step__label {
        position: absolute;
        top: -18px;
        left: -78px;
        width: 160px;
        text-align: center;
        color: grey;
        font-weight: 600; }
      .timeline-status .timeline .step__date {
        position: absolute;
        top: -18px;
        left: -78px;
        width: 160px;
        text-align: center;
        color: grey;
        font-weight: 600;
        top: -35px;
        font-weight: 500; }
        .timeline-status .timeline .step__date-last {
          font-weight: 800;
          color: black; }
      .timeline-status .timeline .step > span {
        background-color: black;
        width: 2.5px;
        height: 40px;
        display: flex; }
    .timeline-status .timeline .segment {
      width: 100%;
      height: 40px;
      position: relative;
      display: flex;
      flex-direction: column; }
      .timeline-status .timeline .segment .radius-start {
        position: absolute;
        left: -8.7px;
        height: 14px;
        width: 6px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border: 3px solid black;
        border-right: none;
        z-index: 1; }
      .timeline-status .timeline .segment .radius-end {
        position: absolute;
        right: -8.7px;
        height: 14px;
        width: 6px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 3px solid black;
        border-left: none;
        z-index: 1; }
      .timeline-status .timeline .segment .success-icon {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        right: -62px;
        top: 30px; }
        .timeline-status .timeline .segment .success-icon > span {
          background-color: black;
          width: 2.5px;
          height: 25px;
          display: flex; }
        .timeline-status .timeline .segment .success-icon > img {
          width: 25px;
          margin: 5px 0; }
        .timeline-status .timeline .segment .success-icon > p {
          font-size: 9px;
          font-weight: 800; }
      .timeline-status .timeline .segment .icon {
        height: 50%;
        position: relative; }
        .timeline-status .timeline .segment .icon > img {
          width: 30px;
          position: absolute;
          left: 45%;
          bottom: 10px; }
      .timeline-status .timeline .segment .color {
        height: 50%; }
        .timeline-status .timeline .segment .color-border {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px; }
        .timeline-status .timeline .segment .color-red {
          background-color: #d35e40; }
          .timeline-status .timeline .segment .color-red-pending {
            background-color: #e69f8d; }
        .timeline-status .timeline .segment .color-yellow {
          background-color: #ffb40a; }
        .timeline-status .timeline .segment .color-orange {
          background-color: #fe8e00; }
        .timeline-status .timeline .segment .color-green {
          background-color: #1eaa82; }
        .timeline-status .timeline .segment .color-grey {
          background-color: #c8c8be; }
  .timeline-status__details > h4 {
    cursor: pointer; }
    .timeline-status__details > h4 .arrow {
      border: solid black;
      border-width: 0 4px 4px 0;
      display: inline-block;
      padding: 4px;
      margin-left: 10px; }
    .timeline-status__details > h4 .up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg); }
    .timeline-status__details > h4 .down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg); }
  .timeline-status__details div {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px; }
    .timeline-status__details div > ul {
      list-style: none;
      padding: 0;
      margin: 0; }
    .timeline-status__details div .ul-dates .first {
      font-weight: 800;
      color: black; }
    .timeline-status__details div .ul-dates > li {
      padding: 5px 0;
      color: grey;
      font-weight: 600; }
    .timeline-status__details div .ul-labels {
      border-left: solid 3px black;
      padding-left: 20px;
      margin-left: 20px; }
      .timeline-status__details div .ul-labels .first {
        font-weight: 800;
        color: black; }
      .timeline-status__details div .ul-labels > li {
        padding: 5px 0;
        color: grey;
        font-weight: 600; }

.stripe-form-container {
  width: 95%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start; }
  .stripe-form-container .formContent {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  .stripe-form-container .logo-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 30px 0px 30px 0px; }
  .stripe-form-container .button-container {
    font-size: 0.9rem;
    display: flex;
    justify-content: space-between;
    margin-top: 20px; }
    .stripe-form-container .button-container .button-cancel {
      font-size: inherit;
      background-color: inherit;
      cursor: pointer; }
    .stripe-form-container .button-container .button-validate {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 10px 30px 10px 30px;
      background-color: #ffb40a;
      font-size: inherit;
      text-align: center;
      cursor: pointer;
      border-radius: 3px; }
  .stripe-form-container .stripe-card-container {
    display: flex;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
    text-align: start; }
    @media screen and (max-width: 568px) {
      .stripe-form-container .stripe-card-container {
        justify-content: flex-start;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px; } }
    .stripe-form-container .stripe-card-container .stripe-card-number-field {
      padding: 5px;
      width: 150px;
      border: 1px solid rgba(0, 0, 0, 0.24);
      background-color: white; }
    .stripe-form-container .stripe-card-container .stripe-card-expiry-field {
      padding: 5px;
      width: 70px;
      border: 1px solid rgba(0, 0, 0, 0.24);
      background-color: white; }
    .stripe-form-container .stripe-card-container .stripe-card-cvc-field {
      padding: 5px;
      width: 45px;
      border: 1px solid rgba(0, 0, 0, 0.24);
      background-color: white; }
  .stripe-form-container .loader {
    position: absolute;
    padding-top: 270px;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #797979;
    z-index: 0; }
  .stripe-form-container .stripe-card-debug {
    margin: 20px 0 0 0;
    background-color: #dadada;
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
    border: 2px #ffb40a solid; }
    .stripe-form-container .stripe-card-debug .clicked-fake-card-label {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
      .stripe-form-container .stripe-card-debug .clicked-fake-card-label > span {
        position: absolute;
        top: 0px;
        right: 50%;
        transform: translate(50%, -110%);
        background-color: #524b4b;
        padding: 5px;
        border-radius: 5px;
        color: white; }
    .stripe-form-container .stripe-card-debug > span {
      font-style: italic;
      font-size: 0.8rem;
      text-align: center;
      font-weight: 600;
      width: 100%;
      margin: 0 0 10px 0;
      text-transform: uppercase; }
    .stripe-form-container .stripe-card-debug > *:not(span) {
      display: flex;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.9rem; }
      .stripe-form-container .stripe-card-debug > *:not(span) > button {
        margin-left: 5px;
        position: relative;
        font-size: 0.8rem;
        cursor: pointer;
        background-color: white;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        padding: 4px 10px 4px 10px;
        border-radius: 10px; }
        .stripe-form-container .stripe-card-debug > *:not(span) > button:hover {
          text-decoration: underline; }

.article-video-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #efefef; }
  .article-video-container__videos {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around; }
    .article-video-container__videos__section {
      flex: 1 1;
      height: 40%;
      margin: 50px; }
      .article-video-container__videos__section:hover {
        cursor: pointer; }
      .article-video-container__videos__section:not(:last-child) {
        margin-right: 0px; }
      .article-video-container__videos__section__iframe {
        display: block;
        overflow: hidden;
        width: 100%;
        height: auto;
        border-radius: 20px;
        transform: translateZ(0px);
        border: 3px solid #eee; }
        .article-video-container__videos__section__iframe img {
          display: block;
          width: 100%;
          height: auto; }
      .article-video-container__videos__section > p {
        margin-top: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #000000; }
  .article-video-container__articles {
    width: calc(100% - 100px);
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px; }
    .article-video-container__articles__section {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #979797;
      margin: 10px 0; }
      .article-video-container__articles__section > a {
        flex: 5 1;
        text-decoration: none;
        font-size: 15px;
        font-weight: 800;
        color: #000000; }
      .article-video-container__articles__section > p {
        flex: 1 1;
        font-size: 15px;
        font-weight: 400;
        text-align: right;
        height: 25px; }

.fail-piece-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  border-radius: 20px;
  margin-bottom: 20px; }
  .fail-piece-container__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px; }
    .fail-piece-container__title > p {
      font-size: 18px;
      font-weight: 600;
      margin-left: 10px; }
    .fail-piece-container__title__exclamation-point {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      width: 24px;
      height: 24px;
      background-color: #ffb40a;
      color: #ffffff;
      font-size: 18px;
      font-weight: 500; }
  .fail-piece-container__calendar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 36px; }
    .fail-piece-container__calendar .custom-daypicker {
      margin-right: 80px; }
    .fail-piece-container__calendar .custom-button {
      align-self: flex-end;
      height: 40px;
      width: 150px; }
  .fail-piece-container > p {
    font-size: 18px;
    margin-top: 16px;
    padding-left: 36px; }
  .fail-piece-container .custom-button {
    width: 20%;
    margin: 20px;
    margin-left: 30px; }

.payment-status-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .payment-status-container__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #efefef;
    width: 42%;
    height: 95px;
    border-radius: 20px;
    padding: 20px 26px; }
    .payment-status-container__block > p {
      font-size: 16px;
      color: #000000;
      font-weight: 400; }
    .payment-status-container__block__data {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .payment-status-container__block__data > h3 {
        font-size: 23px;
        color: #000000;
        font-weight: 600;
        margin-bottom: 0; }
      .payment-status-container__block__data__date {
        font-size: 15px;
        color: #000000;
        font-weight: 400;
        align-self: flex-end;
        margin-bottom: 0.125rem; }
      .payment-status-container__block__data__none {
        margin-top: 3.125rem;
        font-weight: 600;
        font-size: 18px; }

@font-face {
  font-family: 'Lexend';
  src: url(/static/media/Lexend-Regular.fd2d8ea0.ttf) format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Lexend';
  src: url(/static/media/Lexend-Light.b761fd24.ttf) format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Gowun';
  src: url(/static/media/GowunBatang-Regular.e4054a18.ttf) format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Lexend';
  src: url(/static/media/Lexend-SemiBold.b92c90c9.ttf) format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Lexend';
  src: url(/static/media/Lexend-Bold.f7a85c50.ttf) format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Lexend Medium';
  src: url(/static/media/Lexend-Medium.2d1eb765.ttf) format("truetype");
  font-weight: 400;
  font-style: normal; }

.auth-page {
  height: 100vh;
  position: relative;
  font-family: 'Lexend', sans-serif !important;
  font-weight: 500; }
  .auth-page__logo {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }

.auth-page__inner {
  background-color: #FFF523;
  width: 23%;
  height: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  display: flex;
  padding: 40px 0;
  border-radius: 5%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around; }
  @media screen and (max-height: 622px) {
    .auth-page__inner {
      flex-direction: column;
      height: auto;
      width: 25%;
      padding: 30px; } }
  .auth-page__inner .custom-modal__content {
    background-color: transparent;
    left: 10vw;
    top: 15.625rem;
    transform: translate(0, 0);
    padding: 40px; }
  .auth-page__inner__logo {
    width: 35%; }
    @media screen and (max-height: 622px) {
      .auth-page__inner__logo {
        margin: auto;
        width: 60%; } }
  .auth-page__inner__line {
    width: 1px;
    background-color: #979797;
    height: 100%; }
    @media screen and (max-height: 622px) {
      .auth-page__inner__line {
        width: 60%;
        height: 1px;
        margin: 15px 0;
        align-self: center; } }
  .auth-page__inner__connection {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    justify-content: center; }
    @media screen and (max-height: 622px) {
      .auth-page__inner__connection {
        width: 60%;
        margin: auto; } }
    .auth-page__inner__connection__logo {
      width: 30%;
      align-self: center;
      position: absolute;
      top: 5%; }
      @media screen and (max-height: 622px) {
        .auth-page__inner__connection__logo {
          margin: auto;
          width: 60%;
          top: 0.2%; } }
    .auth-page__inner__connection > h1 {
      align-self: center;
      font-size: 1.5vw;
      font-weight: 400;
      font-family: 'Gowun', serif;
      text-align: center;
      margin-bottom: 1.2em;
      margin-top: 1.5em; }
    .auth-page__inner__connection > h1 > span {
      font-weight: 600;
      font-family: 'Lexend Medium', sans-serif; }
    .auth-page__inner__connection__title {
      align-self: center;
      font-size: 1.5vw;
      font-weight: 400;
      font-family: 'Gowun', serif;
      text-align: center;
      margin-bottom: 1.2em;
      margin-top: 1.5em;
      display: flex;
      flex-direction: column;
      grid-gap: 1px;
      gap: 1px; }
      .auth-page__inner__connection__title > span {
        margin-bottom: -0.20em; }
      .auth-page__inner__connection__title > .bold {
        font-weight: 600;
        font-family: 'Lexend Medium', sans-serif; }
    .auth-page__inner__connection > h6 {
      font-size: 1.2vw;
      margin: 0;
      letter-spacing: 1.07px; }
    .auth-page__inner__connection > p {
      color: #E00A0A;
      margin-top: 0;
      margin-bottom: 10px; }
    .auth-page__inner__connection .pass {
      margin-top: 40px; }
    .auth-page__inner__connection .custom-input-new-gen {
      width: 100%;
      margin-bottom: 15px; }
      .auth-page__inner__connection .custom-input-new-gen__input {
        width: 100%; }
      @media screen and (max-height: 622px) {
        .auth-page__inner__connection .custom-input-new-gen {
          width: 100%; } }
    .auth-page__inner__connection .custom-button-new-gen {
      width: 40%;
      align-self: center;
      margin: 15px auto 10px;
      position: relative;
      bottom: 0;
      font-size: 14px; }
      @media screen and (max-height: 622px) {
        .auth-page__inner__connection .custom-button-new-gen {
          position: relative;
          margin-top: 20px;
          width: 100%; } }
    .auth-page__inner__connection > a {
      margin-top: 0.4375rem;
      font-size: 13px;
      color: #000000;
      font-weight: 400;
      text-align: center; }
  .auth-page__inner__password-forgot {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    justify-content: center; }
    @media screen and (max-height: 622px) {
      .auth-page__inner__password-forgot {
        width: 100%;
        margin: auto; } }
    .auth-page__inner__password-forgot__logo {
      width: 30%;
      align-self: center; }
      @media screen and (max-height: 622px) {
        .auth-page__inner__password-forgot__logo {
          margin: auto;
          width: 60%; } }
    .auth-page__inner__password-forgot > h1 {
      align-self: center;
      font-size: 1.5vw;
      font-weight: 400;
      font-family: 'Gowun', serif;
      text-align: center;
      margin-bottom: 1.2em; }
    .auth-page__inner__password-forgot > h1 > span {
      font-weight: 500;
      font-family: 'Lexend', sans-serif; }
    .auth-page__inner__password-forgot > h2 {
      font-size: 1vw;
      line-height: 1.2;
      font-weight: 600;
      letter-spacing: 1px;
      color: #222325; }
    .auth-page__inner__password-forgot > h3 {
      font-size: 0.85vw;
      line-height: 1.2;
      font-weight: 300;
      letter-spacing: 1px;
      color: #222325;
      margin: 0;
      text-align: center; }
    .auth-page__inner__password-forgot > .bold {
      font-weight: 800 !important; }
    .auth-page__inner__password-forgot > p {
      letter-spacing: 1.07px;
      font-size: 16px;
      margin: 1.875rem 0 0.4375rem 0; }
      .auth-page__inner__password-forgot > p:not(:last-child) {
        font-weight: 800; }
    .auth-page__inner__password-forgot .custom-input-new-gen {
      width: 100%;
      margin: 15px 0 0; }
      .auth-page__inner__password-forgot .custom-input-new-gen__input {
        width: 100%; }
    .auth-page__inner__password-forgot__buttons {
      margin-top: 1.875rem;
      display: flex;
      justify-content: space-around;
      grid-gap: 15px;
      gap: 15px; }
      .auth-page__inner__password-forgot__buttons .custom-button-new-gen {
        flex: 50 1;
        font-size: 15px; }
    .auth-page__inner__password-forgot__error {
      color: #E00A0A;
      margin-top: 0;
      margin-bottom: 10px;
      text-align: center; }
    .auth-page__inner__password-forgot__success {
      color: #008000;
      text-align: center; }

.auth-page {
  height: 100vh;
  position: relative; }

.auth-page__inner_password {
  background-color: #FFF523;
  width: 23%;
  height: 57%;
  margin: 0;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  display: flex;
  padding: 40px 0;
  border-radius: 5%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around; }
  @media screen and (max-height: 622px) {
    .auth-page__inner_password {
      flex-direction: column;
      width: 20%;
      height: 50%;
      padding: 30px;
      top: 45%;
      transform: translateY(-55%); } }
  @media screen and (max-width: 1200px) {
    .auth-page__inner_password {
      width: 35%;
      height: 50%;
      padding: 30px;
      top: 45%;
      transform: translateY(-55%); } }
  .auth-page__inner_password .custom-modal__content {
    background-color: transparent;
    left: 10vw;
    top: 15.625rem;
    transform: translate(0, 0);
    padding: 40px; }
  .auth-page__inner_password__logo {
    width: 35%; }
    @media screen and (max-height: 622px) {
      .auth-page__inner_password__logo {
        margin: auto;
        width: 60%; } }
  .auth-page__inner_password__line {
    width: 1px;
    background-color: #979797;
    height: 100%; }
    @media screen and (max-height: 622px) {
      .auth-page__inner_password__line {
        width: 60%;
        height: 1px;
        margin: 15px 0;
        align-self: center; } }
  .auth-page__inner_password__reset-password {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative; }
    @media screen and (max-height: 622px) {
      .auth-page__inner_password__reset-password {
        width: 60%;
        margin: auto; } }
    .auth-page__inner_password__reset-password__logo {
      width: 30%;
      align-self: center;
      position: absolute;
      top: 2%; }
      @media screen and (max-height: 622px) {
        .auth-page__inner_password__reset-password__logo {
          margin: auto;
          width: 60%;
          top: 0.2%; } }
    .auth-page__inner_password__reset-password > h1 {
      align-self: center;
      font-size: 30px;
      text-align: center;
      font-weight: 800; }
    .auth-page__inner_password__reset-password > h6 {
      font-size: 0.8vw;
      margin: 0;
      letter-spacing: 1.07px; }
    .auth-page__inner_password__reset-password__title {
      align-self: center;
      font-size: 1.5vw;
      font-weight: 400;
      font-family: 'Gowun', serif;
      text-align: center;
      margin-bottom: 1.2em;
      margin-top: 1.5em;
      display: flex;
      flex-direction: column;
      grid-gap: 1px;
      gap: 1px; }
      .auth-page__inner_password__reset-password__title > span {
        margin-bottom: -0.20em; }
      .auth-page__inner_password__reset-password__title > .bold {
        font-weight: 600;
        font-family: 'Lexend Medium', sans-serif; }
    .auth-page__inner_password__reset-password > p {
      color: #d0021b;
      margin-top: 1.25rem;
      width: 100%;
      max-height: 42px;
      overflow-y: auto; }
      @media screen and (min-width: 820px) {
        .auth-page__inner_password__reset-password > p {
          margin-top: 20px;
          grid-gap: 5px;
          gap: 5px;
          flex-direction: column;
          font-size: 0.99rem; } }
    .auth-page__inner_password__reset-password .success-message {
      color: #008000;
      font-size: 0.90rem;
      margin: 0; }
    .auth-page__inner_password__reset-password .pass {
      margin-top: 5px;
      margin-bottom: 2px; }
    .auth-page__inner_password__reset-password .custom-input {
      width: 100%;
      margin: 0; }
      .auth-page__inner_password__reset-password .custom-input__input {
        width: 100%; }
    .auth-page__inner_password__reset-password .buttonContainers {
      display: flex;
      flex-direction: row;
      place-self: start;
      width: 100%;
      margin-top: 10px;
      grid-gap: 5px;
      gap: 5px;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column; }
      @media screen and (min-width: 820px) {
        .auth-page__inner_password__reset-password .buttonContainers {
          justify-content: space-around;
          flex-direction: row; } }
    .auth-page__inner_password__reset-password .custom-button-new-gen {
      position: static;
      width: 50%;
      align-self: center;
      margin: 0 auto;
      bottom: 0; }
      @media screen and (max-width: 811px) {
        .auth-page__inner_password__reset-password .custom-button-new-gen {
          align-self: flex-start;
          margin: 0; } }
    .auth-page__inner_password__reset-password > a {
      margin-top: 0.4375rem;
      font-size: 11px;
      color: #000000;
      font-weight: 800; }
  .auth-page__inner_password__password-forgot {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    justify-content: center; }
    .auth-page__inner_password__password-forgot > h1 {
      align-self: center;
      font-size: 1.5vw;
      font-weight: 500;
      text-align: center;
      margin-bottom: 1.2em; }
    .auth-page__inner_password__password-forgot > h1 > span {
      font-weight: 800; }
    .auth-page__inner_password__password-forgot > h2 {
      font-size: 1vw;
      line-height: 1.2;
      font-weight: 600;
      letter-spacing: 1px;
      color: #222325; }
    .auth-page__inner_password__password-forgot > h3 {
      font-size: 0.85vw;
      line-height: 1.2;
      font-weight: 300;
      letter-spacing: 1px;
      color: #222325;
      margin: 0;
      text-align: center; }
    .auth-page__inner_password__password-forgot > .bold {
      font-weight: 800 !important; }
    .auth-page__inner_password__password-forgot > p {
      letter-spacing: 1.07px;
      font-size: 16px;
      margin: 1.875rem 0 0.4375rem 0; }
      .auth-page__inner_password__password-forgot > p:not(:last-child) {
        font-weight: 800; }
    .auth-page__inner_password__password-forgot .custom-input-new-gen {
      width: 100%;
      margin: 15px 0 0; }
      .auth-page__inner_password__password-forgot .custom-input-new-gen__input {
        width: 100%; }
    .auth-page__inner_password__password-forgot__error {
      color: #d0021b;
      margin-top: 1.25rem;
      text-align: center; }

.custom-overlay-password {
  background-color: transparent !important; }

.home-container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .home-container > h2 {
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px; }
  .home-container .levy-refused-container {
    width: 100%;
    margin: 30px 0; }
  .home-container .article-video-container {
    width: 100%; }
  .home-container .stacked-bar canvas {
    padding-left: 40px; }
  .home-container .stacked-bar__legend {
    padding-left: 40px; }
  .home-container .stacked-bar__container {
    border-radius: 20px;
    background-color: #efefef; }

.move-container {
  display: flex;
  flex-direction: column; }
  .move-container__title {
    font-size: 25px; }
  .move-container__sub-title {
    font-size: 20px; }
  .move-container .custom-banner {
    width: 100%;
    margin-bottom: 6vh; }
  .move-container__content {
    font-size: 15px;
    margin-bottom: 1.5625rem; }
    .move-container__content > a {
      color: #000000; }
  .move-container .custom-button {
    width: 15%; }

.bill-container {
  display: flex;
  flex-direction: column; }
  .bill-container__title {
    font-size: 25px; }
  .bill-container .custom-banner {
    width: 100%;
    margin-bottom: 6vh; }
  .bill-container__amount {
    font-size: 20px;
    font-weight: 800;
    color: #000000;
    margin-bottom: 30px; }
  .bill-container .custom-table .rt-td {
    color: #4a4a4a;
    font-size: 13px;
    align-self: center; }
    .bill-container .custom-table .rt-td > a {
      color: #4a4a4a;
      font-size: 13px; }
  .bill-container .invoice-routing {
    padding-top: 20px;
    display: flex;
    flex-direction: column; }
    .bill-container .invoice-routing__bloc {
      margin-top: 30px;
      border-radius: 20px;
      border: solid 2px #000000;
      min-height: 80px; }
      .bill-container .invoice-routing__bloc span {
        display: inline-block;
        margin: 30px 0.5rem auto 1.5rem; }
      .bill-container .invoice-routing__bloc .custom-select {
        position: relative;
        top: -5px;
        width: 20%;
        display: inline-block; }
      .bill-container .invoice-routing__bloc .custom-button {
        width: 15%;
        font-size: 15px;
        margin: 20px 1.5rem auto auto; }
      .bill-container .invoice-routing__bloc .profile-container__buttons .custom-button {
        margin: auto 0.5rem 1rem 0.5rem;
        width: 100%; }
    .bill-container .invoice-routing .inactive {
      border: none;
      background-color: #EFEFEF; }
      .bill-container .invoice-routing .inactive .custom-button {
        float: right; }
    .bill-container .invoice-routing .active {
      min-height: 140px; }
      .bill-container .invoice-routing .active .custom-button {
        width: 15%; }
    .bill-container .invoice-routing__value {
      font-size: 15px;
      font-weight: 600;
      color: #000000;
      display: inline-block;
      margin: 30px 0.5rem auto 0.25rem; }
    .bill-container .invoice-routing__title {
      font-size: 20px;
      font-weight: 800;
      color: #000000;
      margin: 30px 0.5rem auto 0.25rem; }

.file-container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .file-container__title {
    font-size: 25px; }
  .file-container .custom-banner {
    width: 100%;
    margin-bottom: 6vh; }
  .file-container__file {
    font-size: 20px;
    font-weight: 800;
    color: #000000;
    margin-bottom: 30px; }
  .file-container__paragraph {
    color: #4a4a4a;
    font-size: 13px;
    margin-top: 1.875rem; }
    .file-container__paragraph a {
      color: #4a4a4a; }
  .file-container .custom-button {
    width: 30%;
    margin-top: 0.9375rem; }
  .file-container .custom-table .rt-resizable-header:nth-child(2) .rt-resizable-header-content {
    text-align: left;
    padding-left: 3vw; }
  .file-container .custom-table .rt-td {
    color: #4a4a4a;
    font-size: 13px;
    align-self: center; }
    .file-container .custom-table .rt-td > a {
      color: #4a4a4a;
      font-size: 13px; }

.form-container {
  display: flex;
  flex-direction: column; }
  .form-container__title {
    font-size: 25px; }
  .form-container__cart {
    flex-direction: column;
    align-self: center;
    text-align: center;
    width: 70%;
    margin-bottom: 3rem; }
    .form-container__cart > h2 {
      font-size: 20px;
      font-weight: 800; }
    .form-container__cart > h3 {
      font-size: 20px;
      font-weight: 400;
      margin: 0; }
    .form-container__cart > span {
      font-size: 20px;
      font-weight: 800;
      color: #9b9b9b; }
    .form-container__cart > p {
      margin-top: 1rem;
      font-size: 20px;
      font-weight: 800; }
  .form-container > h2 {
    font-size: 20px;
    font-weight: 800; }
  .form-container__statusText {
    margin: 20px 0;
    font-size: 18px;
    font-weight: 400;
    align-self: center; }
    .form-container__statusText > span {
      font-weight: 800; }
  .form-container > p {
    font-size: 15px;
    font-weight: 800; }
  .form-container .react-select {
    width: 100%; }
    .form-container .react-select__placeholder {
      color: #c8c7bd !important; }
  .form-container .custom-textarea {
    width: 100%; }
  .form-container .custom-button {
    margin-top: 30px;
    align-self: center;
    width: 15%; }

.payment-container {
  display: flex;
  flex-direction: column; }
  .payment-container__title {
    font-size: 25px; }
  .payment-container .balanceTimeline {
    display: flex;
    flex-direction: row; }
    .payment-container .balanceTimeline .timeline {
      margin: auto auto auto 0;
      padding: 5px;
      transform: translateY(20px); }
      .payment-container .balanceTimeline .timeline .custom-button {
        text-transform: uppercase;
        padding: 10px 30px 10px 30px; }
    .payment-container .balanceTimeline .balance {
      width: 40%; }
  .payment-container .custom-banner {
    width: 100%;
    margin-bottom: 6vh; }
  .payment-container > h2 {
    font-size: 20px;
    font-weight: 800;
    margin-top: 60px; }
  .payment-container .levy-refused-container {
    width: 100%;
    margin: 30px 0; }
  .payment-container .custom-table {
    margin-bottom: 1.875rem; }
    .payment-container .custom-table .proof-header {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .payment-container .custom-table .proof-header-loader {
        width: 20px;
        height: 20px;
        margin-left: 10px; }
    .payment-container .custom-table .rt-td {
      color: #4a4a4a;
      font-size: 13px;
      align-self: center; }
      .payment-container .custom-table .rt-td > a {
        color: #4a4a4a;
        font-size: 13px; }
      .payment-container .custom-table .rt-td .custom-button {
        margin: 0;
        min-height: 25px; }

.profile-container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .profile-container__line {
    margin: 20px 0;
    align-self: center;
    background-color: #d8d8d8;
    width: 100%;
    height: 2px;
    cursor: none; }
  .profile-container__subtitle {
    font-size: 20px; }
  .profile-container__inputs {
    width: 100%;
    display: flex;
    flex-direction: row; }
    .profile-container__inputs__content {
      flex: 1 1;
      display: flex;
      flex-direction: column; }
      .profile-container__inputs__content > h3 {
        font-size: 18px; }
      .profile-container__inputs__content .custom-input__input {
        width: 70%; }
    .profile-container__inputs__right {
      flex: 1 1; }
  .profile-container__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2.5rem; }
    .profile-container__buttons .custom-button {
      width: 15%;
      margin: 0 0.9375rem; }
  .profile-container__information {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%; }
    .profile-container__information__consumption {
      width: 50%; }
      .profile-container__information__consumption > h3 {
        font-size: 18px; }
      .profile-container__information__consumption > p {
        font-size: 13px; }
      .profile-container__information__consumption .margin {
        margin-top: 0.9375rem; }
    .profile-container__information__address {
      width: 50%; }
      .profile-container__information__address > h3 {
        font-size: 18px; }
      .profile-container__information__address > p {
        font-size: 13px; }
      .profile-container__information__address .street {
        margin-top: 0.9375rem; }
  .profile-container .custom-toggle {
    width: 100%;
    justify-content: flex-start;
    margin: 2.5rem 0; }
  .profile-container > .custom-button {
    margin-top: 20px;
    width: 15%;
    align-self: center; }
  .profile-container__coholder {
    width: 100%;
    display: flex;
    flex-direction: column; }
    .profile-container__coholder__content {
      flex: 1 1;
      display: flex;
      flex-direction: row; }
      .profile-container__coholder__content__inputs {
        flex: 1 1; }
        .profile-container__coholder__content__inputs .custom-select {
          width: 20%;
          margin-bottom: 20px; }
          .profile-container__coholder__content__inputs .custom-select label {
            display: none; }
          .profile-container__coholder__content__inputs .custom-select__select {
            width: 20%; }
          .profile-container__coholder__content__inputs .custom-select > label {
            width: 8%;
            font-size: 15px;
            line-height: 1.2;
            letter-spacing: 1px;
            font-weight: bold;
            text-align: right;
            white-space: nowrap;
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;
            margin-right: 30px; }
        .profile-container__coholder__content__inputs .custom-input__input {
          width: 70%; }
      .profile-container__coholder__content__right {
        flex: 1 1; }
  .profile-container__payment-method {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .profile-container__payment-method__title {
      font-size: 18px; }
    .profile-container__payment-method__texts {
      width: 50%; }
      .profile-container__payment-method__texts > p {
        margin: 0.625rem 0; }
    .profile-container__payment-method__data {
      width: 50%; }
      .profile-container__payment-method__data > p {
        margin: 0.625rem 0; }
  .profile-container__update-billing {
    width: 100%;
    display: flex;
    flex-direction: row; }
    .profile-container__update-billing__contact {
      flex: 1 1;
      display: flex;
      flex-direction: column; }
      .profile-container__update-billing__contact > h3 {
        font-size: 18px; }
      .profile-container__update-billing__contact .custom-select {
        width: 20%;
        margin-bottom: 20px; }
        .profile-container__update-billing__contact .custom-select label {
          display: none; }
        .profile-container__update-billing__contact .custom-select__select {
          width: 20%; }
        .profile-container__update-billing__contact .custom-select > label {
          width: 8%;
          font-size: 15px;
          line-height: 1.2;
          letter-spacing: 1px;
          font-weight: bold;
          text-align: right;
          white-space: nowrap;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none;
          margin-right: 30px; }
      .profile-container__update-billing__contact .custom-input__input {
        width: 70%; }
    .profile-container__update-billing__address {
      flex: 1 1;
      display: flex;
      flex-direction: column; }
      .profile-container__update-billing__address > h3 {
        font-size: 18px; }
      .profile-container__update-billing__address .custom-input {
        width: 100%; }
        .profile-container__update-billing__address .custom-input__input {
          width: 70%; }
        .profile-container__update-billing__address .custom-input:last-child {
          margin-top: 20px; }
      .profile-container__update-billing__address .select {
        width: 100%; }
        .profile-container__update-billing__address .select .react-select__control {
          width: 70%;
          height: 30px !important;
          min-height: 30px !important;
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          border-radius: 15px;
          border-color: #000000; }
          .profile-container__update-billing__address .select .react-select__control--is-focused {
            border-color: currentColor;
            border-color: initial;
            box-shadow: none; }
          .profile-container__update-billing__address .select .react-select__control:hover {
            border-color: currentColor;
            border-color: initial; }
        .profile-container__update-billing__address .select .react-select__value-container {
          height: 30px;
          padding: 0 8px; }
        .profile-container__update-billing__address .select .react-select__indicators {
          height: 30px; }
        .profile-container__update-billing__address .select .react-select__placeholder {
          font-size: 15px;
          margin: 0 2px;
          position: absolute; }
        .profile-container__update-billing__address .select .react-select__menu {
          width: 70%; }
          .profile-container__update-billing__address .select .react-select__menu .react-select__option--is-focused {
            background-color: #d8d8d8; }
        .profile-container__update-billing__address .select .react-select__menu-list {
          padding-top: 0; }
          .profile-container__update-billing__address .select .react-select__menu-list .react-select__option {
            font-size: 15px;
            height: 30px;
            min-height: 30px !important; }
            .profile-container__update-billing__address .select .react-select__menu-list .react-select__option--is-focused {
              background-color: #d8d8d8;
              border-radius: 20px; }
      .profile-container__update-billing__address__errorPostalCode {
        font-size: 12px;
        color: #d0021b; }
  .profile-container__update-payment-method {
    width: 100%;
    display: flex;
    flex-direction: row; }
    .profile-container__update-payment-method .tg-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start; }
      .profile-container__update-payment-method .tg-block .custom-toggle {
        margin: 0;
        margin-bottom: 15px; }
    .profile-container__update-payment-method .\--off {
      background-color: #ececec;
      border-radius: 12px;
      transform: scale(0.9);
      padding: 10px;
      transition: all 0.5s; }
    .profile-container__update-payment-method__title {
      font-size: 18px;
      cursor: pointer; }
    .profile-container__update-payment-method__iban {
      width: 49%;
      display: flex;
      flex-direction: column; }
      .profile-container__update-payment-method__iban .custom-input__input {
        width: 70%; }
      .profile-container__update-payment-method__iban .custom-button {
        width: 30%;
        margin: 0;
        align-self: center; }
    .profile-container__update-payment-method__debit-day {
      width: 49%;
      display: flex;
      flex-direction: column; }
      .profile-container__update-payment-method__debit-day__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        margin-bottom: 20px; }
        .profile-container__update-payment-method__debit-day__container .custom-radio {
          margin: 0;
          margin-right: 10px;
          font-size: 14px;
          font-weight: 600;
          padding-top: 2px; }
      .profile-container__update-payment-method__debit-day .custom-button {
        width: 30%;
        margin: 0;
        align-self: center; }

.sponsor-container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .sponsor-container__title {
    font-size: 25px; }
  .sponsor-container .custom-banner {
    width: 100%;
    margin-bottom: 1.875rem; }
  .sponsor-container__subtitle {
    font-size: 20px; }
  .sponsor-container__subtitle2 {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 1.5625rem; }
  .sponsor-container__inputTitle {
    font-size: 15px;
    font-weight: 800;
    margin-bottom: 0.625rem; }
  .sponsor-container__rule {
    width: 50%;
    font-size: 14px;
    font-weight: 900;
    text-decoration: underline;
    color: #f5a623;
    margin: 10px 0 30px;
    cursor: pointer; }
  .sponsor-container .custom-input__input {
    width: 40%; }
    .sponsor-container .custom-input__input::-webkit-input-placeholder {
      color: #b1b1b1; }
    .sponsor-container .custom-input__input::-ms-input-placeholder {
      color: #b1b1b1; }
    .sponsor-container .custom-input__input::placeholder {
      color: #b1b1b1; }
  .sponsor-container .custom-button {
    width: 15%; }
  .sponsor-container .custom-modal__content {
    overflow: auto; }
  .sponsor-container .custom-modal__inside-content {
    display: flex;
    flex-direction: column;
    padding: 10px; }
    .sponsor-container .custom-modal__inside-content > h3 {
      margin-bottom: 10px; }
    .sponsor-container .custom-modal__inside-content > p {
      margin-bottom: 20px; }

.request-container {
  display: flex;
  flex-direction: column; }
  .request-container__title {
    font-size: 25px; }
  .request-container__sub-title {
    font-size: 20px;
    font-weight: 800;
    color: #000000;
    margin-bottom: 1.875rem;
    margin-top: 3.125rem; }
  .request-container .custom-table .rt-td {
    color: #4a4a4a;
    font-size: 13px;
    align-self: center; }
  .request-container .custom-table .rt-resizable-header-content {
    padding-left: 0 !important; }
  .request-container .custom-table .rt-tr-group {
    margin-left: 0 !important; }
  .request-container .custom-table .rt-expandable {
    margin-right: 0 !important; }
  .request-container .custom-table .sub-component {
    padding: 1.25rem 2.5rem; }

.package-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start; }
  .package-container:nth-child(1) hr {
    height: 1px;
    width: 70%;
    color: #d8d8d8; }
  .package-container__title {
    font-size: 25px; }
  .package-container__subtitle {
    font-size: 20px; }
  .package-container__contractStatus {
    width: 100%;
    height: 100px;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    background-color: #efefef;
    border-radius: 15px; }
    .package-container__contractStatus > p {
      margin: 20px;
      font-size: 16px;
      font-weight: 500; }
  .package-container__body {
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .package-container__body .plan-element {
      flex: 1 1;
      margin-right: 5%;
      min-height: 600px; }
      .package-container__body .plan-element__price {
        flex: 3 1; }
      .package-container__body .plan-element__description {
        width: 90%;
        flex: 8 1; }
      .package-container__body .plan-element__toggle {
        flex: 3 1; }
        .package-container__body .plan-element__toggle .custom-toggle > p {
          font-weight: 800; }
    .package-container__body__right {
      flex: 3 1;
      display: flex;
      flex-direction: column; }
      .package-container__body__right .energy-detail {
        width: 100%;
        height: 200px; }
      .package-container__body__right .option-element {
        width: 100%;
        flex: 1 1;
        margin-top: 10%; }
      .package-container__body__right .custom-button--orange {
        width: 30%;
        font-size: 11px;
        font-weight: bold; }
      .package-container__body__right__button {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        margin-top: 30px; }
        .package-container__body__right__button .custom-button {
          flex: 1 1; }
          .package-container__body__right__button .custom-button:last-child {
            margin-left: 10%; }
      .package-container__body__right__setting {
        display: flex;
        flex-direction: column; }
        .package-container__body__right__setting__button {
          width: 100%;
          max-height: 40px;
          display: flex;
          flex-direction: row;
          justify-content: center; }
          .package-container__body__right__setting__button .custom-button {
            width: 25%;
            height: 40px; }
            .package-container__body__right__setting__button .custom-button:nth-child(1) {
              margin-right: 5%; }

.setting-container {
  display: flex;
  flex-direction: column; }
  .setting-container__subTitle {
    font-size: 20px; }
  .setting-container__toggle-title {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 1.875rem; }
  .setting-container .custom-checkbox__label__content__text {
    font-size: 15px;
    margin-left: 1.75rem; }
  .setting-container__password-title {
    font-size: 20px;
    margin-top: 3.125rem; }
  .setting-container__password-subTitle {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 1.875rem; }
  .setting-container__password-inputs {
    display: flex;
    flex-direction: column;
    width: 50%; }
    .setting-container__password-inputs__title {
      font-weight: 800;
      font-size: 15px;
      margin: 0.3125rem 0; }
    .setting-container__password-inputs__error {
      color: #d0021b; }
    .setting-container__password-inputs .custom-input__input {
      width: 100%; }
  .setting-container .custom-button {
    margin-top: 3.125rem;
    width: 30%;
    align-self: center; }

.consumption-container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .consumption-container .custom-table__table {
    margin: 3.125rem 0; }
    .consumption-container .custom-table__table .rt-table .rt-tbody .rt-tr-group .rt-expandable {
      margin-right: 0; }
  .consumption-container .custom-transfer__error {
    color: #c8c7bd;
    font-weight: 400;
    font-size: 18px; }
  .consumption-container > h3 {
    font-size: 20px;
    font-weight: bold; }
  .consumption-container__subtable {
    display: flex;
    flex-direction: column; }
    .consumption-container__subtable__row {
      margin-left: 2.5rem; }
      .consumption-container__subtable__row > span {
        display: inline-block; }
      .consumption-container__subtable__row__date {
        width: 100px; }
      .consumption-container__subtable__row__context {
        width: 230px; }
      .consumption-container__subtable__row__startDate {
        width: 160px; }
      .consumption-container__subtable__row__endDate {
        width: 160px; }
      .consumption-container__subtable__row__consumption {
        width: 180px; }
      .consumption-container__subtable__row__timeframe {
        width: 170px; }
  .consumption-container .self-withdrawal-modal {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .consumption-container .self-withdrawal-modal .first-span {
      margin-right: 12px; }
    .consumption-container .self-withdrawal-modal__title {
      font-size: 25px;
      margin-top: 15px;
      font-weight: 800; }
    .consumption-container .self-withdrawal-modal__counters > p {
      font-size: 18px; }
    .consumption-container .self-withdrawal-modal__counters > ul {
      padding: 0; }
      .consumption-container .self-withdrawal-modal__counters > ul > li {
        list-style: none;
        font-size: 18px; }
        .consumption-container .self-withdrawal-modal__counters > ul > li > span {
          margin-right: 10px; }
    .consumption-container .self-withdrawal-modal__counters > img {
      display: flex;
      width: 85%;
      margin: auto; }
    .consumption-container .self-withdrawal-modal__data > ul {
      padding: 0; }
      .consumption-container .self-withdrawal-modal__data > ul > li {
        list-style: none;
        padding-bottom: 10px; }
        .consumption-container .self-withdrawal-modal__data > ul > li > p {
          font-size: 16px;
          font-weight: 800;
          padding: 5px; }
          .consumption-container .self-withdrawal-modal__data > ul > li > p > span {
            margin-right: 10px; }
        .consumption-container .self-withdrawal-modal__data > ul > li > ul {
          padding-left: 53px; }
          .consumption-container .self-withdrawal-modal__data > ul > li > ul > li {
            list-style: disc; }
    .consumption-container .self-withdrawal-modal__bottom {
      margin-top: 20px; }
      .consumption-container .self-withdrawal-modal__bottom > p {
        font-size: 18px; }
        .consumption-container .self-withdrawal-modal__bottom > p > a {
          text-decoration: none;
          font-weight: 800;
          color: #ffb40a;
          cursor: pointer; }

.gauge-container {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 30px; }
  .gauge-container__instruction {
    font-size: 11px;
    text-decoration: underline;
    color: #000000;
    margin: 0 0 20% 10px;
    cursor: pointer; }
    .gauge-container__instruction:focus {
      outline: none; }
  .gauge-container__title {
    margin: 0;
    font-size: 20px;
    font-weight: bold; }
  .gauge-container__gauge {
    margin-top: 2%;
    width: 100%; }
    .gauge-container__gauge > input,
    .gauge-container__gauge > section > div {
      display: none; }
    .gauge-container__gauge #tab1:checked ~ section .tab1,
    .gauge-container__gauge #tab2:checked ~ section .tab2 {
      display: block; }
    .gauge-container__gauge #tab1:checked ~ ul .tab1,
    .gauge-container__gauge #tab2:checked ~ ul .tab2 {
      color: #d0021b; }
    .gauge-container__gauge > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-around; }
      .gauge-container__gauge > ul > li > label {
        float: left;
        background: #ffffff;
        padding: 5px 30px;
        color: #000000;
        font-weight: bold; }
        .gauge-container__gauge > ul > li > label:hover {
          cursor: pointer; }
    .gauge-container__gauge > section {
      clear: both;
      background: #ffffff;
      border-radius: 20px;
      border: solid 2px #000000;
      display: flex;
      align-items: center;
      padding: 10px 0; }
      .gauge-container__gauge > section > div {
        width: 100%;
        height: 100%;
        line-height: 1.5em;
        letter-spacing: 0.3px;
        color: #444; }
      .gauge-container__gauge > section .modal {
        width: 168px;
        height: 14px;
        font-size: 11px;
        position: absolute;
        top: 93%;
        left: -35%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        text-decoration: underline;
        cursor: pointer; }
    .gauge-container__gauge #tab1:checked ~ ul .tab1 > label,
    .gauge-container__gauge #tab2:checked ~ ul .tab2 > label {
      position: relative;
      border: 2px solid #000000;
      border-bottom: 0;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px; }
      .gauge-container__gauge #tab1:checked ~ ul .tab1 > label:after,
      .gauge-container__gauge #tab2:checked ~ ul .tab2 > label:after {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #ffffff;
        left: 0;
        bottom: -2px; }
    .gauge-container__gauge #tab2:checked ~ section .tab2 {
      height: 120px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
      padding: 20px 0; }
      .gauge-container__gauge #tab2:checked ~ section .tab2 .custom-select {
        width: 35%; }
      .gauge-container__gauge #tab2:checked ~ section .tab2 .energy-gauge {
        width: 40%; }
        .gauge-container__gauge #tab2:checked ~ section .tab2 .energy-gauge .progress,
        .gauge-container__gauge #tab2:checked ~ section .tab2 .energy-gauge .progress__bar {
          width: 100px; }
    .gauge-container__gauge #tab1:checked ~ section .tab1 {
      position: relative;
      width: 100%;
      height: auto; }
      .gauge-container__gauge #tab1:checked ~ section .tab1 .package-slider-carousel {
        padding: 0 30px;
        width: 830px;
        margin: auto; }
        .gauge-container__gauge #tab1:checked ~ section .tab1 .package-slider-carousel .slick-slider .slick-arrow {
          top: 36%; }
        .gauge-container__gauge #tab1:checked ~ section .tab1 .package-slider-carousel .slick-slider .slick-list .slick-slide {
          padding-bottom: 30px;
          padding-top: 30px; }
        .gauge-container__gauge #tab1:checked ~ section .tab1 .package-slider-carousel .package-element {
          height: 100px;
          width: 165px;
          margin: auto; }
          .gauge-container__gauge #tab1:checked ~ section .tab1 .package-slider-carousel .package-element__title {
            font-size: 12px;
            text-align: center;
            margin: 0; }
          .gauge-container__gauge #tab1:checked ~ section .tab1 .package-slider-carousel .package-element__iconizing {
            font-size: 9px;
            margin: 2px 0;
            transform: scale(0.76); }
          .gauge-container__gauge #tab1:checked ~ section .tab1 .package-slider-carousel .package-element__price {
            font-size: 38px;
            align-items: flex-start; }
          .gauge-container__gauge #tab1:checked ~ section .tab1 .package-slider-carousel .package-element > span {
            width: 80%;
            text-align: center; }
          .gauge-container__gauge #tab1:checked ~ section .tab1 .package-slider-carousel .package-element--recommended {
            transform-origin: center;
            transform: scale(1.2); }
            .gauge-container__gauge #tab1:checked ~ section .tab1 .package-slider-carousel .package-element--recommended:not(:last-child) {
              margin: 0 3%; }
            .gauge-container__gauge #tab1:checked ~ section .tab1 .package-slider-carousel .package-element--recommended > span {
              transform: scale(0.76); }
          .gauge-container__gauge #tab1:checked ~ section .tab1 .package-slider-carousel .package-element .checkmark {
            left: 40%;
            transform: scale(0.76); }
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        .gauge-container__gauge #tab1:checked ~ section .tab1 {
          height: 160px; }
          .gauge-container__gauge #tab1:checked ~ section .tab1 .package-element {
            height: 100px; }
        .gauge-container__gauge #tab2:checked ~ section .tab2 {
          height: 160px; } } }

@-moz-document url-prefix() {
  .gauge-container__gauge > ul > li > label {
    padding: 4px 30px; } }

.frequency-container {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 30px; }
  .frequency-container__title {
    margin: 0;
    font-size: 20px;
    font-weight: bold; }
  .frequency-container__frequency {
    margin-top: 2%;
    width: 100%;
    height: 70%;
    border-radius: 20px;
    border: solid 2px #000000;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .frequency-container__frequency__title {
      font-size: 15px;
      font-weight: bold;
      line-height: 1.2;
      letter-spacing: 1px;
      color: #222325;
      margin-top: 2%;
      margin-left: 2%; }
    .frequency-container__frequency__radios {
      flex: 1 1;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center; }
      .frequency-container__frequency__radios .custom-radio {
        margin-left: 30px;
        max-width: 200px;
        height: 40px;
        font-weight: 500; }

.payment-cb-modal {
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important; }
  .payment-cb-modal__error {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1 1; }
    .payment-cb-modal__error > img {
      width: 25%;
      margin-bottom: 20px; }
    .payment-cb-modal__error > p {
      font-weight: 800;
      font-size: 20px; }
    .payment-cb-modal__error .errorContainer {
      margin-top: 2%; }
    .payment-cb-modal__error .custom-button {
      width: 150px;
      margin-top: 20px; }
  .payment-cb-modal__success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1; }
    .payment-cb-modal__success > img {
      width: 25%;
      margin-bottom: 20px; }
    .payment-cb-modal__success > p {
      font-weight: 800;
      font-size: 20px; }
    .payment-cb-modal__success > button {
      margin: 20px 0 20px 0;
      padding: 10px 50px 10px 50px; }
  .payment-cb-modal__loading {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center; }

/**
ADMIN
**/
#content-admin {
  background-color: #f4f4f4;
  width: 130%; }

.admin-subscription-container {
  align-items: stretch;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  max-width: 70vw; }
  .admin-subscription-container h1 {
    color: #000000;
    font-family: Montserrat;
    font-size: 21px;
    font-stretch: normal;
    font-weight: 900;
    font-style: normal;
    margin: 0;
    min-height: 25px;
    letter-spacing: normal;
    line-height: 1.19;
    text-transform: uppercase; }
  .admin-subscription-container h2 {
    color: #000000;
    font-family: Montserrat;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 900;
    letter-spacing: normal;
    line-height: 1.2;
    margin: 0 0 16px 0;
    min-height: 24px; }
  .admin-subscription-container h3 {
    color: #000000;
    font-family: Montserrat;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 900;
    letter-spacing: normal;
    line-height: 1.19;
    margin: 0 0 12px 0;
    min-height: 19px; }
  .admin-subscription-container h4 {
    color: #000000;
    font-family: Montserrat;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.2;
    margin: 13px;
    min-height: 24px; }
  .admin-subscription-container .client-data-section__radio-buttons {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    width: 25vw; }
    .admin-subscription-container .client-data-section__radio-buttons > .custom-radio {
      margin-right: 1.2vw; }
  .admin-subscription-container .client-data-section__select-group {
    align-items: flex-start;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-content: flex-start; }
    .admin-subscription-container .client-data-section__select-group .custom-select {
      height: 100% !important;
      width: 18rem; }
      .admin-subscription-container .client-data-section__select-group .custom-select__content {
        align-items: flex-start;
        display: flex;
        flex-direction: column; }
  .admin-subscription-container .client-data-section__main__input-grid-ind, .admin-subscription-container .client-data-section__main__input-grid-pro {
    align-items: flex-start;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height: 49px; }
    .admin-subscription-container .client-data-section__main__input-grid-ind > *:first-child, .admin-subscription-container .client-data-section__main__input-grid-pro > *:first-child {
      margin-left: 0 !important;
      margin-right: 1rem; }
    .admin-subscription-container .client-data-section__main__input-grid-ind > *, .admin-subscription-container .client-data-section__main__input-grid-pro > * {
      margin-right: 1rem; }
    .admin-subscription-container .client-data-section__main__input-grid-ind > *:last-child, .admin-subscription-container .client-data-section__main__input-grid-pro > *:last-child {
      margin-right: 0 !important; }
    .admin-subscription-container .client-data-section__main__input-grid-ind > .custom-select,
    .admin-subscription-container .client-data-section__main__input-grid-ind > .custom-input, .admin-subscription-container .client-data-section__main__input-grid-pro > .custom-select,
    .admin-subscription-container .client-data-section__main__input-grid-pro > .custom-input {
      min-width: 25vw; }
      .admin-subscription-container .client-data-section__main__input-grid-ind > .custom-select > label:first-child,
      .admin-subscription-container .client-data-section__main__input-grid-ind > .custom-input > label:first-child, .admin-subscription-container .client-data-section__main__input-grid-pro > .custom-select > label:first-child,
      .admin-subscription-container .client-data-section__main__input-grid-pro > .custom-input > label:first-child {
        display: block; }
    .admin-subscription-container .client-data-section__main__input-grid-ind > .custom-select .custom-select__content, .admin-subscription-container .client-data-section__main__input-grid-pro > .custom-select .custom-select__content {
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start; }
      .admin-subscription-container .client-data-section__main__input-grid-ind > .custom-select .custom-select__content > label, .admin-subscription-container .client-data-section__main__input-grid-pro > .custom-select .custom-select__content > label {
        color: #000000;
        display: block;
        font-family: Montserrat, Helvetica, Arial, sans-serif;
        font-size: 15px;
        font-weight: 700;
        height: 19px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 0px;
        text-align: left; }
    .admin-subscription-container .client-data-section__main__input-grid-ind > .custom-select .custom-select__content__select, .admin-subscription-container .client-data-section__main__input-grid-pro > .custom-select .custom-select__content__select {
      flex-grow: 1;
      min-width: 5vw; }
    .admin-subscription-container .client-data-section__main__input-grid-ind > .custom-input > input:first-child, .admin-subscription-container .client-data-section__main__input-grid-pro > .custom-input > input:first-child {
      min-width: 5vw; }
    .admin-subscription-container .client-data-section__main__input-grid-ind > .custom-input .custom-input__input, .admin-subscription-container .client-data-section__main__input-grid-pro > .custom-input .custom-input__input {
      flex-grow: 1;
      justify-content: flex-start;
      width: 100%; }
    .admin-subscription-container .client-data-section__main__input-grid-ind__date-picker, .admin-subscription-container .client-data-section__main__input-grid-pro__date-picker {
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .admin-subscription-container .client-data-section__main__input-grid-ind__date-picker > label, .admin-subscription-container .client-data-section__main__input-grid-pro__date-picker > label {
        color: #000000;
        display: block;
        font-family: Montserrat, Helvetica, Arial, sans-serif;
        font-size: 15px;
        font-weight: 700;
        height: 19px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 0px;
        text-align: left; }
      .admin-subscription-container .client-data-section__main__input-grid-ind__date-picker > .DayPickerInput, .admin-subscription-container .client-data-section__main__input-grid-pro__date-picker > .DayPickerInput {
        flex-grow: 1;
        min-width: 5vw; }
    .admin-subscription-container .client-data-section__main__input-grid-ind__postal-code .custom-select__content, .admin-subscription-container .client-data-section__main__input-grid-pro__postal-code .custom-select__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-width: 230px; }
    .admin-subscription-container .client-data-section__main__input-grid-ind__address-foreign, .admin-subscription-container .client-data-section__main__input-grid-pro__address-foreign {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px; }
      .admin-subscription-container .client-data-section__main__input-grid-ind__address-foreign .custom-select, .admin-subscription-container .client-data-section__main__input-grid-pro__address-foreign .custom-select {
        min-width: 230px;
        margin: 0 !important; }
        .admin-subscription-container .client-data-section__main__input-grid-ind__address-foreign .custom-select__content, .admin-subscription-container .client-data-section__main__input-grid-pro__address-foreign .custom-select__content {
          display: flex;
          flex-direction: column;
          align-items: flex-start; }
      .admin-subscription-container .client-data-section__main__input-grid-ind__address-foreign .custom-checkbox, .admin-subscription-container .client-data-section__main__input-grid-pro__address-foreign .custom-checkbox {
        margin: 0; }
        .admin-subscription-container .client-data-section__main__input-grid-ind__address-foreign .custom-checkbox__label__content__text, .admin-subscription-container .client-data-section__main__input-grid-pro__address-foreign .custom-checkbox__label__content__text {
          color: #000000;
          display: block;
          font-family: Montserrat, Helvetica, Arial, sans-serif;
          font-size: 15px;
          font-weight: 700;
          height: 19px;
          margin-bottom: 0px;
          margin-left: 0px;
          margin-right: 30px;
          margin-top: 0px;
          text-align: left; }
      .admin-subscription-container .client-data-section__main__input-grid-ind__address-foreign .custom-input, .admin-subscription-container .client-data-section__main__input-grid-pro__address-foreign .custom-input {
        margin: 0;
        margin-left: 20px; }
        .admin-subscription-container .client-data-section__main__input-grid-ind__address-foreign .custom-input__input, .admin-subscription-container .client-data-section__main__input-grid-pro__address-foreign .custom-input__input {
          width: 100%; }
  .admin-subscription-container .site-section {
    display: flex;
    flex-direction: column; }
    .admin-subscription-container .site-section .custom-input {
      align-items: center;
      display: flex;
      flex-direction: row; }
      .admin-subscription-container .site-section .custom-input label {
        display: block;
        text-align: right;
        width: 21vw; }
      .admin-subscription-container .site-section .custom-input__input {
        flex-grow: 1; }
    .admin-subscription-container .site-section__main {
      display: flex;
      flex-direction: column; }
      .admin-subscription-container .site-section__main > .custom-button {
        align-self: flex-end; }
      .admin-subscription-container .site-section__main .custom-select {
        max-width: 25vw; }
        .admin-subscription-container .site-section__main .custom-select__content {
          align-items: flex-start;
          flex-direction: column; }
      .admin-subscription-container .site-section__main__site {
        margin-top: 2vh; }
        .admin-subscription-container .site-section__main__site > .custom-input {
          max-width: 90%; }
      .admin-subscription-container .site-section__main__missing {
        display: flex;
        flex-direction: column;
        grid-gap: 2vh;
        gap: 2vh;
        margin-top: 2vh; }
        .admin-subscription-container .site-section__main__missing > span {
          align-self: center;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none; }
          .admin-subscription-container .site-section__main__missing > span:active {
            opacity: 0.6; }
          .admin-subscription-container .site-section__main__missing > span:hover {
            text-decoration: underline; }
        .admin-subscription-container .site-section__main__missing > p {
          text-align: justify; }
        .admin-subscription-container .site-section__main__missing--highlighted {
          display: flex;
          flex-direction: column;
          grid-gap: 2vh;
          gap: 2vh;
          margin-top: 2vh; }
          .admin-subscription-container .site-section__main__missing--highlighted > span {
            align-self: center;
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none; }
            .admin-subscription-container .site-section__main__missing--highlighted > span:active {
              opacity: 0.6; }
            .admin-subscription-container .site-section__main__missing--highlighted > span:hover {
              text-decoration: underline; }
          .admin-subscription-container .site-section__main__missing--highlighted > p {
            text-align: justify; }
          .admin-subscription-container .site-section__main__missing--highlighted > span {
            color: #ffb40a !important; }
  .admin-subscription-container .offer-section {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .admin-subscription-container .offer-section__package-manage {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
      .admin-subscription-container .offer-section__package-manage .custom-button {
        margin: 0 15px; }
    .admin-subscription-container .offer-section .error-message {
      color: #d0021b;
      font-size: 15px;
      font-weight: 800; }
    .admin-subscription-container .offer-section .custom-select__content {
      align-items: flex-start;
      display: flex;
      flex-direction: column; }
    .admin-subscription-container .offer-section > p:first-child {
      align-self: flex-start; }
    .admin-subscription-container .offer-section > .custom-button {
      align-self: center; }
    .admin-subscription-container .offer-section > .custom-button:last-child {
      align-self: flex-end !important;
      margin-top: 1.2vw; }
    .admin-subscription-container .offer-section__gauge-buttons {
      grid-column-gap: 1.2vw;
      -webkit-column-gap: 1.2vw;
              column-gap: 1.2vw;
      display: flex;
      margin-bottom: 1.2vw;
      width: 100%; }
      .admin-subscription-container .offer-section__gauge-buttons > .el {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        width: 50%; }
      .admin-subscription-container .offer-section__gauge-buttons > .ng {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        width: 50%; }
    .admin-subscription-container .offer-section__gauge {
      align-items: center;
      border: 2px solid #000;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2vw; }
    .admin-subscription-container .offer-section__select-grid {
      display: grid;
      grid-column-gap: 1.2vw;
      -webkit-column-gap: 1.2vw;
              column-gap: 1.2vw;
      grid-template-columns: 50% 50%;
      margin: 1.2vw 0;
      width: 100%; }
      .admin-subscription-container .offer-section__select-grid__half {
        display: flex;
        flex-direction: column;
        width: 97%; }
        .admin-subscription-container .offer-section__select-grid__half > *:first-child {
          margin-bottom: 20px;
          margin-top: 0 !important; }
        .admin-subscription-container .offer-section__select-grid__half > *:nth-child() {
          margin-bottom: 20px !important; }
        .admin-subscription-container .offer-section__select-grid__half > *:last-child {
          margin-bottom: 0 !important; }
    .admin-subscription-container .offer-section .additional {
      display: flex;
      flex-direction: column;
      margin: 1rem 0; }
      .admin-subscription-container .offer-section .additional__options {
        display: flex;
        flex-direction: column; }
        .admin-subscription-container .offer-section .additional__options > .option-group {
          display: grid;
          grid-gap: 1em;
          grid-template-columns: repeat(auto-fill, 240px);
          justify-content: space-evenly; }
          .admin-subscription-container .offer-section .additional__options > .option-group .checkbox-tile {
            width: 240px; }
  .admin-subscription-container .client-consumption-content {
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 50%; }
    .admin-subscription-container .client-consumption-content > h3 {
      margin: 20px 0;
      text-align: center;
      margin-bottom: 35px; }
    .admin-subscription-container .client-consumption-content .custom-select {
      width: 60%; }
    .admin-subscription-container .client-consumption-content .custom-input {
      margin-bottom: 10px; }
    .admin-subscription-container .client-consumption-content__estimates-error {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: auto; }
      .admin-subscription-container .client-consumption-content__estimates-error > p {
        color: #d0021b; }
    .admin-subscription-container .client-consumption-content__high-low > h4 {
      font-size: 15px;
      margin-left: 0;
      margin-bottom: 0; }
    .admin-subscription-container .client-consumption-content__high-low > div {
      display: flex;
      flex-direction: row; }
      .admin-subscription-container .client-consumption-content__high-low > div .custom-input {
        width: 35%; }
        .admin-subscription-container .client-consumption-content__high-low > div .custom-input > label {
          font-weight: 400; }
        .admin-subscription-container .client-consumption-content__high-low > div .custom-input__input {
          width: 80%; }
    .admin-subscription-container .client-consumption-content__high-low__inputs {
      display: flex;
      flex-direction: column;
      margin-right: 15px; }
      .admin-subscription-container .client-consumption-content__high-low__inputs .custom-input {
        width: 100% !important; }
    .admin-subscription-container .client-consumption-content__buttons {
      grid-column-gap: 1.2vw;
      -webkit-column-gap: 1.2vw;
              column-gap: 1.2vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: auto; }
      .admin-subscription-container .client-consumption-content__buttons > .custom-button {
        margin-top: 10%;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 900;
        line-height: 1.2; }
      .admin-subscription-container .client-consumption-content__buttons > .custom-button:last-child {
        background-color: #ffb40a; }
  .admin-subscription-container .situation-section {
    display: flex;
    flex-direction: column; }
    .admin-subscription-container .situation-section > h2 {
      align-self: flex-start; }
    .admin-subscription-container .situation-section__radio-buttons {
      grid-column-gap: 1.2vw;
      -webkit-column-gap: 1.2vw;
              column-gap: 1.2vw;
      display: flex;
      flex-direction: row; }
      .admin-subscription-container .situation-section__radio-buttons .custom-radio:nth-child(2) {
        margin-left: 20px; }
    .admin-subscription-container .situation-section__details {
      grid-column-gap: 1.2vw;
      -webkit-column-gap: 1.2vw;
              column-gap: 1.2vw;
      display: flex;
      flex-direction: row; }
      .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar p {
        height: 2em; }
      .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar p {
        height: 2em; }
      .admin-subscription-container .situation-section__details .date-container .DayPickerInput .DayPickerInput-Overlay {
        overflow: hidden; }
      .admin-subscription-container .situation-section__details .date-container .DayPickerInput .DayPicker-Month {
        margin: 1em; }
      .admin-subscription-container .situation-section__details .date-container__date__calendars {
        grid-gap: 1.2vw;
        gap: 1.2vw; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar > *:first-child {
          margin-bottom: 1rem;
          margin-top: 0 !important; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar > *:nth-child() {
          margin-bottom: 1rem !important; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar > *:last-child {
          margin-bottom: 0 !important; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar .custom-select__content label {
          margin-right: 0; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar p {
          margin-bottom: 0; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__title {
          margin-bottom: 0 !important;
          height: 35px; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__title > *:first-child {
            margin-left: 0 !important;
            margin-right: 1rem; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__title > * {
            margin-right: 1rem; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__title > *:last-child {
            margin-right: 0 !important; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__title > span:nth-of-type(1) {
            color: #000000;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 900;
            line-height: 1.25;
            min-height: 15px;
            text-align: left; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__title > span:nth-of-type(2) {
            color: #707070;
            font-family: Montserrat;
            font-size: 12px;
            line-height: 1.25;
            min-height: 15px;
            text-align: left;
            white-space: nowrap; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__infos > *:first-child {
          margin-left: 0 !important;
          margin-right: 1rem; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__infos > * {
          margin-right: 1rem; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__infos > *:last-child {
          margin-right: 0 !important; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__infos__options {
          margin-bottom: 1rem; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__infos__options p {
            height: 100%;
            margin-bottom: 0 !important; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__paragraph {
          display: flex;
          flex-direction: column;
          margin-top: 0; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__paragraph > div {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap; }
            .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__paragraph > div > *:first-child {
              margin-left: 0 !important;
              margin-right: 0.5rem; }
            .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__paragraph > div > * {
              margin-right: 0.5rem; }
            .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__paragraph > div > *:last-child {
              margin-right: 0 !important; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__paragraph > div span:nth-child(1) {
            color: #000000;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 900;
            line-height: 1.25;
            min-height: 15px;
            text-align: left; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__paragraph > span,
          .admin-subscription-container .situation-section__details .date-container__date__calendars__EL-calendar__paragraph > div span:nth-child(2) {
            color: #707070;
            display: flex;
            flex-direction: row;
            font-family: Montserrat;
            font-size: 12px;
            line-height: 1.25;
            min-height: 15px;
            text-align: left; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar > *:first-child {
          margin-bottom: 1rem;
          margin-top: 0 !important; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar > *:nth-child() {
          margin-bottom: 1rem !important; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar > *:last-child {
          margin-bottom: 0 !important; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar .custom-select__content label {
          margin-right: 0; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar p {
          margin-bottom: 0; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__title {
          margin-bottom: 0 !important;
          height: 35px; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__title > *:first-child {
            margin-left: 0 !important;
            margin-right: 1rem; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__title > * {
            margin-right: 1rem; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__title > *:last-child {
            margin-right: 0 !important; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__title > span:nth-of-type(1) {
            color: #000000;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 900;
            line-height: 1.25;
            min-height: 15px;
            text-align: left; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__title > span:nth-of-type(2) {
            color: #707070;
            font-family: Montserrat;
            font-size: 12px;
            line-height: 1.25;
            min-height: 15px;
            text-align: left;
            white-space: nowrap; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__infos > *:first-child {
          margin-left: 0 !important;
          margin-right: 1rem; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__infos > * {
          margin-right: 1rem; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__infos > *:last-child {
          margin-right: 0 !important; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__infos__options {
          margin-bottom: 1rem; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__infos__options p {
            height: 100%;
            margin-bottom: 0 !important; }
        .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__paragraph {
          display: flex;
          flex-direction: column;
          margin-top: 0; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__paragraph > div {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap; }
            .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__paragraph > div > *:first-child {
              margin-left: 0 !important;
              margin-right: 0.5rem; }
            .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__paragraph > div > * {
              margin-right: 0.5rem; }
            .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__paragraph > div > *:last-child {
              margin-right: 0 !important; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__paragraph > div span:nth-child(1) {
            color: #000000;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 900;
            line-height: 1.25;
            min-height: 15px;
            text-align: left; }
          .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__paragraph > span,
          .admin-subscription-container .situation-section__details .date-container__date__calendars__NG-calendar__paragraph > div span:nth-child(2) {
            color: #707070;
            display: flex;
            flex-direction: row;
            font-family: Montserrat;
            font-size: 12px;
            line-height: 1.25;
            min-height: 15px;
            text-align: left; }
      .admin-subscription-container .situation-section__details .DayPickerInput input {
        margin-bottom: 0; }
      .admin-subscription-container .situation-section__details > h4 {
        max-width: 25vw; }
      .admin-subscription-container .situation-section__details__moving-in {
        display: grid;
        grid-column-gap: 5vw;
        -webkit-column-gap: 5vw;
                column-gap: 5vw;
        flex-grow: 1;
        grid-template-areas: 'a c' 'b d'; }
        .admin-subscription-container .situation-section__details__moving-in > label {
          margin-bottom: 1.2vw; }
        .admin-subscription-container .situation-section__details__moving-in > label:nth-of-type(1) {
          grid-area: a; }
        .admin-subscription-container .situation-section__details__moving-in > .DayPickerInput:nth-of-type(1) {
          grid-area: b; }
        .admin-subscription-container .situation-section__details__moving-in > label:nth-of-type(2) {
          grid-area: c; }
        .admin-subscription-container .situation-section__details__moving-in > .DayPickerInput:nth-of-type(2) {
          grid-area: d; }
      .admin-subscription-container .situation-section__details > .custom-select {
        flex-grow: 1; }
    .admin-subscription-container .situation-section__billing {
      margin-top: 2vh; }
      .admin-subscription-container .situation-section__billing__disclaimer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; }
        .admin-subscription-container .situation-section__billing__disclaimer > p span:first-of-type {
          color: #d0021b; }
      .admin-subscription-container .situation-section__billing__inputs > div {
        margin-top: 1rem; }
      .admin-subscription-container .situation-section__billing__inputs__payment-mode {
        align-items: flex-start;
        grid-column-gap: 1.2vw;
        -webkit-column-gap: 1.2vw;
                column-gap: 1.2vw;
        display: flex;
        flex-direction: row; }
        .admin-subscription-container .situation-section__billing__inputs__payment-mode .custom-select {
          width: 30vw; }
          .admin-subscription-container .situation-section__billing__inputs__payment-mode .custom-select__content {
            margin-bottom: 20px; }
        .admin-subscription-container .situation-section__billing__inputs__payment-mode .custom-input input {
          width: 100%; }
        .admin-subscription-container .situation-section__billing__inputs__payment-mode__iban {
          flex-grow: 1; }
          .admin-subscription-container .situation-section__billing__inputs__payment-mode__iban .custom-input {
            align-items: center;
            display: flex;
            flex-direction: row;
            margin-bottom: 2px;
            width: 90%; }
          .admin-subscription-container .situation-section__billing__inputs__payment-mode__iban span {
            color: #71aff4;
            height: 20px;
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none; }
            .admin-subscription-container .situation-section__billing__inputs__payment-mode__iban span:active {
              opacity: 0.8; }
      .admin-subscription-container .situation-section__billing__inputs__custom-radio-bundle {
        grid-column-gap: 1.2vw;
        -webkit-column-gap: 1.2vw;
                column-gap: 1.2vw;
        display: flex;
        flex-direction: row; }
        .admin-subscription-container .situation-section__billing__inputs__custom-radio-bundle .custom-radio {
          margin-left: 20px; }
  .admin-subscription-container .recap-section {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .admin-subscription-container .recap-section__groups-mono {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 2rem;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start; }
      .admin-subscription-container .recap-section__groups-mono .recap-group {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: space-between;
        margin-top: 1rem;
        width: 49%; }
        .admin-subscription-container .recap-section__groups-mono .recap-group .chosen-package-mono {
          background-color: #ffb40a;
          width: 125px;
          height: 75px;
          border-radius: 15px;
          text-align: center;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center; }
          .admin-subscription-container .recap-section__groups-mono .recap-group .chosen-package-mono > p {
            margin: auto;
            color: #ffffff;
            font-weight: 800;
            font-size: 20px; }
          .admin-subscription-container .recap-section__groups-mono .recap-group .chosen-package-mono-bi {
            width: 135px; }
        .admin-subscription-container .recap-section__groups-mono .recap-group .chosen-package-bi .input-range {
          display: none !important; }
        .admin-subscription-container .recap-section__groups-mono .recap-group > *:first-child {
          margin-bottom: 2rem;
          margin-top: 0 !important; }
        .admin-subscription-container .recap-section__groups-mono .recap-group > *:nth-child() {
          margin-bottom: 2rem !important; }
        .admin-subscription-container .recap-section__groups-mono .recap-group > *:last-child {
          margin-bottom: 0 !important; }
        .admin-subscription-container .recap-section__groups-mono .recap-group > h3 {
          color: #ffb40a;
          width: 100%;
          text-align: left; }
        .admin-subscription-container .recap-section__groups-mono .recap-group .fields {
          width: 100%; }
          .admin-subscription-container .recap-section__groups-mono .recap-group .fields > h4 {
            color: #b1b1b1;
            text-align: center;
            text-decoration: underline;
            width: 100%;
            margin-top: 30px; }
          .admin-subscription-container .recap-section__groups-mono .recap-group .fields .without-margin {
            margin-top: 0;
            margin-left: 0; }
          .admin-subscription-container .recap-section__groups-mono .recap-group .fields .recap-field {
            align-items: flex-end;
            display: flex;
            width: 100%;
            margin-bottom: 10px; }
            .admin-subscription-container .recap-section__groups-mono .recap-group .fields .recap-field span {
              margin-left: 0;
              margin-right: 0;
              white-space: break-spaces;
              width: 50%; }
            .admin-subscription-container .recap-section__groups-mono .recap-group .fields .recap-field span:first-child {
              font-weight: 600;
              text-align: right;
              width: 50%; }
            .admin-subscription-container .recap-section__groups-mono .recap-group .fields .recap-field span:last-child {
              font-weight: 900;
              padding-left: 1rem;
              text-align: left; }
    .admin-subscription-container .recap-section__groups-bi {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 2rem;
      width: 100%;
      align-items: flex-start; }
      .admin-subscription-container .recap-section__groups-bi .recap-group {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: space-between;
        margin-top: 1rem;
        width: 49%; }
        .admin-subscription-container .recap-section__groups-bi .recap-group .chosen-package-mono {
          background-color: #ffb40a;
          width: 125px;
          height: 75px;
          border-radius: 15px;
          text-align: center;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center; }
          .admin-subscription-container .recap-section__groups-bi .recap-group .chosen-package-mono > p {
            margin: auto;
            color: #ffffff;
            font-weight: 800;
            font-size: 20px; }
          .admin-subscription-container .recap-section__groups-bi .recap-group .chosen-package-mono-bi {
            width: 135px; }
        .admin-subscription-container .recap-section__groups-bi .recap-group .chosen-package-bi .input-range {
          display: none !important; }
        .admin-subscription-container .recap-section__groups-bi .recap-group > *:first-child {
          margin-bottom: 2rem;
          margin-top: 0 !important; }
        .admin-subscription-container .recap-section__groups-bi .recap-group > *:nth-child() {
          margin-bottom: 2rem !important; }
        .admin-subscription-container .recap-section__groups-bi .recap-group > *:last-child {
          margin-bottom: 0 !important; }
        .admin-subscription-container .recap-section__groups-bi .recap-group > h3 {
          color: #ffb40a;
          width: 100%;
          text-align: left; }
        .admin-subscription-container .recap-section__groups-bi .recap-group .fields {
          width: 100%; }
          .admin-subscription-container .recap-section__groups-bi .recap-group .fields > h4 {
            color: #b1b1b1;
            text-align: center;
            text-decoration: underline;
            width: 100%;
            margin-top: 30px; }
          .admin-subscription-container .recap-section__groups-bi .recap-group .fields .without-margin {
            margin-top: 0;
            margin-left: 0; }
          .admin-subscription-container .recap-section__groups-bi .recap-group .fields .recap-field {
            align-items: flex-end;
            display: flex;
            width: 100%;
            margin-bottom: 10px; }
            .admin-subscription-container .recap-section__groups-bi .recap-group .fields .recap-field span {
              margin-left: 0;
              margin-right: 0;
              white-space: break-spaces;
              width: 50%; }
            .admin-subscription-container .recap-section__groups-bi .recap-group .fields .recap-field span:first-child {
              font-weight: 600;
              text-align: right;
              width: 50%; }
            .admin-subscription-container .recap-section__groups-bi .recap-group .fields .recap-field span:last-child {
              font-weight: 900;
              padding-left: 1rem;
              text-align: left; }
    .admin-subscription-container .recap-section .code {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .admin-subscription-container .recap-section .code p {
        text-align: left; }
      .admin-subscription-container .recap-section .code__inputs {
        align-items: center;
        grid-column-gap: 1rem;
        -webkit-column-gap: 1rem;
                column-gap: 1rem;
        display: grid;
        justify-content: flex-start;
        grid-template-columns: 40% 35% auto;
        grid-template-rows: auto;
        width: 100%; }
        .admin-subscription-container .recap-section .code__inputs > * {
          margin-bottom: 0 !important; }
        .admin-subscription-container .recap-section .code__inputs .custom-select {
          min-height: 0 !important;
          width: 100%; }
          .admin-subscription-container .recap-section .code__inputs .custom-select__content {
            width: 100%; }
            .admin-subscription-container .recap-section .code__inputs .custom-select__content label {
              margin: 0; }
        .admin-subscription-container .recap-section .code__inputs .custom-input {
          width: 100%; }
          .admin-subscription-container .recap-section .code__inputs .custom-input input {
            width: 100%; }
      .admin-subscription-container .recap-section .code__radios {
        align-items: center;
        grid-column-gap: 1.2vw;
        -webkit-column-gap: 1.2vw;
                column-gap: 1.2vw;
        display: flex;
        flex-direction: row;
        justify-content: flex-start; }
        .admin-subscription-container .recap-section .code__radios .custom-toggle {
          width: unset; }
  .admin-subscription-container .missing-informations-section {
    align-items: stretch;
    display: flex;
    flex-direction: column; }
    .admin-subscription-container .missing-informations-section > *:first-child {
      margin-bottom: 1rem;
      margin-top: 0 !important; }
    .admin-subscription-container .missing-informations-section > *:nth-child() {
      margin-bottom: 1rem !important; }
    .admin-subscription-container .missing-informations-section > *:last-child {
      margin-bottom: 0 !important; }
    .admin-subscription-container .missing-informations-section > span {
      color: #707070;
      min-height: 15px;
      font-family: Montserrat;
      font-size: 12px;
      margin-bottom: 0; }
    .admin-subscription-container .missing-informations-section .custom-button {
      align-self: flex-end;
      color: #000000;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 900;
      line-height: 1.2; }
    .admin-subscription-container .missing-informations-section .custom-input {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      max-width: 90%; }
      .admin-subscription-container .missing-informations-section .custom-input > label {
        display: block;
        text-align: right;
        width: 21vw; }
      .admin-subscription-container .missing-informations-section .custom-input__input {
        flex-grow: 1; }
  .admin-subscription-container .orderNumberDisplay {
    margin-top: 3%; }
  .admin-subscription-container .subscribe-section {
    display: flex;
    flex-direction: column; }
    .admin-subscription-container .subscribe-section > *:first-child {
      margin-bottom: 1.2vw;
      margin-top: 0 !important; }
    .admin-subscription-container .subscribe-section > *:nth-child() {
      margin-bottom: 1.2vw !important; }
    .admin-subscription-container .subscribe-section > *:last-child {
      margin-bottom: 0 !important; }
    .admin-subscription-container .subscribe-section__buttons {
      align-items: flex-start;
      grid-column-gap: 1.2vw;
      -webkit-column-gap: 1.2vw;
              column-gap: 1.2vw;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly; }
      .admin-subscription-container .subscribe-section__buttons__save > .save-confirm {
        color: #000000;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 900;
        margin-bottom: 0.5rem;
        line-height: 1.25;
        text-align: center; }
      .admin-subscription-container .subscribe-section__buttons__save > .save-warning {
        color: #6F49CE;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 900;
        margin-bottom: 0.5rem;
        line-height: 1.25;
        text-align: center; }
      .admin-subscription-container .subscribe-section__buttons__save > button {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 900; }
      .admin-subscription-container .subscribe-section__buttons__save {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 50%; }
        .admin-subscription-container .subscribe-section__buttons__save > *:first-child {
          margin-bottom: 1vh;
          margin-top: 0 !important; }
        .admin-subscription-container .subscribe-section__buttons__save > *:nth-child() {
          margin-bottom: 1vh !important; }
        .admin-subscription-container .subscribe-section__buttons__save > *:last-child {
          margin-bottom: 0 !important; }
        .admin-subscription-container .subscribe-section__buttons__save span {
          color: #707070;
          font-family: Montserrat;
          font-size: 12px;
          text-align: justify;
          width: 100%; }
      .admin-subscription-container .subscribe-section__buttons__sign {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 50%;
        z-index: 1; }
        .admin-subscription-container .subscribe-section__buttons__sign > *:first-child {
          margin-bottom: 1vh;
          margin-top: 0 !important; }
        .admin-subscription-container .subscribe-section__buttons__sign > *:nth-child() {
          margin-bottom: 1vh !important; }
        .admin-subscription-container .subscribe-section__buttons__sign > *:last-child {
          margin-bottom: 0 !important; }
        .admin-subscription-container .subscribe-section__buttons__sign span {
          color: #707070;
          font-family: Montserrat;
          font-size: 12px;
          text-align: justify;
          width: 100%; }
      .admin-subscription-container .subscribe-section__buttons__sign--highlighted {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 50%;
        z-index: 1001; }
        .admin-subscription-container .subscribe-section__buttons__sign--highlighted > *:first-child {
          margin-bottom: 1vh;
          margin-top: 0 !important; }
        .admin-subscription-container .subscribe-section__buttons__sign--highlighted > *:nth-child() {
          margin-bottom: 1vh !important; }
        .admin-subscription-container .subscribe-section__buttons__sign--highlighted > *:last-child {
          margin-bottom: 0 !important; }
        .admin-subscription-container .subscribe-section__buttons__sign--highlighted span {
          color: #707070;
          font-family: Montserrat;
          font-size: 12px;
          text-align: justify;
          width: 100%; }
  .admin-subscription-container .customer-survey-modal {
    align-items: stretch;
    background-color: white;
    border-radius: 0.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 5vw;
    width: 65vw;
    border: solid 4px #000000; }
    .admin-subscription-container .customer-survey-modal > h3 {
      color: #000000;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 800;
      height: 19px;
      line-height: 1.27;
      margin-bottom: 1rem;
      text-align: center; }
    .admin-subscription-container .customer-survey-modal__buttons {
      grid-column-gap: 1.2vw;
      -webkit-column-gap: 1.2vw;
              column-gap: 1.2vw;
      display: flex;
      flex-direction: row;
      justify-content: center; }
      .admin-subscription-container .customer-survey-modal__buttons .custom-button {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 900;
        line-height: 1.2; }
    .admin-subscription-container .customer-survey-modal__mode-toggle {
      grid-column-gap: 1.2vw;
      -webkit-column-gap: 1.2vw;
              column-gap: 1.2vw;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 1.2vw 0;
      width: 100%; }
      .admin-subscription-container .customer-survey-modal__mode-toggle > label {
        margin-right: 10px; }
      .admin-subscription-container .customer-survey-modal__mode-toggle > span {
        margin-right: 20px !important;
        font-size: 15px;
        font-weight: bold;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none; }
    .admin-subscription-container .customer-survey-modal__rate-radios {
      grid-column-gap: 1.2vw;
      -webkit-column-gap: 1.2vw;
              column-gap: 1.2vw;
      display: grid;
      grid-template-columns: 30% 20% 20% 20%;
      margin-bottom: 1.2vw;
      width: 100%; }
      .admin-subscription-container .customer-survey-modal__rate-radios > label:first-child {
        font-size: 15px;
        font-weight: bold;
        text-align: left;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        white-space: nowrap;
        text-align: right; }
      .admin-subscription-container .customer-survey-modal__rate-radios > .custom-radio {
        color: #000000;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: normal; }
    .admin-subscription-container .customer-survey-modal > .manual-input > .custom-input {
      display: flex;
      flex-direction: row; }
      .admin-subscription-container .customer-survey-modal > .manual-input > .custom-input > input {
        background-color: #efefef;
        flex-grow: 1; }
      .admin-subscription-container .customer-survey-modal > .manual-input > .custom-input > label {
        flex-shrink: 0;
        margin-right: 1.2vw;
        width: 30%; }
    .admin-subscription-container .customer-survey-modal > .custom-input {
      display: flex;
      flex-direction: row; }
      .admin-subscription-container .customer-survey-modal > .custom-input > input {
        background-color: #efefef;
        flex-grow: 1; }
      .admin-subscription-container .customer-survey-modal > .custom-input > label {
        flex-shrink: 0;
        margin-right: 1.2vw;
        width: 30%;
        text-align: right; }
    .admin-subscription-container .customer-survey-modal .custom-select__content {
      display: flex;
      flex-direction: row; }
      .admin-subscription-container .customer-survey-modal .custom-select__content > label {
        flex-shrink: 0;
        width: 30%;
        text-align: right; }
  .admin-subscription-container .seasonal-offers {
    align-items: center;
    border: 4px solid black;
    border-radius: 0.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    padding: 5vw; }
    .admin-subscription-container .seasonal-offers__gauge {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      width: 100%; }
      .admin-subscription-container .seasonal-offers__gauge__header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start; }
        .admin-subscription-container .seasonal-offers__gauge__header .custom-checkbox {
          z-index: 1003; }
      .admin-subscription-container .seasonal-offers__gauge__body {
        display: flex;
        height: 120px;
        justify-content: center; }
  .admin-subscription-container .reset-modal {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2em; }
    .admin-subscription-container .reset-modal > *:first-child {
      margin-bottom: 1em;
      margin-top: 0 !important; }
    .admin-subscription-container .reset-modal > *:nth-child() {
      margin-bottom: 1em !important; }
    .admin-subscription-container .reset-modal > *:last-child {
      margin-bottom: 0 !important; }
    .admin-subscription-container .reset-modal__buttons {
      align-items: center;
      grid-column-gap: 1em;
      -webkit-column-gap: 1em;
              column-gap: 1em;
      display: flex;
      flex-direction: row;
      justify-content: center; }
  .admin-subscription-container .orderNumberDisplay {
    margin-top: 3%; }
  .admin-subscription-container .payment-modal {
    align-items: center;
    border: 4px solid #ffb40a;
    border-radius: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 545px; }
    .admin-subscription-container .payment-modal .loader {
      align-items: center;
      background-color: white;
      border: 4px solid #ffb40a;
      border-radius: 0.5em;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1000000; }
  .admin-subscription-container .payment-iframe {
    background-color: #efefef;
    border-radius: 0.5em;
    height: 80vh !important;
    margin: 5vh, 5vw;
    overflow: scroll;
    min-width: 40vw; }
    .admin-subscription-container .payment-iframe .payment-loader {
      align-items: center;
      background-color: #efefef;
      border-radius: 0.5em;
      display: flex;
      justify-content: center;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1005; }
    .admin-subscription-container .payment-iframe .payment-iframe-modal {
      background-color: #efefef;
      box-sizing: border-box;
      height: 120%;
      width: 100%; }
      .admin-subscription-container .payment-iframe .payment-iframe-modal__iframe {
        border: none;
        height: 100%;
        overflow: hidden;
        width: 100%; }
      .admin-subscription-container .payment-iframe .payment-iframe-modal .retry {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        text-align: center; }
        .admin-subscription-container .payment-iframe .payment-iframe-modal .retry > *:first-child {
          margin-bottom: 1rem;
          margin-top: 0 !important; }
        .admin-subscription-container .payment-iframe .payment-iframe-modal .retry > *:nth-child() {
          margin-bottom: 1rem !important; }
        .admin-subscription-container .payment-iframe .payment-iframe-modal .retry > *:last-child {
          margin-bottom: 0 !important; }
        .admin-subscription-container .payment-iframe .payment-iframe-modal .retry__logo {
          height: 12vw;
          width: 10vw;
          background-color: #ffb40a;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center; }
        .admin-subscription-container .payment-iframe .payment-iframe-modal .retry span {
          color: #000000;
          font-size: 15px;
          font-weight: bold;
          width: 500px;
          margin: 0px 0px 10px 0px; }
        .admin-subscription-container .payment-iframe .payment-iframe-modal .retry__buttons {
          align-content: flex-start;
          display: flex;
          flex-direction: row;
          justify-content: space-around; }
          .admin-subscription-container .payment-iframe .payment-iframe-modal .retry__buttons__finalize {
            align-items: center;
            display: flex;
            flex-direction: column;
            min-width: 14rem; }
            .admin-subscription-container .payment-iframe .payment-iframe-modal .retry__buttons__finalize > p {
              font-size: 10px;
              width: 14rem; }
          .admin-subscription-container .payment-iframe .payment-iframe-modal .retry__buttons .custom-button {
            height: 40px;
            margin: 0 2em;
            width: 14rem; }
      .admin-subscription-container .payment-iframe .payment-iframe-modal .success {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        text-align: center; }
        .admin-subscription-container .payment-iframe .payment-iframe-modal .success img {
          background-color: #ffb40a;
          border-radius: 50%;
          margin: 1em;
          height: 10em;
          width: 10em; }
        .admin-subscription-container .payment-iframe .payment-iframe-modal .success span {
          color: #000000;
          font-size: 15px;
          font-weight: bold; }
  .admin-subscription-container .custom-button {
    width: 180px !important; }
  .admin-subscription-container .custom-select {
    height: 30px;
    margin-bottom: 20px !important;
    min-height: 49px; }
    .admin-subscription-container .custom-select div {
      text-overflow: ellipsis !important;
      white-space: nowrap !important; }
    .admin-subscription-container .custom-select__content label {
      margin-right: 1rem; }
    .admin-subscription-container .custom-select__content react-select-control {
      height: 30px; }
  .admin-subscription-container .react-select__option {
    text-overflow: clip;
    white-space: nowrap; }
  .admin-subscription-container__footer {
    border-radius: 0 0 20px 20px;
    height: 40px; }
  .admin-subscription-container__header {
    align-items: center;
    border-radius: 20px 20px 0 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 65px;
    justify-content: space-between;
    padding: 0 2%; }
    .admin-subscription-container__header > h1 {
      flex-grow: 1;
      text-transform: uppercase; }
    .admin-subscription-container__header > img {
      max-height: 65px; }
  .admin-subscription-container__body {
    background-color: #ffffff; }
    .admin-subscription-container__body__top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 1.2vw; }
      .admin-subscription-container__body__top__display-order-number {
        width: 18rem; }
        .admin-subscription-container__body__top__display-order-number > p {
          border: solid 2px #ffb40a;
          padding: 10px;
          border-radius: 12px;
          font-weight: 800; }
      .admin-subscription-container__body__top__status {
        display: grid;
        font-family: Montserrat;
        font-size: 12px;
        grid-template-columns: auto auto;
        grid-template-rows: 15px 15px;
        justify-content: end;
        line-height: 1.25;
        margin: 1.2vw; }
        .admin-subscription-container__body__top__status > span:nth-child(1),
        .admin-subscription-container__body__top__status > span:nth-child(3) {
          color: #b1b1b1;
          font-weight: bold;
          margin-right: 1.2vw;
          text-align: right; }
        .admin-subscription-container__body__top__status > span:nth-child(2),
        .admin-subscription-container__body__top__status > span:nth-child(4) {
          color: #707070; }
  .admin-subscription-container__footer, .admin-subscription-container__header {
    background-color: #ffb40a;
    overflow: hidden; }
  .admin-subscription-container__mask {
    background-color: black;
    height: 100vh;
    left: 0;
    opacity: 0.3;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1000; }
  .admin-subscription-container > .custom-button {
    align-self: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 900;
    margin-top: 1em;
    z-index: 1000; }
  .admin-subscription-container section {
    box-sizing: border-box;
    margin-bottom: 10vh;
    padding: 0 1.2vw 0 1.2vw; }
  .admin-subscription-container__loader-background {
    align-items: center;
    background-color: #efefef;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    opacity: 0.8;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1000; }

.search-container .modal__content {
  height: 30%; }

.search-container .custom-select__content {
  z-index: 0 !important; }

.search-container .top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  .search-container .top-row .access-client {
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 1.25rem 1.25rem;
    border-radius: 15px;
    transition: ease all 0.5s;
    margin: 1.5625rem 0;
    width: 400px;
    align-items: center; }
    .search-container .top-row .access-client .custom-button {
      width: 140px;
      height: 10px; }
    .search-container .top-row .access-client .custom-input {
      height: 100%;
      margin: 0; }
      .search-container .top-row .access-client .custom-input__input {
        width: 80%; }
  .search-container .top-row .upload-endorsements {
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 1.25rem 1.25rem;
    border-radius: 15px;
    transition: ease all 0.5s;
    margin: 1.5625rem 0;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .search-container .top-row .upload-endorsements__title {
      font-weight: 800;
      font-size: 15px;
      margin-right: 15px; }

.search-container .title {
  font-size: 25px;
  color: #000000;
  font-weight: bold;
  display: inline-block;
  text-align: left;
  margin: 0;
  margin-right: 30px; }

.search-container__container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 1.25rem 1.25rem;
  border-radius: 15px;
  transition: ease all 0.5s;
  margin: 1.5625rem 0;
  justify-content: space-around;
  background-color: white;
  margin-top: 0;
  border-top: none;
  width: 100%; }

.search-container__block {
  width: calc(100% / 4); }
  .search-container__block > label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 15px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    margin: 0;
    margin-right: 30px; }
  .search-container__block .DayPickerInput {
    width: 90%; }
  .search-container__block .custom-button {
    width: 50%;
    margin-left: 2%; }
  .search-container__block .custom-input__input {
    height: 30px;
    width: 90%;
    display: block;
    border: 1px solid #131212;
    border-radius: 14px;
    background-color: #ffffff;
    padding: 0 10px;
    font-size: 15px;
    z-index: 0; }
  .search-container__block .custom-input__delete {
    position: absolute;
    height: 15px;
    width: 15px;
    top: 26.5px;
    right: 40px;
    outline: 0; }
    .search-container__block .custom-input__delete > span {
      font-size: 12px;
      color: #b1b1b1;
      font-weight: 600;
      text-align: right;
      cursor: pointer;
      position: absolute;
      top: 0px;
      left: 2.5px;
      z-index: 1;
      outline: 0; }
      .search-container__block .custom-input__delete > span:hover {
        color: #646464; }
  .search-container__block .custom-date__delete {
    position: absolute;
    margin-top: 9px;
    margin-left: 310px; }
    @media screen and (min-width: 1300px) {
      .search-container__block .custom-date__delete > span {
        font-size: 12px;
        color: #b1b1b1;
        font-weight: 600;
        text-align: right;
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 90.5px;
        z-index: 1;
        outline: 0; }
        .search-container__block .custom-date__delete > span:hover {
          color: #646464; } }
    @media screen and (min-width: 1920px) {
      .search-container__block .custom-date__delete > span {
        font-size: 12px;
        color: #b1b1b1;
        font-weight: 600;
        text-align: right;
        cursor: pointer;
        position: absolute;
        top: 0px;
        left: 2.5px;
        z-index: 1;
        outline: 0; }
        .search-container__block .custom-date__delete > span:hover {
          color: #646464; } }
  .search-container__block .search-rs-vl-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 12%; }

.background-element {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px 30px;
  border-radius: 15px;
  border: solid 2px #f5a623;
  width: 99%; }

.customer-contract-container {
  margin-left: -1%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative; }
  .customer-contract-container .background-element {
    border: none; }
  .customer-contract-container__div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 400px; }
    .customer-contract-container__div-top {
      position: absolute;
      left: 190px;
      top: 36px;
      z-index: 2; }
    .customer-contract-container__div-bottom {
      position: absolute;
      bottom: 36px;
      right: 0;
      z-index: 2; }
    .customer-contract-container__div .custom-select {
      width: 160px;
      cursor: pointer;
      margin: 0; }
      .customer-contract-container__div .custom-select__content {
        cursor: pointer; }
        .customer-contract-container__div .custom-select__content label {
          display: none; }
    .customer-contract-container__div .custom-button {
      width: 160px;
      margin-left: 20px;
      height: 31px;
      font-size: 13px;
      bottom: 5px;
      right: 90px; }
    .customer-contract-container__div .custom-input {
      width: 100%; }
  .customer-contract-container .custom-table {
    width: 100%; }
    .customer-contract-container .custom-table .rt-td {
      color: #2e2ba5;
      font-size: 13px;
      align-self: center;
      font-weight: 600; }
    .customer-contract-container .custom-table .rt-resizable-header-content {
      padding-left: 0 !important; }
    .customer-contract-container .custom-table .rt-tr-group {
      margin-left: 0 !important; }
    .customer-contract-container .custom-table .rt-expandable {
      margin-right: 0 !important; }
    .customer-contract-container .custom-table .sub-component {
      padding: 1.25rem 2.5rem; }
      .customer-contract-container .custom-table .sub-component__table {
        width: 100%;
        margin: 0;
        border-spacing: 0;
        border-collapse: separate;
        border-spacing: 0 5px; }
        .customer-contract-container .custom-table .sub-component__table thead tr th {
          font-weight: 400;
          text-align: left;
          padding-left: 10px;
          object-fit: contain;
          font-family: Montserrat;
          font-size: 12px;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #8d8d8d; }
        .customer-contract-container .custom-table .sub-component__table tbody {
          padding-top: 20px; }
          .customer-contract-container .custom-table .sub-component__table tbody tr {
            height: 35px; }
            .customer-contract-container .custom-table .sub-component__table tbody tr td {
              color: black;
              font-weight: 500;
              padding: 0;
              padding-left: 10px;
              font-size: 12px;
              line-height: 1.25;
              text-align: left; }
              .customer-contract-container .custom-table .sub-component__table tbody tr td .transaction-element__buttons {
                display: inline;
                padding: 0; }
              .customer-contract-container .custom-table .sub-component__table tbody tr td .custom-button {
                width: 100px;
                margin: 0; }
    .customer-contract-container .custom-table .rt-table {
      overflow: visible; }
    .customer-contract-container .custom-table .rt-resizer {
      width: 1px !important; }
    .customer-contract-container .custom-table .rt-tr {
      border-bottom: solid 1px #d8d8d8;
      padding: 7px; }
      .customer-contract-container .custom-table .rt-tr .rt-td:nth-child(2) {
        font-weight: 800; }
    .customer-contract-container .custom-table .custom-button {
      width: 90px;
      margin: 0 auto; }
    .customer-contract-container .custom-table .custom-checkbox {
      margin: 0; }
    .customer-contract-container .custom-table .sticky {
      background: #ffffff;
      z-index: 1000;
      position: -webkit-sticky;
      position: sticky;
      top: 0px; }
    .customer-contract-container .custom-table .menu-style {
      height: 37px;
      width: 9px;
      cursor: pointer;
      margin-left: 10px; }
    .customer-contract-container .custom-table .subcomponent-menu-style {
      height: 27px;
      width: 6px;
      cursor: pointer;
      margin-left: 1px; }
    .customer-contract-container .custom-table .sub-component__table {
      width: 70%; }
      .customer-contract-container .custom-table .sub-component__table .pop-up {
        right: 15%; }
      @media screen and (max-width: 1270px) {
        .customer-contract-container .custom-table .sub-component__table {
          width: 90%; }
          .customer-contract-container .custom-table .sub-component__table .pop-up {
            right: 5%; } }
  @media screen and (max-width: 950px) {
    .customer-contract-container {
      flex-wrap: wrap; } }
  .customer-contract-container__header {
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 0 25px;
    padding-left: 21px;
    border-left: solid 9px transparent;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 85px; }
    .customer-contract-container__header-active {
      border-left: solid 9px #75eab5; }
    .customer-contract-container__header__column {
      margin: 0 0.5rem; }
      .customer-contract-container__header__column:nth-child(1) {
        margin-left: 0; }
      .customer-contract-container__header__column:nth-child(5) {
        margin-right: 0; }
      .customer-contract-container__header__column > h1 {
        color: #2e2ba5;
        font-size: 14px;
        font-weight: 800; }
      .customer-contract-container__header__column > p {
        color: #747474;
        font-size: 11px;
        margin: 16px 0; }
      .customer-contract-container__header__column > h2 {
        color: #2e2ba5;
        font-size: 14px;
        font-weight: 500; }
      .customer-contract-container__header__column > h3 {
        color: #2e2ba5;
        font-size: 14px;
        font-weight: 500; }
  .customer-contract-container__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%; }
    .customer-contract-container__container .process-element {
      width: 47.5%;
      height: 100%; }
    .customer-contract-container__container .transaction-element {
      width: 47.5%;
      height: 100%; }
  .customer-contract-container .pop-up {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 200px;
    height: 62px;
    align-items: flex-end;
    right: 0;
    border-radius: 10px;
    margin-top: -15px;
    z-index: 2;
    border: solid 1px #f5a623;
    border-top-right-radius: 1;
    border-bottom-right-radius: 1;
    border-top-left-radius: 1;
    border-bottom-left-radius: 1;
    text-align: center;
    background-color: white; }
    .customer-contract-container .pop-up > p {
      font-size: 11px;
      color: #000000;
      font-weight: bold;
      display: inline-block;
      margin: 0;
      width: 200px;
      margin-top: 5px;
      cursor: pointer; }
      .customer-contract-container .pop-up > p:hover {
        color: #f5a623; }
  .customer-contract-container .reset-password > p {
    font-size: 11px;
    color: #000000;
    font-weight: bold;
    display: inline-block;
    margin: 0;
    width: 200px;
    margin-top: 5px;
    cursor: pointer; }
    .customer-contract-container .reset-password > p:hover {
      color: #f5a623; }
  .customer-contract-container .reinit-subscription > p {
    font-size: 11px;
    color: #000000;
    font-weight: bold;
    display: inline-block;
    margin: 0;
    width: 200px;
    margin-top: 5px;
    cursor: pointer; }
    .customer-contract-container .reinit-subscription > p:hover {
      color: #f5a623; }
  .customer-contract-container .remove-user > p {
    font-size: 11px;
    color: #000000;
    font-weight: bold;
    display: inline-block;
    margin: 0;
    width: 200px;
    margin-top: 5px;
    cursor: pointer; }
    .customer-contract-container .remove-user > p:hover {
      color: #f5a623; }
  .customer-contract-container .enable-or-desable > p {
    font-size: 11px;
    color: #000000;
    font-weight: bold;
    display: inline-block;
    margin: 0;
    width: 200px;
    margin-top: 5px;
    cursor: pointer; }
    .customer-contract-container .enable-or-desable > p:hover {
      color: #f5a623; }
  .customer-contract-container .enable-or-desable .custom-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; }
    .customer-contract-container .enable-or-desable .custom-modal__content .warning-title {
      font-weight: 600;
      text-align: center;
      color: #d0021b;
      font-size: 20px;
      margin-bottom: 5%; }
    .customer-contract-container .enable-or-desable .custom-modal__content .message {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 15px;
      font-weight: 100;
      height: 19px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 30px;
      margin-top: 0px; }
    .customer-contract-container .enable-or-desable .custom-modal__content .question {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 15px;
      font-weight: 100;
      height: 19px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 30px;
      margin-top: 50px; }
    .customer-contract-container .enable-or-desable .custom-modal .enable-user {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
      .customer-contract-container .enable-or-desable .custom-modal .enable-user .warning-title {
        font-weight: 600;
        text-align: center;
        color: #d0021b;
        font-size: 20px;
        margin-bottom: 5%; }
      .customer-contract-container .enable-or-desable .custom-modal .enable-user .reponse {
        margin-top: 5%;
        text-align: center; }
      .customer-contract-container .enable-or-desable .custom-modal .enable-user .message {
        margin-top: 5%;
        text-align: center; }
      .customer-contract-container .enable-or-desable .custom-modal .enable-user > p {
        font-weight: 600;
        text-align: center; }
        .customer-contract-container .enable-or-desable .custom-modal .enable-user > p > span {
          color: #d0021b;
          font-size: 15px; }
      .customer-contract-container .enable-or-desable .custom-modal .enable-user__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1.875rem; }
        @media screen and (max-width: 550px) {
          .customer-contract-container .enable-or-desable .custom-modal .enable-user__buttons {
            flex-direction: column; } }
        .customer-contract-container .enable-or-desable .custom-modal .enable-user__buttons .custom-button {
          width: 30%;
          margin: 1.25rem; }
          @media screen and (max-width: 550px) {
            .customer-contract-container .enable-or-desable .custom-modal .enable-user__buttons .custom-button {
              width: 60%;
              margin-bottom: 0; } }
    .customer-contract-container .enable-or-desable .custom-modal .desable-user {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
      .customer-contract-container .enable-or-desable .custom-modal .desable-user .warning-title {
        font-weight: 600;
        text-align: center;
        color: #d0021b;
        font-size: 20px;
        margin-bottom: 5%; }
      .customer-contract-container .enable-or-desable .custom-modal .desable-user .reponse {
        margin-top: 5%;
        text-align: center; }
      .customer-contract-container .enable-or-desable .custom-modal .desable-user .message {
        margin-top: 5%;
        text-align: center; }
      .customer-contract-container .enable-or-desable .custom-modal .desable-user > p {
        font-weight: 600;
        text-align: center; }
        .customer-contract-container .enable-or-desable .custom-modal .desable-user > p > span {
          color: #d0021b;
          font-size: 15px; }
      .customer-contract-container .enable-or-desable .custom-modal .desable-user__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1.875rem; }
        @media screen and (max-width: 550px) {
          .customer-contract-container .enable-or-desable .custom-modal .desable-user__buttons {
            flex-direction: column; } }
        .customer-contract-container .enable-or-desable .custom-modal .desable-user__buttons .custom-button {
          width: 30%;
          margin: 1.25rem; }
          @media screen and (max-width: 550px) {
            .customer-contract-container .enable-or-desable .custom-modal .desable-user__buttons .custom-button {
              width: 60%;
              margin-bottom: 0; } }
  .customer-contract-container .custom-modal__content {
    width: 45%;
    height: 50%; }
    @media screen and (min-width: 1300px) {
      .customer-contract-container .custom-modal__content {
        height: 450px; } }
    .customer-contract-container .custom-modal__content .reset-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
      .customer-contract-container .custom-modal__content .reset-modal .warning-title {
        font-weight: 600;
        text-align: center;
        color: #d0021b;
        font-size: 20px;
        margin-bottom: 5%; }
      .customer-contract-container .custom-modal__content .reset-modal .message {
        margin-top: 5%;
        text-align: center; }
      .customer-contract-container .custom-modal__content .reset-modal > p {
        font-weight: 600;
        text-align: center; }
        .customer-contract-container .custom-modal__content .reset-modal > p > span {
          color: #d0021b;
          font-size: 15px; }
      .customer-contract-container .custom-modal__content .reset-modal__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1.875rem; }
        @media screen and (max-width: 550px) {
          .customer-contract-container .custom-modal__content .reset-modal__buttons {
            flex-direction: column; } }
        .customer-contract-container .custom-modal__content .reset-modal__buttons .custom-button {
          width: 30%;
          margin: 1.25rem; }
          @media screen and (max-width: 550px) {
            .customer-contract-container .custom-modal__content .reset-modal__buttons .custom-button {
              width: 60%;
              margin-bottom: 0; } }
    .customer-contract-container .custom-modal__content .reinit-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
      .customer-contract-container .custom-modal__content .reinit-modal .warning-title {
        font-weight: 600;
        text-align: center;
        color: #d0021b;
        font-size: 20px;
        margin-bottom: 5%; }
      .customer-contract-container .custom-modal__content .reinit-modal .success_message {
        font-weight: 600;
        text-align: center;
        font-size: 15px; }
      .customer-contract-container .custom-modal__content .reinit-modal .email {
        font-weight: 600;
        text-align: center;
        margin-top: 2%; }
      .customer-contract-container .custom-modal__content .reinit-modal .advice {
        font-weight: 600;
        text-align: center;
        margin-top: 2%;
        color: #d0021b;
        font-size: 15px; }
      .customer-contract-container .custom-modal__content .reinit-modal__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1.875rem; }
        @media screen and (max-width: 550px) {
          .customer-contract-container .custom-modal__content .reinit-modal__buttons {
            flex-direction: column; } }
        .customer-contract-container .custom-modal__content .reinit-modal__buttons .custom-button {
          width: 30%;
          margin: 1.25rem; }
          @media screen and (max-width: 550px) {
            .customer-contract-container .custom-modal__content .reinit-modal__buttons .custom-button {
              width: 60%;
              margin-bottom: 0; } }
    .customer-contract-container .custom-modal__content .reinit-subscription-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
      .customer-contract-container .custom-modal__content .reinit-subscription-modal .warning-title {
        font-weight: 600;
        text-align: center;
        color: #d0021b;
        font-size: 20px;
        margin-bottom: 5%; }
      .customer-contract-container .custom-modal__content .reinit-subscription-modal .reponse {
        text-align: center; }
      .customer-contract-container .custom-modal__content .reinit-subscription-modal > p {
        font-weight: 600;
        text-align: center; }
        .customer-contract-container .custom-modal__content .reinit-subscription-modal > p > span {
          color: #d0021b;
          font-size: 15px; }
      .customer-contract-container .custom-modal__content .reinit-subscription-modal .custom-input {
        margin-top: 2%;
        width: 100%;
        align-items: center;
        justify-content: center; }
      .customer-contract-container .custom-modal__content .reinit-subscription-modal__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1.875rem; }
        @media screen and (max-width: 550px) {
          .customer-contract-container .custom-modal__content .reinit-subscription-modal__buttons {
            flex-direction: column; } }
        .customer-contract-container .custom-modal__content .reinit-subscription-modal__buttons .custom-button {
          width: 30%;
          margin: 1.25rem; }
          @media screen and (max-width: 550px) {
            .customer-contract-container .custom-modal__content .reinit-subscription-modal__buttons .custom-button {
              width: 60%;
              margin-bottom: 0; } }
    .customer-contract-container .custom-modal__content .administration-user-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
      .customer-contract-container .custom-modal__content .administration-user-modal .warning-title {
        font-weight: 600;
        text-align: center;
        color: #d0021b;
        font-size: 20px;
        margin-bottom: 5%; }
      .customer-contract-container .custom-modal__content .administration-user-modal .message {
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: #000000;
        font-family: Montserrat, Helvetica, Arial, sans-serif;
        font-size: 15px;
        font-weight: 700;
        height: 19px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 0px; }
      .customer-contract-container .custom-modal__content .administration-user-modal .question {
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: #000000;
        font-family: Montserrat, Helvetica, Arial, sans-serif;
        font-size: 15px;
        font-weight: 700;
        height: 19px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 50px; }
      .customer-contract-container .custom-modal__content .administration-user-modal__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1.875rem; }
        @media screen and (max-width: 550px) {
          .customer-contract-container .custom-modal__content .administration-user-modal__buttons {
            flex-direction: column; } }
        .customer-contract-container .custom-modal__content .administration-user-modal__buttons .custom-button {
          width: 30%;
          margin: 1.25rem; }
          @media screen and (max-width: 550px) {
            .customer-contract-container .custom-modal__content .administration-user-modal__buttons .custom-button {
              width: 60%;
              margin-bottom: 0; } }
    .customer-contract-container .custom-modal__content .administration-user-success-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 450px; }
      .customer-contract-container .custom-modal__content .administration-user-success-modal .enable-user-modal-response {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%; }
        .customer-contract-container .custom-modal__content .administration-user-success-modal .enable-user-modal-response .code {
          display: flex;
          flex-direction: row;
          justify-content: center;
          color: red;
          font-family: Montserrat, Helvetica, Arial, sans-serif;
          font-size: 25px;
          font-weight: 600;
          height: 19px;
          margin-bottom: 0px;
          margin-left: 0px;
          margin-right: 30px;
          margin-top: 0px; }
        .customer-contract-container .custom-modal__content .administration-user-success-modal .enable-user-modal-response .message {
          display: flex;
          flex-direction: row;
          justify-content: center;
          color: #000000;
          font-family: Montserrat, Helvetica, Arial, sans-serif;
          font-size: 25px;
          font-weight: 300;
          height: 19px;
          margin-bottom: 0px;
          margin-left: 0px;
          margin-right: 30px;
          margin-top: 60px; }
        .customer-contract-container .custom-modal__content .administration-user-success-modal .enable-user-modal-response .search-rs-vl-btn {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 12%; }
          .customer-contract-container .custom-modal__content .administration-user-success-modal .enable-user-modal-response .search-rs-vl-btn .custom-button {
            width: 30%;
            margin: 1%; }
      @media screen and (min-width: 1300px) {
        .customer-contract-container .custom-modal__content .administration-user-success-modal {
          height: 400px; } }
      .customer-contract-container .custom-modal__content .administration-user-success-modal .custom-modal__content {
        width: 100%;
        height: 550px; }
      .customer-contract-container .custom-modal__content .administration-user-success-modal .warning-title {
        font-weight: 600;
        text-align: center;
        color: #d0021b;
        font-size: 20px;
        margin-bottom: 4%; }
      .customer-contract-container .custom-modal__content .administration-user-success-modal__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1.875rem; }
        @media screen and (max-width: 550px) {
          .customer-contract-container .custom-modal__content .administration-user-success-modal__buttons {
            flex-direction: column; } }
        .customer-contract-container .custom-modal__content .administration-user-success-modal__buttons .custom-button {
          width: 30%;
          margin: 1.25rem; }
          @media screen and (max-width: 550px) {
            .customer-contract-container .custom-modal__content .administration-user-success-modal__buttons .custom-button {
              width: 60%;
              margin-bottom: 0; } }
  .customer-contract-container .custom-modal .btn-close {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    justify-content: flex-end;
    margin-bottom: 5px;
    cursor: pointer; }
  .customer-contract-container .sub-component .pop-up {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 200px;
    height: 45px;
    align-items: flex-end;
    right: 0;
    border-radius: 10px;
    margin-top: -15px;
    z-index: 2;
    border: solid 1px #f5a623;
    border-top-right-radius: 1;
    border-bottom-right-radius: 1;
    border-top-left-radius: 1;
    border-bottom-left-radius: 1;
    text-align: center;
    background-color: white; }
    .customer-contract-container .sub-component .pop-up > p {
      font-size: 11px;
      color: #000000;
      font-weight: bold;
      display: inline-block;
      margin: 0;
      width: 200px;
      margin-top: 5px;
      cursor: pointer; }
      .customer-contract-container .sub-component .pop-up > p:hover {
        color: #f5a623; }
  .customer-contract-container .sub-component .transfer-contract-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; }
    .customer-contract-container .sub-component .transfer-contract-modal .title {
      font-weight: 600;
      text-align: center;
      color: #d0021b;
      font-size: 20px;
      margin-bottom: 5%; }
    .customer-contract-container .sub-component .transfer-contract-modal .reponse {
      text-align: center; }
    .customer-contract-container .sub-component .transfer-contract-modal > p {
      font-weight: 600;
      text-align: center; }
      .customer-contract-container .sub-component .transfer-contract-modal > p > span {
        color: #d0021b;
        font-size: 15px; }
    .customer-contract-container .sub-component .transfer-contract-modal .custom-input {
      margin-top: 2%;
      width: 100%;
      align-items: center;
      justify-content: center; }
    .customer-contract-container .sub-component .transfer-contract-modal__buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 1.875rem; }
      @media screen and (max-width: 550px) {
        .customer-contract-container .sub-component .transfer-contract-modal__buttons {
          flex-direction: column; } }
      .customer-contract-container .sub-component .transfer-contract-modal__buttons .custom-button {
        width: 30%;
        margin: 1.25rem; }
        @media screen and (max-width: 550px) {
          .customer-contract-container .sub-component .transfer-contract-modal__buttons .custom-button {
            width: 60%;
            margin-bottom: 0; } }
  .customer-contract-container .sub-component .btn-close {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    justify-content: flex-end;
    margin-bottom: 5px;
    cursor: pointer; }
  .customer-contract-container .sub-component .success-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%; }
    .customer-contract-container .sub-component .success-component .custom-table {
      height: 50%;
      margin-bottom: 100px; }
    .customer-contract-container .sub-component .success-component .title {
      font-weight: 600;
      text-align: center;
      color: #d0021b;
      font-size: 20px;
      margin-bottom: 5%; }
  .customer-contract-container .sub-component .group-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: space-evenly;
    bottom: 10%;
    z-index: 1000; }
    .customer-contract-container .sub-component .group-btn .custom-button {
      width: 200px;
      top: 13px; }
  .customer-contract-container .sub-component .error-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
    margin-top: 55px; }
    .customer-contract-container .sub-component .error-component .title {
      font-weight: 600;
      text-align: center;
      color: #d0021b;
      font-size: 20px;
      margin-bottom: 9%; }
    .customer-contract-container .sub-component .error-component .btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 1.875rem; }
      @media screen and (max-width: 550px) {
        .customer-contract-container .sub-component .error-component .btn {
          flex-direction: column; } }
      .customer-contract-container .sub-component .error-component .btn .custom-button {
        width: 30%;
        margin: 1.25rem; }
        @media screen and (max-width: 550px) {
          .customer-contract-container .sub-component .error-component .btn .custom-button {
            width: 60%;
            margin-bottom: 0; } }
  .customer-contract-container .admin-user .create-user-modal .title {
    font-weight: 600;
    text-align: center;
    color: #d0021b;
    font-size: 20px; }
  .customer-contract-container .admin-user .create-user-modal .message {
    font-weight: 100; }
  .customer-contract-container .admin-user .create-user-modal > p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #000000;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    height: 19px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 30px;
    margin-top: 0px; }
  .customer-contract-container .admin-user .create-user-modal .search-rs-vl-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2%; }
    .customer-contract-container .admin-user .create-user-modal .search-rs-vl-btn .custom-button {
      width: 30%;
      margin: 1%; }
  .customer-contract-container .admin-user .create-user-modal__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 1.25rem 1.25rem;
    border-radius: 15px;
    transition: ease all 0.5s;
    margin: 1.5625rem 0;
    justify-content: space-around;
    margin-top: 0;
    border-top: none;
    width: 100%; }
  .customer-contract-container .admin-user .create-user-modal__block {
    width: 50%; }
    .customer-contract-container .admin-user .create-user-modal__block > .custom-select .custom-select__content {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      height: 30px;
      width: 90%;
      justify-content: space-between; }
      .customer-contract-container .admin-user .create-user-modal__block > .custom-select .custom-select__content > label {
        color: #000000;
        display: block;
        font-family: Montserrat, Helvetica, Arial, sans-serif;
        font-size: 15px;
        font-weight: 100;
        height: 19px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 0px;
        text-align: left; }
    .customer-contract-container .admin-user .create-user-modal__block > .custom-select .custom-select__content__select {
      flex-grow: 1; }
    .customer-contract-container .admin-user .create-user-modal__block > label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 15px;
      text-align: left;
      margin: 0;
      margin-right: 30px;
      font-weight: 100; }
    .customer-contract-container .admin-user .create-user-modal__block .custom-button {
      width: 50%;
      margin-left: 2%; }
    .customer-contract-container .admin-user .create-user-modal__block .custom-input > label {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: #000000;
      font-family: Montserrat, Helvetica, Arial, sans-serif;
      font-size: 15px;
      font-weight: 100;
      height: 19px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 30px;
      margin-top: 0px; }
    .customer-contract-container .admin-user .create-user-modal__block .custom-input__input {
      height: 30px;
      width: 90%;
      display: block;
      border: 1px solid #131212;
      border-radius: 14px;
      padding: 0 10px;
      font-size: 15px; }
  .customer-contract-container .admin-user .modal-response {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%; }
    .customer-contract-container .admin-user .modal-response .code {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: red;
      font-family: Montserrat, Helvetica, Arial, sans-serif;
      font-size: 25px;
      font-weight: 700;
      height: 19px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 30px;
      margin-top: 0px; }
    .customer-contract-container .admin-user .modal-response .message {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: #000000;
      font-size: 25px;
      font-weight: 100;
      height: 19px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 30px;
      margin-top: 60px; }
    .customer-contract-container .admin-user .modal-response .search-rs-vl-btn {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 12%; }
      .customer-contract-container .admin-user .modal-response .search-rs-vl-btn .custom-button {
        width: 30%;
        margin: 1%; }
    .customer-contract-container .admin-user .modal-response__container {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      padding: 1.25rem 1.25rem;
      border-radius: 15px;
      transition: ease all 0.5s;
      margin: 1.5625rem 0;
      justify-content: space-around;
      margin-top: 0;
      border-top: none;
      width: 100%; }
    .customer-contract-container .admin-user .modal-response__block {
      width: 50%; }
      .customer-contract-container .admin-user .modal-response__block > .custom-select .custom-select__content {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        height: 30px;
        width: 90%;
        justify-content: space-between; }
        .customer-contract-container .admin-user .modal-response__block > .custom-select .custom-select__content > label {
          color: #000000;
          display: block;
          font-family: Montserrat, Helvetica, Arial, sans-serif;
          font-size: 15px;
          font-weight: 700;
          height: 19px;
          margin-bottom: 0px;
          margin-left: 0px;
          margin-right: 30px;
          margin-top: 0px;
          text-align: left; }
      .customer-contract-container .admin-user .modal-response__block > .custom-select .custom-select__content__select {
        flex-grow: 1; }
      .customer-contract-container .admin-user .modal-response__block > label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 15px;
        color: #000000;
        font-weight: bold;
        text-align: left;
        margin: 0;
        margin-right: 30px; }
      .customer-contract-container .admin-user .modal-response__block .custom-button {
        width: 50%;
        margin-left: 2%; }
  .customer-contract-container .admin-user .remove-user-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; }
    .customer-contract-container .admin-user .remove-user-modal .warning-title {
      font-weight: 600;
      text-align: center;
      color: #d0021b;
      font-size: 20px;
      margin-bottom: 5%; }
    .customer-contract-container .admin-user .remove-user-modal .response {
      margin-top: 5%;
      text-align: center; }
    .customer-contract-container .admin-user .remove-user-modal > p {
      font-weight: 600;
      text-align: center; }
      .customer-contract-container .admin-user .remove-user-modal > p > span {
        color: #d0021b;
        font-size: 15px; }
    .customer-contract-container .admin-user .remove-user-modal__buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 1.875rem; }
      @media screen and (max-width: 550px) {
        .customer-contract-container .admin-user .remove-user-modal__buttons {
          flex-direction: column; } }
      .customer-contract-container .admin-user .remove-user-modal__buttons .custom-button {
        width: 30%;
        margin: 1.25rem; }
        @media screen and (max-width: 550px) {
          .customer-contract-container .admin-user .remove-user-modal__buttons .custom-button {
            width: 60%;
            margin-bottom: 0; } }
  .customer-contract-container .enable-user-modal-response {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; }
    .customer-contract-container .enable-user-modal-response .code {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: red;
      font-family: Montserrat, Helvetica, Arial, sans-serif;
      font-size: 25px;
      font-weight: 600;
      height: 19px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 30px;
      margin-top: 0px; }
    .customer-contract-container .enable-user-modal-response .message {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: #000000;
      font-family: Montserrat, Helvetica, Arial, sans-serif;
      font-size: 25px;
      font-weight: 300;
      height: 19px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 30px;
      margin-top: 60px; }
    .customer-contract-container .enable-user-modal-response .search-rs-vl-btn {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 12%; }
      .customer-contract-container .enable-user-modal-response .search-rs-vl-btn .custom-button {
        width: 30%;
        margin: 1%; }

.transfer-modal {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 130px;
  text-align: center; }
  .transfer-modal > h3 {
    color: #d0021b; }
  .transfer-modal > p {
    font-size: 20px;
    margin-top: 20px;
    font-weight: 500; }

.custom-table {
  position: relative; }
  .custom-table__page-size {
    width: 50px;
    height: 27px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    color: #445565;
    background-color: #e3e7eb !important;
    border-color: #ced4da !important;
    font-weight: 600;
    border-radius: 5px;
    z-index: 5;
    cursor: pointer;
    margin-left: 10px; }
  .custom-table__custom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 400px; }
    .custom-table__custom-top {
      position: absolute;
      right: 0px;
      top: 9px;
      z-index: 2; }
    .custom-table__custom-bottom {
      position: absolute;
      bottom: 36px;
      right: 0;
      z-index: 2; }
    .custom-table__custom .custom-button {
      width: 160px;
      margin-left: 20px;
      height: 31px;
      font-size: 13px; }
  .custom-table__table .pagination-bottom,
  .custom-table__table .pagination-top {
    width: 70%; }
  .custom-table__table-border-none .rt-table {
    border: none !important; }
  .custom-table__table .custom-loading {
    background: rgba(255, 255, 255, 0.9) !important;
    border-radius: 20px !important; }
    .custom-table__table .custom-loading .custom-loading-inner {
      color: #000000;
      font-size: 16px; }
  .custom-table__table .rt-table {
    border-radius: 20px; }
    .custom-table__table .rt-table .-filters {
      height: 40px !important;
      flex-direction: row; }
      .custom-table__table .rt-table .-filters input {
        font-size: 13px !important;
        color: #4a4a4a; }
    .custom-table__table .rt-table .rt-thead {
      box-shadow: none !important;
      height: 60px; }
      .custom-table__table .rt-table .rt-thead .rt-tr {
        align-items: center;
        padding: 0;
        background-color: #ffffff; }
        .custom-table__table .rt-table .rt-thead .rt-tr .rt-th {
          font-size: 14px;
          color: #000000;
          text-align: left;
          font-weight: 800;
          border: none;
          padding-left: 5; }
      .custom-table__table .rt-table .rt-thead .rt-tr[data-qnt='0'] > .rt-expandable {
        visibility: hidden; }
    .custom-table__table .rt-table .rt-tbody .rt-tr {
      padding: 0; }
      .custom-table__table .rt-table .rt-tbody .rt-tr .rt-td {
        border: none;
        color: #000000; }
      .custom-table__table .rt-table .rt-tbody .rt-tr .rt-expandable .rt-expander {
        color: #ffb40a; }
        .custom-table__table .rt-table .rt-tbody .rt-tr .rt-expandable .rt-expander::after {
          border-top-color: #ffb40a; }
    .custom-table__table .rt-table .rt-tbody .rt-tr-group {
      min-height: 35px;
      margin-left: 1.25rem;
      border: none; }
      .custom-table__table .rt-table .rt-tbody .rt-tr-group .rt-expandable {
        margin-right: 1.25rem; }
    .custom-table__table .rt-table .sub-component .line {
      display: flex;
      margin-top: 0.625rem;
      align-self: center;
      background-color: #979797;
      width: 100%;
      height: 1px;
      cursor: none; }
  .custom-table__table .rt-noData {
    margin: 1.875rem 0;
    background-color: transparent !important; }
  .custom-table .custom-button {
    width: 160px;
    margin-top: 20px; }
  .custom-table__downloadLink {
    text-decoration: none;
    background-color: #ffffff;
    color: #000000;
    font-size: 11px;
    font-weight: bold;
    margin-top: 20px;
    border-radius: 20px;
    transition: all 0.3s; }
    .custom-table__downloadLink:hover {
      color: #7571f4; }
  .custom-table__error {
    font-size: 15px;
    color: #d0021b;
    font-weight: bold; }

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: none !important; }

.with-triangle {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .with-triangle > img {
    margin-left: 5px; }

.-odd {
  background-color: rgba(183, 183, 183, 0.1); }

.table-pagination {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  box-shadow: none !important;
  border: none !important; }
  .table-pagination-visibility {
    visibility: hidden !important; }
  .table-pagination-previous {
    flex: none !important; }
    .table-pagination-previous > button {
      width: 50px !important;
      margin: 0 !important; }
  .table-pagination-next {
    flex: none !important; }
    .table-pagination-next > button {
      width: 50px !important;
      margin: 0 !important; }
  .table-pagination .data-length {
    padding: 5px 7px;
    background-color: #ffffff;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16) !important; }
    .table-pagination .data-length > p {
      font-size: 15px;
      font-weight: 500; }
      .table-pagination .data-length > p > span {
        font-weight: 600; }
  .table-pagination .center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .table-pagination .select-wrap {
    border-radius: 16px !important;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16) !important; }

.search-case-container .title {
  font-size: 25px;
  color: #000000;
  font-weight: bold;
  display: inline-block;
  text-align: left;
  margin: 0;
  margin-right: 30px; }

.search-case-container__container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 1.25rem 1.25rem;
  border-radius: 15px;
  transition: ease all 0.5s;
  margin: 1.5625rem 0;
  justify-content: space-around;
  margin-top: 0;
  border-top: none;
  width: auto;
  background-color: #ffffff; }

.search-case-container__block {
  width: calc(100% / 4); }
  .search-case-container__block > .custom-select .custom-select__content {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 30px;
    width: 90%;
    z-index: 100; }
    .search-case-container__block > .custom-select .custom-select__content > label {
      color: #000000;
      display: block;
      font-family: Montserrat, Helvetica, Arial, sans-serif;
      font-size: 15px;
      font-weight: 700;
      height: 19px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 30px;
      margin-top: 0px;
      text-align: left; }
  .search-case-container__block > .custom-select .custom-select__content__select {
    flex-grow: 1;
    min-width: 5vw; }
  .search-case-container__block > label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 15px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    margin: 0;
    margin-right: 30px; }
  .search-case-container__block .DayPickerInput {
    width: 90%; }
    .search-case-container__block .DayPickerInput .DayPickerInput-Overlay {
      z-index: 1000; }
  .search-case-container__block .custom-button {
    width: 50%;
    margin-left: 2%; }
  .search-case-container__block .custom-input__input {
    height: 30px;
    width: 90%;
    display: block;
    border: 1px solid #131212;
    border-radius: 14px;
    padding: 0 10px;
    font-size: 15px;
    padding-right: 25px;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.4); }
  .search-case-container__block .custom-input__delete {
    position: absolute;
    height: 15px;
    width: 15px;
    top: 26.5px;
    right: 40px;
    outline: 0; }
    .search-case-container__block .custom-input__delete > span {
      font-size: 12px;
      color: #b1b1b1;
      font-weight: 600;
      text-align: right;
      cursor: pointer;
      position: absolute;
      top: 0px;
      left: 2.5px;
      z-index: 1;
      outline: 0; }
      .search-case-container__block .custom-input__delete > span:hover {
        color: #646464; }
  .search-case-container__block .search-rs-vl-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 12%; }
  .search-case-container__block .btnLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 12%; }
  .search-case-container__block .download-button {
    display: flex;
    flex-grow: 1;
    width: 100%;
    margin-left: 0; }
    .search-case-container__block .download-button .custom-button {
      margin-left: 0;
      margin-top: 12%;
      width: 90%; }
  .search-case-container__block .custom-date__delete {
    position: absolute;
    margin-top: 9px;
    margin-left: 310px; }
    @media screen and (min-width: 1300px) {
      .search-case-container__block .custom-date__delete > span {
        font-size: 12px;
        color: #b1b1b1;
        font-weight: 600;
        text-align: right;
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 90.5px;
        z-index: 1;
        outline: 0; }
        .search-case-container__block .custom-date__delete > span:hover {
          color: #646464; } }
    @media screen and (min-width: 1920px) {
      .search-case-container__block .custom-date__delete > span {
        font-size: 12px;
        color: #b1b1b1;
        font-weight: 600;
        text-align: right;
        cursor: pointer;
        position: absolute;
        top: 0px;
        left: 2.5px;
        z-index: 1;
        outline: 0; }
        .search-case-container__block .custom-date__delete > span:hover {
          color: #646464; } }

.search-case-container .modal-response .code {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: red;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 25px;
  font-weight: 700;
  height: 19px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 30px;
  margin-top: 0px; }

.search-case-container .modal-response .message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #000000;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 25px;
  font-weight: 100;
  height: 19px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 30px;
  margin-top: 60px; }

.search-case-container .modal-response .search-rs-vl-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 12%; }
  .search-case-container .modal-response .search-rs-vl-btn .custom-button {
    width: 30%;
    margin: 1%; }

.search-case-container .modal-response__container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 1.25rem 1.25rem;
  border-radius: 15px;
  transition: ease all 0.5s;
  margin: 1.5625rem 0;
  justify-content: space-around;
  margin-top: 0;
  border-top: none;
  width: 100%; }

.search-case-container .modal-response__block > .custom-select .custom-select__content {
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 30px;
  width: 90%;
  justify-content: space-between; }
  .search-case-container .modal-response__block > .custom-select .custom-select__content > label {
    color: #000000;
    display: block;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    height: 19px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 30px;
    margin-top: 0px;
    text-align: left; }

.search-case-container .modal-response__block > .custom-select .custom-select__content__select {
  flex-grow: 1;
  min-width: 5vw; }

.search-case-container .modal-response__block > label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 15px;
  color: #000000;
  font-weight: bold;
  text-align: left;
  margin: 0;
  margin-right: 30px; }

.search-case-container .modal-response__block .custom-button {
  margin-left: 2%; }

.search-case-result-container {
  margin-left: -1%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative; }
  .search-case-result-container .background-element {
    border: none; }
  .search-case-result-container__button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 400px; }
    .search-case-result-container__button-top {
      position: absolute;
      right: 0px;
      top: 36px;
      z-index: 2; }
    .search-case-result-container__button-bottom {
      position: absolute;
      bottom: 36px;
      right: 0;
      z-index: 2; }
    .search-case-result-container__button .custom-select {
      width: 160px;
      cursor: pointer;
      margin: 0; }
      .search-case-result-container__button .custom-select__content {
        cursor: pointer; }
        .search-case-result-container__button .custom-select__content label {
          display: none; }
    .search-case-result-container__button .custom-button {
      width: 160px;
      margin-left: 20px;
      height: 31px;
      font-size: 13px; }
  .search-case-result-container .custom-table {
    width: 100%; }
    .search-case-result-container .custom-table .rt-td {
      color: #2e2ba5;
      font-size: 13px;
      align-self: center;
      font-weight: 600; }
    .search-case-result-container .custom-table .rt-resizable-header-content {
      padding-left: 0 !important; }
    .search-case-result-container .custom-table .rt-tr-group {
      margin-left: 0 !important; }
    .search-case-result-container .custom-table .rt-expandable {
      margin-right: 0 !important; }
    .search-case-result-container .custom-table .sub-component {
      padding: 1.25rem 2.5rem; }
      .search-case-result-container .custom-table .sub-component__table {
        width: 100%;
        margin: 0;
        border-spacing: 0;
        border-collapse: separate;
        border-spacing: 0 5px; }
        .search-case-result-container .custom-table .sub-component__table thead tr th {
          font-weight: 400;
          text-align: left;
          padding-left: 10px;
          object-fit: contain;
          font-family: Montserrat;
          font-size: 12px;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #8d8d8d; }
        .search-case-result-container .custom-table .sub-component__table tbody {
          padding-top: 20px; }
          .search-case-result-container .custom-table .sub-component__table tbody tr {
            height: 35px; }
            .search-case-result-container .custom-table .sub-component__table tbody tr td {
              color: black;
              font-weight: 500;
              padding: 0;
              padding-left: 10px;
              font-size: 12px;
              line-height: 1.25;
              text-align: left; }
              .search-case-result-container .custom-table .sub-component__table tbody tr td .transaction-element__buttons {
                display: inline;
                padding: 0; }
              .search-case-result-container .custom-table .sub-component__table tbody tr td .custom-button {
                width: 100px;
                margin: 0; }
    .search-case-result-container .custom-table .custom-collapse {
      border-radius: 4px;
      padding: 12px;
      margin-bottom: 1rem;
      margin: 0; }
      .search-case-result-container .custom-table .custom-collapse .message-collapse-first-elm,
      .search-case-result-container .custom-table .custom-collapse .message-collapse-message {
        color: #000000;
        font-size: 12px;
        font-weight: 600;
        color: #000000; }
    .search-case-result-container .custom-table .rt-thead .rt-th {
      font-size: 11px !important; }
    .search-case-result-container .custom-table .rt-tr {
      border-bottom: solid 1px #d8d8d8;
      padding: 7px; }
      .search-case-result-container .custom-table .rt-tr .rt-td:nth-child(2), .search-case-result-container .custom-table .rt-tr .rt-td:nth-child(3) {
        font-weight: 800; }
    .search-case-result-container .custom-table .custom-button {
      width: auto;
      margin: 0 auto; }
    .search-case-result-container .custom-table .custom-checkbox {
      margin: 0; }
    .search-case-result-container .custom-table .sticky {
      background: #ffffff;
      z-index: 1000;
      width: 90%; }
    .search-case-result-container .custom-table .menu-style {
      width: 35px;
      cursor: pointer; }
  @media screen and (max-width: 950px) {
    .search-case-result-container {
      flex-wrap: wrap; } }
  .search-case-result-container__header {
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 0 25px;
    padding-left: 21px;
    border-left: solid 9px transparent;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 85px; }
    .search-case-result-container__header-active {
      border-left: solid 9px #75eab5; }
    .search-case-result-container__header__column {
      margin: 0 0.5rem; }
      .search-case-result-container__header__column:nth-child(1) {
        margin-left: 0; }
      .search-case-result-container__header__column:nth-child(5) {
        margin-right: 0; }
      .search-case-result-container__header__column > h1 {
        color: #2e2ba5;
        font-size: 14px;
        font-weight: 800; }
      .search-case-result-container__header__column > p {
        color: #747474;
        font-size: 11px;
        margin: 16px 0; }
      .search-case-result-container__header__column > h2 {
        color: #2e2ba5;
        font-size: 14px;
        font-weight: 500; }
      .search-case-result-container__header__column > h3 {
        color: #2e2ba5;
        font-size: 14px;
        font-weight: 500; }
  .search-case-result-container__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%; }
    .search-case-result-container__container .process-element {
      width: 47.5%;
      height: 100%; }
    .search-case-result-container__container .transaction-element {
      width: 47.5%;
      height: 100%; }

.user-administration-container {
  display: flex;
  flex-direction: column; }
  .user-administration-container .actions-btns {
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 1.25rem 1.25rem;
    border-radius: 15px;
    transition: ease all 0.5s;
    margin: 1.5625rem 0;
    width: 400px;
    align-items: center; }
    .user-administration-container .actions-btns .custom-button {
      width: 165px;
      height: 10px; }
  .user-administration-container .title {
    font-size: 25px;
    color: #000000;
    font-weight: bold;
    display: inline-block;
    text-align: left;
    margin: 0;
    margin-right: 30px; }
  .user-administration-container__container {
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 1.25rem 1.25rem;
    border-radius: 15px;
    transition: ease all 0.5s;
    margin: 1.5625rem 0;
    flex-direction: column;
    width: auto; }
    .user-administration-container__container .search-rs-vl-btn {
      align-self: flex-end;
      margin-top: 10px; }
      .user-administration-container__container .search-rs-vl-btn .custom-button {
        width: 165px;
        margin-right: 20px; }
    .user-administration-container__container__row {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 0;
      border-top: none;
      width: 100%; }
      .user-administration-container__container__row__block {
        width: calc(100% / 4); }
        .user-administration-container__container__row__block:nth-child(2) .custom-input:nth-child(2) {
          margin: 0; }
        .user-administration-container__container__row__block .search-group-text {
          position: absolute; }
        .user-administration-container__container__row__block > .custom-select {
          margin-bottom: 20px; }
          .user-administration-container__container__row__block > .custom-select .custom-select__content {
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            width: 90%; }
            .user-administration-container__container__row__block > .custom-select .custom-select__content > label {
              color: #000000;
              display: block;
              font-family: Montserrat, Helvetica, Arial, sans-serif;
              font-size: 15px;
              font-weight: 700;
              height: 19px;
              margin-bottom: 0px;
              margin-left: 0px;
              margin-right: 30px;
              margin-top: 0px;
              text-align: left; }
          .user-administration-container__container__row__block > .custom-select .custom-select__content__select {
            flex-grow: 1;
            min-width: 5vw; }
        .user-administration-container__container__row__block > label {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 15px;
          color: #000000;
          font-weight: bold;
          text-align: left;
          margin: 0;
          margin-right: 30px; }
        .user-administration-container__container__row__block .DayPickerInput {
          width: 90%; }
        .user-administration-container__container__row__block .custom-button {
          width: 50%;
          margin-left: 2%; }
        .user-administration-container__container__row__block .custom-input__input {
          height: 30px;
          width: 90%;
          display: block;
          border: 1px solid #131212;
          border-radius: 14px;
          background-color: #ffffff;
          padding: 0 10px;
          font-size: 15px; }
          .user-administration-container__container__row__block .custom-input__input--locked {
            border: none;
            pointer-events: none;
            background-color: rgba(155, 155, 155, 0.24); }
        .user-administration-container__container__row__block .custom-input__delete {
          position: absolute;
          height: 15px;
          width: 15px;
          top: 26.5px;
          right: 40px;
          outline: 0; }
          .user-administration-container__container__row__block .custom-input__delete > span {
            font-size: 12px;
            color: #b1b1b1;
            font-weight: 600;
            text-align: right;
            cursor: pointer;
            position: absolute;
            top: 0px;
            left: 2.5px;
            z-index: 1;
            outline: 0; }
            .user-administration-container__container__row__block .custom-input__delete > span:hover {
              color: #646464; }
  .user-administration-container .btn-close {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    justify-content: flex-end;
    margin-bottom: 5px;
    cursor: pointer; }
  .user-administration-container .custom-modal__content {
    width: 55%;
    height: 60%; }
    @media screen and (max-height: 850px) {
      .user-administration-container .custom-modal__content {
        width: 45%;
        height: 75%; } }
    @media screen and (max-width: 1300px) {
      .user-administration-container .custom-modal__content {
        width: 45%;
        height: 40%; } }
    @media screen and (max-width: 980px) {
      .user-administration-container .custom-modal__content {
        width: 70%;
        height: 40%; } }
    @media screen and (max-width: 650px) {
      .user-administration-container .custom-modal__content {
        width: 80%;
        height: 45%; } }
    .user-administration-container .custom-modal__content .create-user-modal {
      display: flex;
      flex-direction: column; }
      .user-administration-container .custom-modal__content .create-user-modal .title {
        font-weight: 600;
        text-align: center;
        color: #d0021b;
        font-size: 20px;
        margin-bottom: 5%; }
      .user-administration-container .custom-modal__content .create-user-modal .radio-buttons {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        width: 25vw; }
        .user-administration-container .custom-modal__content .create-user-modal .radio-buttons > .custom-radio {
          margin-right: 1.2vw; }
      .user-administration-container .custom-modal__content .create-user-modal .search-rs-vl-btn {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 2%; }
        .user-administration-container .custom-modal__content .create-user-modal .search-rs-vl-btn .custom-button {
          width: 30%;
          margin: 1%; }
      .user-administration-container .custom-modal__content .create-user-modal__container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 1.25rem 1.25rem;
        border-radius: 15px;
        transition: ease all 0.5s;
        margin: 1.5625rem 0;
        justify-content: space-around;
        margin-top: 0;
        margin-bottom: 10px;
        border-top: none;
        width: 100%; }
      .user-administration-container .custom-modal__content .create-user-modal__block {
        margin-top: -35px;
        width: 50%; }
        .user-administration-container .custom-modal__content .create-user-modal__block > .custom-select {
          margin-bottom: 20px; }
          .user-administration-container .custom-modal__content .create-user-modal__block > .custom-select .custom-select__content {
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            height: 49px;
            width: 90%;
            justify-content: space-between; }
            .user-administration-container .custom-modal__content .create-user-modal__block > .custom-select .custom-select__content > label {
              height: 19px;
              margin-bottom: 0px;
              margin-left: 0px;
              margin-right: 30px;
              margin-top: 0px;
              text-align: left;
              font-weight: normal; }
          .user-administration-container .custom-modal__content .create-user-modal__block > .custom-select .custom-select__content__select {
            flex-grow: 1;
            min-width: 5vw; }
        .user-administration-container .custom-modal__content .create-user-modal__block > label {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 15px;
          color: #000000;
          font-weight: bold;
          text-align: left;
          margin: 0;
          margin-right: 30px;
          background-color: red; }
        .user-administration-container .custom-modal__content .create-user-modal__block .custom-button {
          width: 50%;
          margin-left: 2%; }
        .user-administration-container .custom-modal__content .create-user-modal__block .custom-input__input {
          height: 30px;
          width: 90%;
          display: block;
          border: 1px solid #131212;
          border-radius: 14px;
          background-color: #ffffff;
          padding: 0 10px;
          font-size: 15px; }
        .user-administration-container .custom-modal__content .create-user-modal__block .custom-input > label {
          font-weight: normal; }
    .user-administration-container .custom-modal__content .create-group-modal__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
      .user-administration-container .custom-modal__content .create-group-modal__content .create-group-error {
        color: #d0021b;
        margin-top: 10px; }
      .user-administration-container .custom-modal__content .create-group-modal__content__group-selection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%; }
        .user-administration-container .custom-modal__content .create-group-modal__content__group-selection > div {
          width: 45%; }
          .user-administration-container .custom-modal__content .create-group-modal__content__group-selection > div > p {
            font-weight: 800; }
          .user-administration-container .custom-modal__content .create-group-modal__content__group-selection > div .custom-select {
            width: 100%; }
          .user-administration-container .custom-modal__content .create-group-modal__content__group-selection > div .custom-input__input {
            width: 100%; }
      .user-administration-container .custom-modal__content .create-group-modal__content .title {
        font-weight: 600;
        text-align: center;
        color: #d0021b;
        font-size: 20px;
        margin-bottom: 5%; }
      .user-administration-container .custom-modal__content .create-group-modal__content .reponse {
        margin-top: 5%;
        text-align: center; }
      .user-administration-container .custom-modal__content .create-group-modal__content > p {
        font-weight: 600;
        text-align: center; }
        .user-administration-container .custom-modal__content .create-group-modal__content > p > span {
          color: #d0021b;
          font-size: 15px; }
      .user-administration-container .custom-modal__content .create-group-modal__content .custom-input {
        margin-top: 2%;
        width: 100%;
        align-items: center;
        justify-content: center; }
      .user-administration-container .custom-modal__content .create-group-modal__content .custom-select {
        margin-top: 2%;
        width: 60%;
        align-items: center;
        justify-content: center; }
      .user-administration-container .custom-modal__content .create-group-modal__content .radio-buttons {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem; }
        .user-administration-container .custom-modal__content .create-group-modal__content .radio-buttons > .custom-radio {
          margin-right: 1.2vw; }
      .user-administration-container .custom-modal__content .create-group-modal__content__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1.875rem; }
        @media screen and (max-width: 550px) {
          .user-administration-container .custom-modal__content .create-group-modal__content__buttons {
            flex-direction: column; } }
        .user-administration-container .custom-modal__content .create-group-modal__content__buttons .custom-button {
          width: 30%;
          margin: 1.25rem; }
          @media screen and (max-width: 550px) {
            .user-administration-container .custom-modal__content .create-group-modal__content__buttons .custom-button {
              width: 60%;
              margin-bottom: 0; } }
    .user-administration-container .custom-modal__content .remove-user-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
      .user-administration-container .custom-modal__content .remove-user-modal .warning-title {
        font-weight: 600;
        text-align: center;
        color: #d0021b;
        font-size: 20px;
        margin-bottom: 5%; }
      .user-administration-container .custom-modal__content .remove-user-modal .reponse {
        margin-top: 5%;
        text-align: center; }
      .user-administration-container .custom-modal__content .remove-user-modal .message {
        margin-top: 5%;
        text-align: center; }
      .user-administration-container .custom-modal__content .remove-user-modal > p {
        font-weight: 600;
        text-align: center; }
        .user-administration-container .custom-modal__content .remove-user-modal > p > span {
          color: #d0021b;
          font-size: 15px; }
      .user-administration-container .custom-modal__content .remove-user-modal__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1.875rem; }
        @media screen and (max-width: 550px) {
          .user-administration-container .custom-modal__content .remove-user-modal__buttons {
            flex-direction: column; } }
        .user-administration-container .custom-modal__content .remove-user-modal__buttons .custom-button {
          width: 30%;
          margin: 1.25rem; }
          @media screen and (max-width: 550px) {
            .user-administration-container .custom-modal__content .remove-user-modal__buttons .custom-button {
              width: 60%;
              margin-bottom: 0; } }
    .user-administration-container .custom-modal__content .desable-user {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
      .user-administration-container .custom-modal__content .desable-user .warning-title {
        font-weight: 600;
        text-align: center;
        color: #d0021b;
        font-size: 20px;
        margin-bottom: 5%; }
      .user-administration-container .custom-modal__content .desable-user .warning-title {
        font-weight: 600;
        text-align: center;
        color: #d0021b;
        font-size: 20px;
        margin-bottom: 5%; }
      .user-administration-container .custom-modal__content .desable-user .reponse {
        margin-top: 5%;
        text-align: center; }
      .user-administration-container .custom-modal__content .desable-user .message {
        margin-top: 5%;
        text-align: center; }
      .user-administration-container .custom-modal__content .desable-user > p {
        font-weight: 600;
        text-align: center; }
        .user-administration-container .custom-modal__content .desable-user > p > span {
          color: #d0021b;
          font-size: 15px; }
    .user-administration-container .custom-modal__content .enable-user .warning-title {
      font-weight: 600;
      text-align: center;
      color: #d0021b;
      font-size: 20px;
      margin-bottom: 5%; }
    .user-administration-container .custom-modal__content .enable-user .message {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: #000000;
      font-family: Montserrat, Helvetica, Arial, sans-serif;
      font-size: 15px;
      font-weight: 700;
      height: 19px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 30px;
      margin-top: 0px; }
    .user-administration-container .custom-modal__content .enable-user .question {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: #000000;
      font-family: Montserrat, Helvetica, Arial, sans-serif;
      font-size: 15px;
      font-weight: 700;
      height: 19px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 30px;
      margin-top: 50px; }
    .user-administration-container .custom-modal__content .enable-user__buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 1.875rem; }
      @media screen and (max-width: 550px) {
        .user-administration-container .custom-modal__content .enable-user__buttons {
          flex-direction: column; } }
      .user-administration-container .custom-modal__content .enable-user__buttons .custom-button {
        width: 30%;
        margin: 1.25rem; }
        @media screen and (max-width: 550px) {
          .user-administration-container .custom-modal__content .enable-user__buttons .custom-button {
            width: 60%;
            margin-bottom: 0; } }
    .user-administration-container .custom-modal__content .modal-response {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
      .user-administration-container .custom-modal__content .modal-response .code {
        font-weight: 600;
        text-align: center;
        color: #d0021b;
        font-size: 20px;
        margin-bottom: 5%; }
      .user-administration-container .custom-modal__content .modal-response .message {
        margin-top: 5%;
        text-align: center;
        font-size: 20px; }
      .user-administration-container .custom-modal__content .modal-response .search-rs-vl-btn {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 12%; }
        .user-administration-container .custom-modal__content .modal-response .search-rs-vl-btn .custom-button {
          width: 30%;
          margin: 1%; }
      .user-administration-container .custom-modal__content .modal-response__container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 1.25rem 1.25rem;
        border-radius: 15px;
        transition: ease all 0.5s;
        margin: 1.5625rem 0;
        justify-content: space-around;
        margin-top: 0;
        border-top: none;
        width: 100%; }
      .user-administration-container .custom-modal__content .modal-response__block {
        width: 50%; }
        .user-administration-container .custom-modal__content .modal-response__block > .custom-select .custom-select__content {
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          height: 30px;
          width: 90%;
          justify-content: space-between; }
          .user-administration-container .custom-modal__content .modal-response__block > .custom-select .custom-select__content > label {
            color: #000000;
            display: block;
            font-family: Montserrat, Helvetica, Arial, sans-serif;
            font-size: 15px;
            font-weight: 700;
            height: 19px;
            margin-bottom: 0px;
            margin-left: 0px;
            margin-right: 30px;
            margin-top: 0px;
            text-align: left; }
        .user-administration-container .custom-modal__content .modal-response__block > .custom-select .custom-select__content__select {
          flex-grow: 1;
          min-width: 5vw; }
        .user-administration-container .custom-modal__content .modal-response__block > label {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 15px;
          color: #000000;
          font-weight: bold;
          text-align: left;
          margin: 0;
          margin-right: 30px; }
        .user-administration-container .custom-modal__content .modal-response__block .custom-button {
          width: 50%;
          margin-left: 2%; }

.user-administration-result {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0; }
  .user-administration-result .background-element {
    width: auto;
    border: none; }
  .user-administration-result .enable-user-modal-response {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; }
    .user-administration-result .enable-user-modal-response .code {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: red;
      font-family: Montserrat, Helvetica, Arial, sans-serif;
      font-size: 25px;
      font-weight: 600;
      height: 19px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 30px;
      margin-top: 0px; }
    .user-administration-result .enable-user-modal-response .message {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: #000000;
      font-family: Montserrat, Helvetica, Arial, sans-serif;
      font-size: 25px;
      font-weight: 300;
      height: 19px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 30px;
      margin-top: 60px; }
    .user-administration-result .enable-user-modal-response .search-rs-vl-btn {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 12%; }
      .user-administration-result .enable-user-modal-response .search-rs-vl-btn .custom-button {
        width: 30%;
        margin: 1%; }
  .user-administration-result__button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 400px;
    position: absolute;
    right: 0px;
    top: 25px;
    z-index: 2; }
    .user-administration-result__button .custom-button {
      width: 160px;
      margin-left: 20px;
      height: 31px;
      font-size: 13px; }
  .user-administration-result__button-selected {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 400px;
    position: absolute;
    right: 0px;
    top: 25px; }
    .user-administration-result__button-selected .custom-button {
      width: 160px;
      margin-left: 20px;
      height: 31px;
      font-size: 13px; }
  .user-administration-result .custom-table {
    width: 100%; }
    .user-administration-result .custom-table .rt-td {
      color: #2e2ba5;
      font-size: 13px;
      align-self: center;
      font-weight: 600; }
    .user-administration-result .custom-table .rt-resizable-header-content {
      padding-left: 0 !important; }
    .user-administration-result .custom-table .rt-tr-group {
      margin-left: 0 !important; }
    .user-administration-result .custom-table .rt-expandable {
      margin-right: 0 !important; }
    .user-administration-result .custom-table .sub-component {
      padding: 1.25rem 2.5rem; }
      .user-administration-result .custom-table .sub-component__table {
        width: 100%;
        margin: 0;
        border-spacing: 0;
        border-collapse: separate;
        border-spacing: 0 5px; }
        .user-administration-result .custom-table .sub-component__table thead tr th {
          font-weight: 400;
          text-align: left;
          padding-left: 10px;
          object-fit: contain;
          font-family: Montserrat;
          font-size: 12px;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #8d8d8d; }
        .user-administration-result .custom-table .sub-component__table tbody {
          padding-top: 20px; }
          .user-administration-result .custom-table .sub-component__table tbody tr {
            height: 35px; }
            .user-administration-result .custom-table .sub-component__table tbody tr td {
              color: black;
              font-weight: 500;
              padding: 0;
              padding-left: 10px;
              font-size: 12px;
              line-height: 1.25;
              text-align: left; }
              .user-administration-result .custom-table .sub-component__table tbody tr td .transaction-element__buttons {
                display: inline;
                padding: 0; }
              .user-administration-result .custom-table .sub-component__table tbody tr td .custom-button {
                width: 100px;
                margin: 0; }
    .user-administration-result .custom-table .rt-resizer {
      width: 1px !important; }
    .user-administration-result .custom-table .rt-tr {
      padding: 7px; }
      .user-administration-result .custom-table .rt-tr .rt-td:nth-child(1) {
        font-weight: 800; }
      .user-administration-result .custom-table .rt-tr .rt-td > img {
        margin-left: 20px; }
    .user-administration-result .custom-table .custom-checkbox {
      margin: 0; }
    .user-administration-result .custom-table .sticky {
      background: #ffffff;
      z-index: 1000;
      width: 90%; }
    .user-administration-result .custom-table .menu-style {
      height: 27px;
      width: 6px;
      cursor: pointer;
      margin-left: 1px; }
  @media screen and (max-width: 950px) {
    .user-administration-result {
      flex-wrap: wrap; } }
  .user-administration-result__header {
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 0 25px;
    padding-left: 21px;
    border-left: solid 9px transparent;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 85px; }
    .user-administration-result__header-active {
      border-left: solid 9px #75eab5; }
    .user-administration-result__header__column {
      margin: 0 0.5rem; }
      .user-administration-result__header__column:nth-child(1) {
        margin-left: 0; }
      .user-administration-result__header__column:nth-child(5) {
        margin-right: 0; }
      .user-administration-result__header__column > h1 {
        color: #2e2ba5;
        font-size: 14px;
        font-weight: 800; }
      .user-administration-result__header__column > p {
        color: #747474;
        font-size: 11px;
        margin: 16px 0; }
      .user-administration-result__header__column > h2 {
        color: #2e2ba5;
        font-size: 14px;
        font-weight: 500; }
      .user-administration-result__header__column > h3 {
        color: #2e2ba5;
        font-size: 14px;
        font-weight: 500; }
  .user-administration-result__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%; }
    .user-administration-result__container .process-element {
      width: 47.5%;
      height: 100%; }
    .user-administration-result__container .transaction-element {
      width: 47.5%;
      height: 100%; }
  .user-administration-result .pop-up {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 200px;
    height: 45px;
    align-items: flex-end;
    right: 0;
    border-radius: 10px;
    margin-top: -15px;
    z-index: 2;
    border: solid 1px #f5a623;
    border-top-right-radius: 1;
    border-bottom-right-radius: 1;
    border-top-left-radius: 1;
    border-bottom-left-radius: 1;
    text-align: center;
    background-color: white; }
    .user-administration-result .pop-up > p {
      font-size: 11px;
      color: #000000;
      font-weight: bold;
      display: inline-block;
      margin: 0;
      width: 200px;
      margin-top: 5px;
      cursor: pointer; }
      .user-administration-result .pop-up > p:hover {
        color: #f5a623; }
  .user-administration-result .remove-user > p {
    font-size: 11px;
    color: #000000;
    font-weight: bold;
    display: inline-block;
    margin: 0;
    width: 200px;
    margin-top: 5px;
    cursor: pointer; }
    .user-administration-result .remove-user > p:hover {
      color: #f5a623; }
  .user-administration-result .enable-or-desable > p {
    font-size: 11px;
    color: #000000;
    font-weight: bold;
    display: inline-block;
    margin: 0;
    width: 200px;
    margin-top: 5px;
    cursor: pointer; }
    .user-administration-result .enable-or-desable > p:hover {
      color: #f5a623; }
  .user-administration-result .enable-or-desable .enable-user-modal-response {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; }
    .user-administration-result .enable-or-desable .enable-user-modal-response .code {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: red;
      font-family: Montserrat, Helvetica, Arial, sans-serif;
      font-size: 25px;
      font-weight: 600;
      height: 19px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 30px;
      margin-top: 0px; }
    .user-administration-result .enable-or-desable .enable-user-modal-response .message {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: #000000;
      font-family: Montserrat, Helvetica, Arial, sans-serif;
      font-size: 25px;
      font-weight: 300;
      height: 19px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 30px;
      margin-top: 60px; }
    .user-administration-result .enable-or-desable .enable-user-modal-response .search-rs-vl-btn {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 12%; }
      .user-administration-result .enable-or-desable .enable-user-modal-response .search-rs-vl-btn .custom-button {
        width: 30%;
        margin: 1%; }

.custom-modal .btn-close {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-end;
  margin-bottom: 5px;
  cursor: pointer; }

.custom-modal__content {
  width: 45%;
  height: 45%; }
  @media screen and (max-width: 1300px) {
    .custom-modal__content {
      width: 45%;
      height: 40%; } }
  @media screen and (max-width: 980px) {
    .custom-modal__content {
      width: 70%;
      height: 40%; } }
  @media screen and (max-width: 650px) {
    .custom-modal__content {
      width: 80%;
      height: 45%; } }
  .custom-modal__content .create-user-modal > p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #000000;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    height: 19px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 30px;
    margin-top: 0px; }
  .custom-modal__content .create-user-modal .search-rs-vl-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2%; }
    .custom-modal__content .create-user-modal .search-rs-vl-btn .custom-button {
      width: 30%;
      margin: 1%; }
  .custom-modal__content .create-user-modal__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 1.25rem 1.25rem;
    border-radius: 15px;
    transition: ease all 0.5s;
    margin: 1.5625rem 0;
    justify-content: space-around;
    margin-top: 0;
    border-top: none;
    width: 100%; }
  .custom-modal__content .create-user-modal__block {
    width: 50%; }
    .custom-modal__content .create-user-modal__block > .custom-select .custom-select__content {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      height: 30px;
      width: 90%;
      justify-content: space-between; }
      .custom-modal__content .create-user-modal__block > .custom-select .custom-select__content > label {
        color: #000000;
        display: block;
        font-family: Montserrat, Helvetica, Arial, sans-serif;
        font-size: 15px;
        font-weight: 700;
        height: 19px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 0px;
        text-align: left; }
    .custom-modal__content .create-user-modal__block > .custom-select .custom-select__content__select {
      flex-grow: 1;
      min-width: 5vw; }
    .custom-modal__content .create-user-modal__block > label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 15px;
      color: #000000;
      font-weight: bold;
      text-align: left;
      margin: 0;
      margin-right: 30px; }
    .custom-modal__content .create-user-modal__block .custom-button {
      width: 50%;
      margin-left: 2%; }
    .custom-modal__content .create-user-modal__block .custom-input__input {
      height: 30px;
      width: 90%;
      display: block;
      border: 1px solid #131212;
      border-radius: 14px;
      background-color: #ffffff;
      padding: 0 10px;
      font-size: 15px; }
  .custom-modal__content .create-user-modal > p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #000000;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    height: 19px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 30px;
    margin-top: 0px; }
  .custom-modal__content .create-user-modal .search-rs-vl-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2%; }
    .custom-modal__content .create-user-modal .search-rs-vl-btn .custom-button {
      width: 30%;
      margin: 1%; }
  .custom-modal__content .create-user-modal__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 1.25rem 1.25rem;
    border-radius: 15px;
    transition: ease all 0.5s;
    margin: 1.5625rem 0;
    justify-content: space-around;
    margin-top: 0;
    border-top: none;
    width: 100%; }
  .custom-modal__content .create-user-modal__block {
    width: 50%; }
    .custom-modal__content .create-user-modal__block > .custom-select .custom-select__content {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      height: 30px;
      width: 90%;
      justify-content: space-between; }
      .custom-modal__content .create-user-modal__block > .custom-select .custom-select__content > label {
        color: #000000;
        display: block;
        font-family: Montserrat, Helvetica, Arial, sans-serif;
        font-size: 15px;
        font-weight: 700;
        height: 19px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 0px;
        text-align: left; }
    .custom-modal__content .create-user-modal__block > .custom-select .custom-select__content__select {
      flex-grow: 1;
      min-width: 5vw; }
    .custom-modal__content .create-user-modal__block > label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 15px;
      color: #000000;
      font-weight: bold;
      text-align: left;
      margin: 0;
      margin-right: 30px; }
    .custom-modal__content .create-user-modal__block .custom-button {
      width: 50%;
      margin-left: 2%; }
    .custom-modal__content .create-user-modal__block .custom-input__input {
      height: 30px;
      width: 90%;
      display: block;
      border: 1px solid #131212;
      border-radius: 14px;
      background-color: #ffffff;
      padding: 0 10px;
      font-size: 15px; }
  .custom-modal__content .create-group-modal > p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #000000;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    height: 19px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 30px;
    margin-top: 0px; }
  .custom-modal__content .create-group-modal .search-rs-vl-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2%; }
    .custom-modal__content .create-group-modal .search-rs-vl-btn .custom-button {
      width: 30%;
      margin: 1%; }
  .custom-modal__content .create-group-modal__content__buttons {
    margin: 0 !important; }
  .custom-modal__content .create-group-modal__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 1.25rem 1.25rem;
    border-radius: 15px;
    transition: ease all 0.5s;
    margin: 1.5625rem 0;
    justify-content: space-around;
    margin-top: 0;
    border-top: none;
    width: 100%; }
  .custom-modal__content .create-group-modal__block {
    width: 50%; }
    .custom-modal__content .create-group-modal__block > .custom-select .custom-select__content {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      height: 30px;
      width: 90%;
      justify-content: space-between; }
      .custom-modal__content .create-group-modal__block > .custom-select .custom-select__content > label {
        color: #000000;
        display: block;
        font-family: Montserrat, Helvetica, Arial, sans-serif;
        font-size: 15px;
        font-weight: 700;
        height: 19px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 0px;
        text-align: left; }
    .custom-modal__content .create-group-modal__block > .custom-select .custom-select__content__select {
      flex-grow: 1;
      min-width: 5vw; }
    .custom-modal__content .create-group-modal__block > label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 15px;
      color: #000000;
      font-weight: bold;
      text-align: left;
      margin: 0;
      margin-right: 30px; }
    .custom-modal__content .create-group-modal__block .custom-button {
      width: 50%;
      margin-left: 2%; }
    .custom-modal__content .create-group-modal__block .custom-input > label {
      margin-left: 12%; }
    .custom-modal__content .create-group-modal__block .custom-input__input {
      height: 30px;
      width: 90%;
      display: block;
      border: 1px solid #131212;
      border-radius: 14px;
      background-color: #ffffff;
      padding: 0 10px;
      font-size: 15px; }
  .custom-modal__content .desable-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; }
    .custom-modal__content .desable-user .warning-title {
      font-weight: 600;
      text-align: center;
      color: #d0021b;
      font-size: 20px;
      margin-bottom: 5%; }
    .custom-modal__content .desable-user .response {
      margin-top: 5%;
      text-align: center; }
    .custom-modal__content .desable-user .question {
      margin-top: 5%;
      text-align: center; }
    .custom-modal__content .desable-user > p {
      font-weight: 600;
      text-align: center; }
      .custom-modal__content .desable-user > p > span {
        color: #d0021b;
        font-size: 15px; }
    .custom-modal__content .desable-user__buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 1.875rem; }
      @media screen and (max-width: 550px) {
        .custom-modal__content .desable-user__buttons {
          flex-direction: column; } }
      .custom-modal__content .desable-user__buttons .custom-button {
        width: 30%;
        margin: 1.25rem; }
        @media screen and (max-width: 550px) {
          .custom-modal__content .desable-user__buttons .custom-button {
            width: 60%;
            margin-bottom: 0; } }
  .custom-modal__content .enable-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; }
    .custom-modal__content .enable-user .warning-title {
      font-weight: 600;
      text-align: center;
      color: #d0021b;
      font-size: 20px;
      margin-bottom: 5%; }
    .custom-modal__content .enable-user .response {
      margin-top: 5%;
      text-align: center; }
    .custom-modal__content .enable-user .question {
      margin-top: 5%;
      text-align: center; }
    .custom-modal__content .enable-user > p {
      font-weight: 600;
      text-align: center; }
      .custom-modal__content .enable-user > p > span {
        color: #d0021b;
        font-size: 15px; }
    .custom-modal__content .enable-user__buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 1.875rem; }
      @media screen and (max-width: 550px) {
        .custom-modal__content .enable-user__buttons {
          flex-direction: column; } }
      .custom-modal__content .enable-user__buttons .custom-button {
        width: 30%;
        margin: 1.25rem; }
        @media screen and (max-width: 550px) {
          .custom-modal__content .enable-user__buttons .custom-button {
            width: 60%;
            margin-bottom: 0; } }
  .custom-modal__content .remove-user-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; }
    .custom-modal__content .remove-user-modal .warning-title {
      font-weight: 600;
      text-align: center;
      color: #d0021b;
      font-size: 20px;
      margin-bottom: 5%; }
    .custom-modal__content .remove-user-modal .response {
      margin-top: 5%;
      text-align: center; }
    .custom-modal__content .remove-user-modal > p {
      font-weight: 600;
      text-align: center; }
      .custom-modal__content .remove-user-modal > p > span {
        color: #d0021b;
        font-size: 15px; }
    .custom-modal__content .remove-user-modal__buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 1.875rem; }
      @media screen and (max-width: 550px) {
        .custom-modal__content .remove-user-modal__buttons {
          flex-direction: column; } }
      .custom-modal__content .remove-user-modal__buttons .custom-button {
        width: 30%;
        margin: 1.25rem; }
        @media screen and (max-width: 550px) {
          .custom-modal__content .remove-user-modal__buttons .custom-button {
            width: 60%;
            margin-bottom: 0; } }
  .custom-modal__content .modal-response .code {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: red;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-weight: 700;
    height: 19px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 30px;
    margin-top: 0px; }
  .custom-modal__content .modal-response .message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #000000;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-weight: 100;
    height: 19px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 30px;
    margin-top: 10%; }
  .custom-modal__content .modal-response .search-rs-vl-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 12%; }
    .custom-modal__content .modal-response .search-rs-vl-btn .custom-button {
      width: 30%;
      margin: 1%;
      margin-top: 5%; }
  .custom-modal__content .modal-response__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 1.25rem 1.25rem;
    border-radius: 15px;
    transition: ease all 0.5s;
    margin: 1.5625rem 0;
    justify-content: space-around;
    margin-top: 0;
    border-top: none;
    width: 100%; }
  .custom-modal__content .modal-response__block {
    width: 50%; }
    .custom-modal__content .modal-response__block > .custom-select .custom-select__content {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      height: 30px;
      width: 90%;
      justify-content: space-between; }
      .custom-modal__content .modal-response__block > .custom-select .custom-select__content > label {
        color: #000000;
        display: block;
        font-family: Montserrat, Helvetica, Arial, sans-serif;
        font-size: 15px;
        font-weight: 700;
        height: 19px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 0px;
        text-align: left; }
    .custom-modal__content .modal-response__block > .custom-select .custom-select__content__select {
      flex-grow: 1;
      min-width: 5vw; }
    .custom-modal__content .modal-response__block > label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 15px;
      color: #000000;
      font-weight: bold;
      text-align: left;
      margin: 0;
      margin-right: 30px; }
    .custom-modal__content .modal-response__block .custom-button {
      width: 50%;
      margin-left: 2%; }

.authorization-schema-group {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .authorization-schema-group .custom-checkbox > label:before {
    padding: 5px;
    margin-right: 10px;
    border: 3px solid #dddddb; }
  .authorization-schema-group .custom-checkbox > input:checked + label:after {
    left: 5.5px;
    height: 13px;
    top: 0; }
  .authorization-schema-group .custom-checkbox__label__content > p {
    font-size: 12px; }
  .authorization-schema-group__items-auth {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; }
    .authorization-schema-group__items-auth > .authorization-schema-item {
      width: calc(100% / 3.4); }

.authorization-schema-item {
  width: 100%;
  background-color: rgba(216, 216, 216, 0.35);
  border-radius: 19px;
  padding: 10px; }
  .authorization-schema-item .custom-checkbox {
    margin-bottom: 0; }
    .authorization-schema-item .custom-checkbox__label__content > p {
      font-weight: 600; }
  .authorization-schema-item__modes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 10px;
    margin: 10px 0; }
    .authorization-schema-item__modes .custom-radio {
      font-weight: 400;
      font-size: 13px;
      padding-left: 27px; }
      .authorization-schema-item__modes .custom-radio > span {
        height: 11px;
        width: 11px; }
        .authorization-schema-item__modes .custom-radio > span:after {
          width: 10px;
          height: 10px;
          left: 0.5px;
          top: 0.5px; }
  .authorization-schema-item__sub-menu-items {
    display: flex;
    flex-direction: column; }
    .authorization-schema-item__sub-menu-items__item-menu .custom-toggle {
      justify-content: flex-start; }
      .authorization-schema-item__sub-menu-items__item-menu .custom-toggle__toggle {
        margin: 0; }
      .authorization-schema-item__sub-menu-items__item-menu .custom-toggle > p {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: normal; }
    .authorization-schema-item__sub-menu-items__item-menu > div .custom-checkbox {
      margin-bottom: 10px;
      margin-left: 50px; }
      .authorization-schema-item__sub-menu-items__item-menu > div .custom-checkbox .custom-checkbox__label__content__text {
        font-weight: 400; }

.status {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .status .circle {
    width: 15px;
    min-width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #75eab5; }
  .status .label {
    color: #75eab5;
    font-size: 13px;
    margin-left: 0.5rem; }
  .status-success .circle {
    background-color: #39894c; }
  .status-success .label {
    color: #39894c; }
  .status-danger .circle {
    background-color: #b11f2c; }
  .status-danger .label {
    color: #b11f2c; }
  .status-warning .circle {
    background-color: #cd9c00; }
  .status-warning .label {
    color: #cd9c00; }
  .status-secondary .circle {
    background-color: #5f6269; }
  .status-secondary .label {
    color: #5f6269; }
  .status-purple .circle {
    background-color: #6f49ce; }
  .status-purple .label {
    color: #6f49ce; }
  .status-undefined .circle {
    display: none; }

.filter-select {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .filter-select > select {
    border: none !important;
    cursor: pointer;
    color: #747474;
    font-weight: 500 !important;
    font-size: 13px !important;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none !important;
         appearance: none !important; }
    .filter-select > select::-ms-expand {
      display: none !important; }

.custom-collapse {
  width: 100%;
  border-radius: 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  background-color: var(--white);
  padding: 10px; }
  .custom-collapse__display-first-element {
    display: flex;
    flex-direction: column;
    position: relative; }
    .custom-collapse__display-first-element__btn {
      position: absolute;
      top: -10px;
      right: 3px;
      transition: all 0.6s;
      font-size: 17px;
      font-weight: 800;
      cursor: pointer; }
      .custom-collapse__display-first-element__btn-open {
        transform: rotate(45deg); }
  .custom-collapse__children-content {
    transition: height 0.5s;
    height: 0;
    overflow: hidden; }

.message-collapse-message {
  white-space: pre-wrap; }

